import { produce } from 'immer';
import type {
  QueryVariable,
  ScrapeVariable,
  SourceVariable,
  TemplateVariable,
  Variable,
  VariableMap,
  VariableStateData,
} from 'types-shared/workflowTypes';
import type { StateCreator } from 'zustand';

type Data =
  | Pick<SourceVariable, 'data'>
  | Pick<ScrapeVariable, 'data'>
  | Pick<TemplateVariable, 'data'>
  | Pick<QueryVariable, 'data'>;

export const initialVariableState: VariableStateData = {
  variables: {},
  globalVariables: {},
};

export interface VariableStateActions {
  setVariables: (variables: VariableMap) => void;
  setGlobalVariables: (variables: VariableMap) => void;
  addVariable: (variable: Variable) => void;
  addVariables: (variables: VariableMap) => void;
  deleteVariable: (variableId: string) => void;
  updateVariableData: (variableId: string, data: Data) => void;
  updateVariable: (variable: Variable) => void;
  resetVariables: () => void;
}

export const VariableState: StateCreator<
  VariableStateData & VariableStateActions
> = (set) => ({
  ...initialVariableState,
  setVariables: (variables: VariableMap) => {
    set(
      produce((state: VariableStateData) => {
        state.variables = variables;
      }),
    );
  },
  setGlobalVariables: (globalVariables: VariableMap) => {
    set(
      produce((state: VariableStateData) => {
        state.globalVariables = globalVariables;
      }),
    );
  },
  addVariable: (variable: Variable) => {
    set(
      produce((state: VariableStateData) => {
        state.variables[variable.id] = variable;
      }),
    );
  },
  addVariables: (variables: VariableMap) => {
    set(
      produce((state: VariableStateData) => {
        state.variables = {
          ...state.variables,
          ...variables,
        };
      }),
    );
  },
  deleteVariable: (variableId: string) => {
    set(
      produce((state: VariableStateData) => {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.variables[variableId];
      }),
    );
  },
  updateVariableData: (variableId: string, { data }: Data) => {
    set(
      produce((state: VariableStateData) => {
        if (variableId in state.variables) {
          const oldVariable = state.variables[variableId];
          oldVariable.data = data;
          state.variables[variableId] = oldVariable;
        }
      }),
    );
  },
  updateVariable: (variable: Variable) => {
    set(
      produce((state: VariableStateData) => {
        state.variables[variable.id] = variable;
      }),
    );
  },
  resetVariables: () => {
    set(initialVariableState);
  },
});

import { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  WorkflowStatusEnum,
  type WorkflowMetadataType,
} from 'api-types-shared';
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridValueFormatterParams,
} from '@mui/x-data-grid/models';
import {
  type WorkflowListMenuProps,
  type WorkflowStatusSelectItem,
} from '../types';
import { IconButton, Select, Tooltip, MoreVert } from 'ui-kit';
import { clsx } from 'clsx';
import dayjs from '../../../config/dayjs';
import StatusLabel from '../components/StatusLabel';
import {
  workflowStatusSelectOptions,
  workflowListStatusToTitleMap,
} from '../../../utils/constants';
import { isAdmin } from '../../../utils/env';
import WorkflowChip from '../components/Chips/WorkflowChip';

const useWorkflowCols = ({
  showEmailCol,
  demoFeaturesEnabled,
  workflowUpdateStatus,
  setMenuProps,
  handleUpdateWorkflow,
}: {
  showEmailCol: boolean;
  demoFeaturesEnabled: boolean;
  workflowUpdateStatus: 'success' | 'error' | 'idle' | 'pending';
  setMenuProps: (props: WorkflowListMenuProps) => void;
  handleUpdateWorkflow: (workflow: WorkflowMetadataType) => void;
}): GridColDef<WorkflowMetadataType>[] => {
  const handleSetMenuProps = useCallback(setMenuProps, [setMenuProps]);

  const columns: GridColDef<WorkflowMetadataType>[] = useMemo(
    () => [
      {
        field: 'workflowName',
        headerName: 'Workflow Name',
        flex: isAdmin ? 2 : 4,
      },
      ...(isAdmin
        ? [
            {
              field: 'workflowId',
              headerName: 'Workflow ID',
              flex: 2,
            },
          ]
        : []),

      ...(showEmailCol
        ? [
            {
              field: 'email',
              headerName: 'Owner',
              flex: isAdmin ? 1.5 : 2.5,

              renderCell: (
                params: GridRenderCellParams<WorkflowMetadataType>,
              ) => {
                return (
                  <Tooltip
                    hidden={!isAdmin}
                    title={
                      <>
                        <p>
                          <b>Email:</b> {params.row.email}
                        </p>
                        <p>
                          <b>ID:</b> {params.row.userId}
                        </p>
                      </>
                    }
                  >
                    <p>{params.row.email}</p>
                  </Tooltip>
                );
              },
            },
          ]
        : []),

      {
        field: 'createdAt',
        headerName: 'Created At',
        valueGetter: (params) => new Date(params.row.createdAt),
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          dayjs(params.value).format('MM/DD/YY -  h:mm A'),
        flex: isAdmin ? 1.2 : 3,
      },
      {
        field: 'updatedAt',
        headerName: 'Last Modification',
        valueGetter: (params) =>
          new Date(params.row.updatedAt ?? params.row.createdAt),
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          dayjs(params.value).format('MM/DD/YY -  h:mm A'),
        flex: (() => {
          if (isAdmin) return 1.2;
          if (demoFeaturesEnabled) return 2.5;
          return 3;
        })(),
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params) => {
          const workflowStatus =
            params.row.status ?? WorkflowStatusEnum.ProcessingImport;
          const workflowTitle = isAdmin
            ? workflowStatus
            : workflowListStatusToTitleMap[workflowStatus] ?? workflowStatus;

          return (
            <WorkflowChip
              className={clsx('!h-6 !overflow-x-hidden', {
                '!bg-info-dark':
                  workflowTitle === WorkflowStatusEnum.ProcessingImport &&
                  !isAdmin,
              })}
              classes={{
                label: '!overflow-hidden !max-w-full',
              }}
              status={workflowStatus}
              label={
                <StatusLabel
                  showProcessingState={!isAdmin}
                  workflowStatus={workflowTitle}
                />
              }
              variant="filled"
            />
          );
        },
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'action',
        headerName: '',
        align: 'right',
        minWidth: (() => {
          if (isAdmin) return 320;
          if (demoFeaturesEnabled) return 220;
          return 180;
        })(),
        renderCell: (params) => {
          if (
            demoFeaturesEnabled &&
            params.row.status === WorkflowStatusEnum.ProcessingImport
          ) {
            return (
              <>
                <Link
                  className="px-3 py-1.5 uppercase rounded border border-sola-primary text-sola-primary font-medium text-sm hover:bg-sola-primary-4 hover:border-sola-primary-dark hover:text-sola-primary-dark mr-4"
                  to={`/workflows/${params.row.workflowId}`}
                >
                  APPROVE & PROCESS
                </Link>
                <IconButton
                  className="!p-0"
                  onClick={(e) => {
                    handleSetMenuProps({
                      anchorEl: e.target as HTMLButtonElement,
                      ...params.row,
                    });
                  }}
                >
                  <MoreVert className="text-secondary-blue" />
                </IconButton>
              </>
            );
          }

          return (
            <span className="flex items-center gap-4">
              {(params.row.status !== WorkflowStatusEnum.ProcessingImport &&
                params.row.status !==
                  WorkflowStatusEnum.ProcessingImportError) ||
              isAdmin ? (
                <>
                  {isAdmin ? (
                    <Select<WorkflowStatusSelectItem>
                      disabled={workflowUpdateStatus === 'pending'}
                      getLabel={(option) => option.label}
                      getValue={(option) => option.value}
                      onChange={(event) => {
                        handleUpdateWorkflow({
                          ...params.row,
                          status: event.target.value as WorkflowStatusEnum,
                        });
                      }}
                      options={workflowStatusSelectOptions}
                      value={
                        params.row.status as unknown as WorkflowStatusSelectItem
                      }
                    />
                  ) : null}
                  <Link
                    className="px-3 py-1.5 uppercase rounded border border-sola-primary text-sola-primary font-medium text-sm hover:bg-sola-primary-4 hover:border-sola-primary-dark hover:text-sola-primary-dark"
                    to={`/workflows/${params.row.workflowId}`}
                  >
                    View Details
                  </Link>
                  <IconButton
                    className="!p-0"
                    onClick={(e) => {
                      handleSetMenuProps({
                        anchorEl: e.target as HTMLButtonElement,
                        ...params.row,
                      });
                    }}
                  >
                    <MoreVert className="text-secondary-blue" />
                  </IconButton>
                </>
              ) : null}
            </span>
          );
        },
        flex: 2,
        sortable: false,
      },
    ],
    [
      demoFeaturesEnabled,
      showEmailCol,
      handleUpdateWorkflow,
      workflowUpdateStatus,
      handleSetMenuProps,
    ],
  );

  return columns;
};

export default useWorkflowCols;

import { Fragment, useMemo } from 'react';
import {
  parseVariable,
  QueryVariable,
  type ScrapeVariable,
  SourceTypeEnum,
  SourceVariable,
  VariableString,
  VariableTypeEnum,
  DocumentSourceEnum,
  type VariableRef,
} from 'types-shared';
import { getVariableChipContent, VariableChip } from '../VariableChip';
import { GrayedOutInput, Switch, AutoFixInactiveIcon } from 'ui-kit';
import {
  VariableEventsEnum,
  type PreviewVariableProps,
} from '../../utils/variableModal';
import { handleException } from 'sentry-browser-shared';

type VariableData = string | VariableRef;

export function OtherVariablesModalContent({
  variable,
  variables,
  globalVariables,
  datasourceMetadata,
  transformationResult,
  variablesMap,
  transformationKey,
  changeModalAction,
}: PreviewVariableProps) {
  const isApiCall = useMemo(() => {
    if (!QueryVariable.safeParse(variable).success) {
      return false;
    }
    const { data } = QueryVariable.parse(variable);
    const { sourceIds } = data;

    const sourceVar = variables[sourceIds[0]];
    if (sourceVar.type !== VariableTypeEnum.Source) {
      return false;
    }
    const source = SourceVariable.parse(sourceVar);
    return source.data.sourceType === SourceTypeEnum.API;
  }, [variable, variables]);

  const isScrape = variable.type === VariableTypeEnum.Scrape;

  const isDocumentScrape =
    variable.type === VariableTypeEnum.Document &&
    variable.data.source === DocumentSourceEnum.Execution;
  const isSource = variable.type === VariableTypeEnum.Source;
  const isQuery = variable.type === VariableTypeEnum.Query;
  const isGlobalVariable = variable.type === VariableTypeEnum.Global;

  const variableExample = useMemo(() => {
    if (!isScrape && !isSource && !isDocumentScrape) {
      return VariableString.parse(
        parseVariable({ variable, variableMap: variables, handleException }),
      );
    }
    return '';
  }, [isScrape, isDocumentScrape, isSource, variable, variables]);

  const scrapedText = useMemo(() => {
    if (isScrape && (variable as ScrapeVariable).data.scrapedText) {
      return (variable.data as { scrapedText: string }).scrapedText
        .split('\n')
        .map((line: string) => (
          <Fragment key={line}>
            {line}
            <br />
          </Fragment>
        ));
    }
    return '';
  }, [isScrape, variable]);

  const renderPrompt = (dataLike: VariableData[]) =>
    dataLike
      .filter((val) => val !== '')
      .map((val: VariableData) => {
        if (typeof val === 'string') {
          return <span key={val}>{val}</span>;
        }

        return (
          <VariableChip
            key={val.id}
            variableId={val.id}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariables}
          />
        );
      });

  const renderSource = () => {
    if (variable.type === VariableTypeEnum.Query) {
      return getVariableChipContent(variables[variable.data.sourceIds[0]]);
    } else if (isScrape) {
      return 'Scraped during the execution';
    } else if (isApiCall) {
      return 'API Call';
    } else if (isGlobalVariable) {
      return 'Global Variable';
    } else if (isDocumentScrape) {
      return 'Document scraped during the workflow';
    }

    return datasourceMetadata?.name;
  };

  return (
    <div className="flex-1 py-5">
      <GrayedOutInput
        className="mb-5"
        label="Variable name"
        value={variable.name}
      />

      {!isSource ? (
        <GrayedOutInput
          className="mb-5"
          label="Variable source"
          value={renderSource()}
          isSelect
        />
      ) : null}

      {(variable.data as { valueType: string }).valueType ? (
        <GrayedOutInput
          className="mb-5"
          label="Variable type"
          value={(variable.data as { valueType: string }).valueType}
          isSelect
        />
      ) : null}

      {!isScrape &&
      !isSource &&
      !isGlobalVariable &&
      !isDocumentScrape &&
      !isApiCall &&
      !isQuery ? (
        <GrayedOutInput
          className="mb-5"
          label="Column name"
          value={VariableString.parse(
            parseVariable({
              variable,
              variableMap: variables,
              handleException,
            }),
          )}
        />
      ) : null}

      {transformationKey?.length ? (
        <GrayedOutInput
          className="mb-5"
          label="Key"
          value={renderPrompt(transformationKey)}
        />
      ) : null}

      {transformationResult ? (
        <div className="rounded-lg mb-9 mt-5 p-px !bg-gradient-to-r from-gray-300 to-gray-500">
          <div className="rounded-lg p-7 text-sm flex flex-col bg-white overflow-y-hidden">
            <div className="flex justify-between items-center">
              <div className="flex gap-4">
                <AutoFixInactiveIcon
                  fontSize="small"
                  className="text-gray-400"
                />
                <span className="font-medium mb-1 text-gray-400">
                  Apply GPT Transformations
                </span>
              </div>

              <Switch
                color="secondary"
                checked={false}
                className="transform scale-x-[-1]"
                onChange={() => {
                  changeModalAction(VariableEventsEnum.EDIT_VARIABLE);
                }}
              />
            </div>

            <div className="mt-5 text-sm text-gray-400">
              Automatically convert the variable’s data with a natural language
              prompt.
            </div>

            <div className="">
              <div className="mb-5 mt-5 text-slate-500 text-sm">
                Add a variable value example to preview the transformation
                result that will be applied
              </div>

              <div className="relative flex flex-col mt-3 pt-1">
                <GrayedOutInput
                  className="mb-5"
                  label="Variable value example"
                  value={
                    VariableString.parse(
                      variable.dashboardData?.initialValue ?? '',
                    ) || (isScrape ? scrapedText : variableExample)
                  }
                />

                {variable.dashboardData?.transformInputs?.query &&
                variable.dashboardData.transformInputs.query.length > 0 ? (
                  <GrayedOutInput
                    className="mb-5 w-full"
                    label="Prompt"
                    valueStyle={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      wordBreak: 'break-word',
                    }}
                    value={renderPrompt(
                      variable.dashboardData.transformInputs.query,
                    )}
                  />
                ) : null}
              </div>

              <div className="font-medium mt-8 text-gray-400">
                Transformation result
              </div>
              <div className="mt-1 p-[1px] !rounded bg-zinc-100">
                <div className="!rounded-sm p-3 min-h-[2.75rem] break-words bg-zinc-100 whitespace-pre-wrap font-mono text-gray-500">
                  {transformationResult ? (
                    transformationResult
                  ) : (
                    <span className="text-neutral-400">
                      Transformation Result
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

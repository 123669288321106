import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useMemo } from 'react';
import {
  type SelectOption,
  type Variable,
  type GlobalVariable,
  type VariableMap,
  VariableTypeEnum,
} from 'types-shared';
import { VariableChip } from '../../../../components/VariableChip';
import { useFeatureFlag } from '../../../../utils/helper';
import { FeatureFlag } from '../../../../utils/constants';

interface Props {
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
  onSelect: (val: Variable | string) => void;
  onAddNew?: () => void;
  options: SelectOption[];
  variables: Variable[];
  allowAddVariable: boolean;
  variablesMap: Record<string, Variable>;
  globalVariables: GlobalVariable[];
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
}

function SelectMenu({
  anchorEl,
  open,
  onClose,
  onSelect,
  onAddNew,
  variables = [],
  options = [],
  allowAddVariable = true,
  variablesMap,
  globalVariables = [],
  globalVariablesMap,
}: Props) {
  const systemVariablesEnabled = useFeatureFlag(
    FeatureFlag.SystemVariables,
    true,
  );

  const filteredVariables = useMemo(
    () =>
      variables.filter(
        (variable) =>
          Boolean(systemVariablesEnabled) ||
          variable.type !== VariableTypeEnum.Execution,
      ),
    [variables, systemVariablesEnabled],
  );

  return (
    <Menu
      anchorEl={anchorEl}
      classes={{
        paper: '!max-w-none',
        list: '!p-0',
      }}
      disablePortal
      onClose={onClose}
      open={open}
      slotProps={{
        paper: {
          sx: {
            width: `${anchorEl?.clientWidth.toString() ?? '0'}px`,
          },
        },
      }}
      sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}
    >
      {options.map(({ text, value }: SelectOption) => (
        <MenuItem
          className="!px-3 !py-2.5"
          divider
          key={value}
          onClick={() => {
            onSelect(text);
            onClose();
          }}
        >
          {text}
        </MenuItem>
      ))}
      {[...filteredVariables, ...globalVariables].map(
        (variable: Variable, i) => (
          <MenuItem
            className="!px-3 !py-2.5"
            divider={i === variables.length - 1}
            key={variable.id}
            onClick={() => {
              onSelect(variable);
              onClose();
            }}
          >
            <VariableChip
              variableId={variable.id}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
            />
          </MenuItem>
        ),
      )}
      {allowAddVariable ? (
        <MenuItem
          className="!border-t !border-t-gray-300 !text-blue-600 !px-3 !py-2.5"
          onClick={() => onAddNew?.()}
        >
          Add New Variable
        </MenuItem>
      ) : null}
    </Menu>
  );
}

export default SelectMenu;

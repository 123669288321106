"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateWorkflowSchema = void 0;
var types_shared_1 = require("types-shared");
var utils_schema_1 = require("../utils.schema");
var workflow_schema_1 = require("./workflow.schema");
var add_workflow_schema_1 = require("./add-workflow.schema");
exports.updateWorkflowSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
        query: types_shared_1.z.object({
            stateReq: utils_schema_1.booleanStringTransform,
            targetReq: utils_schema_1.booleanStringTransform,
            variableReq: utils_schema_1.booleanStringTransform,
        }),
        body: types_shared_1.z.object({
            workflowName: types_shared_1.z.string().optional(),
            status: workflow_schema_1.WorkflowStatus.optional(),
            imageHashes: types_shared_1.z.array(types_shared_1.z.string()).optional(),
            videoReqId: types_shared_1.z.string().optional(),
            revertVersionId: types_shared_1.z.string().optional(),
            adminUserPush: workflow_schema_1.AdminVersion.optional(),
        }),
    }),
    response: types_shared_1.z.object({
        stateUrl: types_shared_1.z.string().optional(),
        targetUrl: types_shared_1.z.string().optional(),
        variableUrl: types_shared_1.z.string().optional(),
        imageUploadMap: add_workflow_schema_1.ImageUploadData.optional(),
        videoUpload: types_shared_1.z
            .object({
            uploadId: types_shared_1.z.string(),
            urls: types_shared_1.z.array(types_shared_1.z.string()),
        })
            .optional(),
        versionId: types_shared_1.z.string().optional(),
    }),
};

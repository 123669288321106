import { LaunchIcon, Delete, IconButton, EditPencilIcon, Flex } from 'ui-kit';
import React from 'react';
import {
  BranchModeEnum,
  NodeTypesEnum,
  parseTemplateString,
  ParseVariableMode,
  SourceTypeEnum,
  type SourceVariable,
  TemplateVariable,
  type VariableMap,
  VariableString,
  type WorkflowNode,
  type TemplateData,
} from 'types-shared';
import {
  getRequestNodeTitle,
  getTriggerBlockShortTitle,
  type ScreenshotUrl,
} from '../utils';
import { isAdmin } from '../../../utils/env';
import { Link } from 'react-router-dom';
import { handleException } from 'sentry-browser-shared';

export default function ExecutionScreenshotDetails({
  selectedIndex,
  hasError,
  currentNode,
  sourceVariable,
  receiverEmail,
  screenshotUrlsLength,
  variablesData,
  setShowUpdateErrModal,
  onDeleteScreenshot,
  workflowId,
  selectedScreenshot,
}: {
  hasError: boolean;
  currentNode: WorkflowNode | undefined;
  completedStepsLength: number;
  sourceVariable: SourceVariable | undefined;
  receiverEmail: string | undefined;
  screenshotUrlsLength: number;
  selectedIndex: number | undefined;
  variablesData?: VariableMap;
  setShowUpdateErrModal: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteScreenshot?: (
    id: string,
    isImage: boolean,
    item: ScreenshotUrl,
  ) => void;
  selectedScreenshot: ScreenshotUrl;
  workflowId?: string;
}) {
  const {
    nodeType,
    type,
    sortData: { nodeId, name },
  } = selectedScreenshot;
  const sortDataName = name ?? nodeId;

  return (
    <div className="flex flex-col space-y-2 p-9">
      <Flex alignItems="center" justifyContent="space-between">
        {nodeId && workflowId ? (
          <Link
            to={`/editor/${workflowId}${nodeType === NodeTypesEnum.Image ? `?focusNode=${nodeId}` : ''}`}
            target="_blank"
            className="flex items-center space-x-2"
          >
            <p className="text-xl font-medium">
              Step <span className="text-base ml-1">{nodeId.slice(0, 5)}</span>
            </p>
            <LaunchIcon className="!w-5 !h-5" />
          </Link>
        ) : null}
        <div className="flex items-center space-x-3">
          {isAdmin &&
          (selectedIndex === screenshotUrlsLength - 1 ||
            selectedIndex === -1) ? (
            <IconButton
              className="!p-0"
              onClick={() => {
                setShowUpdateErrModal(true);
              }}
            >
              <div className="flex space-x-2">
                <span className="text-red-900 text-sm font-medium">
                  {hasError ? 'Update' : 'Add'} Error
                </span>

                <EditPencilIcon className="!w-5 !h-5 text-red-900" />
              </div>
            </IconButton>
          ) : null}
          {isAdmin ? (
            <IconButton
              className="!p-0"
              onClick={() => {
                if (onDeleteScreenshot && sortDataName) {
                  onDeleteScreenshot(
                    sortDataName,
                    type === 'blob',
                    selectedScreenshot,
                  );
                }
              }}
            >
              <Delete className="!w-5 !h-5 !text-red-500" />
            </IconButton>
          ) : null}
        </div>
      </Flex>
      {currentNode?.type === NodeTypesEnum.Source ? (
        <>
          {sourceVariable?.data.sourceType === SourceTypeEnum.EmailTrigger ? (
            <>
              <HeaderRow title="Type" value="Trigger" />
              <HeaderRow
                title="Name"
                value={getTriggerBlockShortTitle(sourceVariable)}
              />
              {receiverEmail ? (
                <HeaderRow title="Receiver email" value={receiverEmail} />
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
      {currentNode?.type === NodeTypesEnum.Request ? (
        <>
          <HeaderRow title="Type" value={getRequestNodeTitle(currentNode)} />
          <HeaderRow title="Name" value={currentNode.name} />
          <HeaderRow title="Description" value={currentNode.data.description} />
        </>
      ) : null}
      {currentNode && currentNode.type === NodeTypesEnum.Freeform ? (
        <HeaderRow
          title="Instructions"
          value={VariableString.parse(
            parseTemplateString({
              data: variablesData
                ? (
                    variablesData[
                      currentNode.data.instructions.variableId
                    ] as TemplateVariable
                  ).data
                : [],
              variableMap: variablesData ?? {},
              mode: ParseVariableMode.Execution,
              handleException,
            }),
          )}
        />
      ) : null}

      {currentNode?.type === NodeTypesEnum.Conditional
        ? currentNode.data.branchesData?.map((branch) => {
            if (branch.selectedMode === BranchModeEnum.Instruction) {
              let thisBranchData: TemplateData = [];

              if (variablesData) {
                const branchInstructionVariable =
                  variablesData[branch.instruction.variableId];
                if (
                  TemplateVariable.safeParse(branchInstructionVariable).success
                ) {
                  thisBranchData = TemplateVariable.parse(
                    branchInstructionVariable,
                  ).data;
                } else {
                  handleException(
                    new Error(`Invalid branch instruction variable.`),
                    {
                      name: 'ExecutionScreenshotDetails/ConditionalBranch',
                      extra: {
                        variablesData,
                        branch,
                        workflowId,
                        currentNode,
                      },
                    },
                  );
                  return null;
                }
              }

              return (
                <HeaderRow
                  key={JSON.stringify(branch.instruction)}
                  title="Instructions"
                  value={VariableString.parse(
                    parseTemplateString({
                      data: thisBranchData,
                      variableMap: variablesData ?? {},
                      mode: ParseVariableMode.Execution,
                      handleException,
                    }),
                  )}
                />
              );
            }
            return null;
          })
        : null}
    </div>
  );
}

export function HeaderRow({ title, value }: { title: string; value?: string }) {
  if (!value) return null;
  return (
    <p className="text-sm font-medium">
      <span>{title}:</span> &nbsp;
      <span className="text-slate-500">{value}</span>
    </p>
  );
}

import { useNavigate, useParams } from 'react-router-dom';
import {
  useDuplicateWorkflow,
  useFetchWorkflowExecutionDetails,
  useFetchWorkflowMetadata,
  useUpdateWorkflowName,
  useWorkflowDetailCols,
} from '../hooks';
import { useFeatureFlag } from '../../../utils/helper';
import { isUUID } from '../../Editor/utils/helper';
import {
  ContentContainer,
  Flex,
  ScrollableContainer,
  SortOrder,
  CustomTypography,
} from 'ui-kit';
import { AdminVersionEnum, WorkflowStatusEnum } from 'api-types-shared';
import { type ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { isAdmin } from '../../../utils/env';

import { Toolbar } from '../../../components/Toolbar';
import {
  WorkflowDetailSkeleton,
  WorkflowDetailSkeletonHeader,
} from '../components/WorkflowDetailSkeleton';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { NoDataFound } from '../../../components/NoDataFound';
import CloneModal from '../components/CloneModal';
import {
  EXECUTIONS_LIST_REFETCH_INTERVAL,
  FeatureFlag,
} from '../../../utils/constants';
import { useUpdateExecution } from '../../Execution/hooks';
import type { CompactWorkflowMetadata } from '../types';
import WorkflowDetailHeader from '../components/WorkflowDetailHeader';
import ExecutionListFilter from '../components/ExecutionListFilter';
import {
  executionListFiltersSchema,
  defaultFilters,
  mapStatuses,
  hasFilters,
} from '../utils/helper';
import { type GridSortModel } from '@mui/x-data-grid/models';
import usePersistedURLState from '../../../hooks/usePersistedURLState';
import WorkflowExecutionMetrics from '../components/WorkflowExecutionMetrics';
import CircularProgress from '@mui/material/CircularProgress';

const sortOrder = [SortOrder.DESC, SortOrder.ASC];

export default function WorkflowDetail() {
  const navigate = useNavigate();
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('WorkflowId not found!');
  }

  if (!isUUID(workflowId)) {
    navigate('/');
  }

  const inputRef = useRef<HTMLInputElement>(null);
  const [filters, setFilters] = usePersistedURLState(
    executionListFiltersSchema,
    defaultFilters,
    `executions-list-filter-${workflowId}`,
  );
  const [orderBy, setOrderBy] = useState<GridSortModel>([
    { field: 'createdAt', sort: SortOrder.DESC },
  ]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [timeToRefresh, setTimeToRefresh] = useState<number>(
    EXECUTIONS_LIST_REFETCH_INTERVAL,
  );
  const [workflowName, setWorkflowName] = useState<string>('');
  const [cloneWorkflowData, setCloneWorkflowData] =
    useState<CompactWorkflowMetadata | null>(null);
  const showFilters = useFeatureFlag(FeatureFlag.ExecutionsFilters);

  const { data: workflowMetadata, isLoading: fetchingWorkflowMetadata } =
    useFetchWorkflowMetadata(workflowId);
  const statusValue = useMemo(() => {
    const status = filters.status[0];
    return !status || (filters.status.length === 1 && status === 'All')
      ? undefined
      : mapStatuses(filters.status.slice(1));
  }, [filters.status]);
  const {
    isLoading: executionsListLoading,
    refetch: refetchExecutions,
    data: executionsData,
    isRefetching: executionsListFetching,
    dataUpdatedAt: executionsListDataUpdatedAt,
  } = useFetchWorkflowExecutionDetails(
    {
      workflowId,
      adminRun:
        typeof filters.hideAdminRuns === 'boolean'
          ? filters.hideAdminRuns
          : undefined,
      status: statusValue,
      executionId: filters.id ? filters.id : undefined,
    },
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      orderBy,
    },
  );
  const { executions = [], totalCount = 0 } = executionsData ?? {};
  const isLoading = executionsListLoading || executionsListFetching;
  // const { mutateAsync, status } = useDownloadExecutionData(); - TODO: re-enable after M&M sandbox
  // const { mutateAsync: downloadExecutionData } = useDownloadExecutionData();
  // const hideRunId = useFeatureFlag(FeatureFlag.HideRunId, false);
  const { status: executionUpdateStatus, mutate: updateExecution } =
    useUpdateExecution();
  const { mutateAsync: cloneWorkflow } = useDuplicateWorkflow();
  const editWorkflowEnabled = useFeatureFlag(FeatureFlag.EditWorkflow);
  const metricsEnabled =
    useFeatureFlag(FeatureFlag.ExecutionAndWorkflowListMetrics) ?? false;
  const executionDetailsEnabled = Boolean(
    useFeatureFlag(FeatureFlag.ExecutionDetails),
  );

  const { mutateAsync: updateWorkflowMetadata } = useUpdateWorkflowName();

  const hasSuggestions = useMemo(() => {
    return workflowMetadata?.currentVersionCommitUsers?.includes(
      AdminVersionEnum.ForcePush,
    );
  }, [workflowMetadata?.currentVersionCommitUsers]);

  const hasFiltersApplied = useMemo(() => {
    return hasFilters(filters);
  }, [filters]);

  const columns = useWorkflowDetailCols({
    updateExecution,
    executionUpdateStatus,
    executionDetailsEnabled,
  });

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkflowName(event.target.value);
  };

  const updateWorkflowName = async () => {
    setIsEditingName(false);
    if (inputRef.current) {
      inputRef.current.scrollLeft = 0;
    }
    if (workflowMetadata?.workflowName !== workflowName) {
      await updateWorkflowMetadata({ workflowId, workflowName });
    }
  };

  const onCloneWorkflow = async (val: {
    workflowId: string;
    workflowName: string;
  }) => {
    const { newWorkflowId } = await cloneWorkflow(val);
    navigate(`/editor/${newWorkflowId}`);
  };

  const onResetPage = () => {
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
  };

  useEffect(() => {
    if (
      workflowMetadata?.status === WorkflowStatusEnum.ProcessingImport &&
      !isAdmin
    ) {
      navigate('/workflows');
    }
  }, [navigate, workflowMetadata]);

  useEffect(() => {
    if (workflowMetadata?.workflowName) {
      setWorkflowName(workflowMetadata.workflowName);
    }
  }, [workflowMetadata]);

  useEffect(() => {
    void refetchExecutions();
  }, [orderBy, paginationModel, refetchExecutions]);

  useEffect(() => {
    setTimeToRefresh(EXECUTIONS_LIST_REFETCH_INTERVAL);
  }, [executionsListDataUpdatedAt]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToRefresh((prev) => {
        if (prev === 0) {
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ScrollableContainer>
      <Toolbar
        onBack={() => {
          navigate('/workflows', { replace: true });
        }}
        pageInfo={`Workflow ID: ${workflowId}`}
      />
      <ContentContainer withToolbar>
        {fetchingWorkflowMetadata || !workflowMetadata ? (
          <WorkflowDetailSkeletonHeader />
        ) : (
          <WorkflowDetailHeader
            setCloneWorkflowData={setCloneWorkflowData}
            setIsEditingName={setIsEditingName}
            isEditingName={isEditingName}
            editWorkflowEnabled={Boolean(editWorkflowEnabled)}
            workflowName={workflowName}
            inputRef={inputRef}
            workflowMetadata={workflowMetadata}
            workflowId={workflowId}
            updateWorkflowName={updateWorkflowName}
            onNameChange={onNameChange}
            hasSuggestions={Boolean(hasSuggestions)}
          />
        )}

        {metricsEnabled || isAdmin ? (
          <WorkflowExecutionMetrics workflowId={workflowId} />
        ) : null}

        <div className="flex items-end justify-between mt-8">
          <div className="flex flex-col space-y-1">
            <Flex className="gap-2" flexDirection="column">
              <CustomTypography color="text.primary" variant="h5">
                Executions
              </CustomTypography>
            </Flex>
            <Flex className="items-center" flexDirection="row">
              <div className="min-w-max">
                <CustomTypography color="text.secondary" variant="body2">
                  Details for all your current and past runs.
                </CustomTypography>
              </div>
            </Flex>
          </div>

          <div className="flex items-center min-w-max">
            <span className="text-sm text-gray-500 min-w-max mr-4">
              Auto-updating
            </span>
            <CircularProgress
              color="secondary"
              size={20}
              thickness={5}
              sx={{ scale: '-1 1' }}
              variant="determinate"
              value={((20 - timeToRefresh) / 20) * 100}
            />
          </div>
        </div>

        {(isAdmin || showFilters) && (hasFiltersApplied || totalCount > 0) ? (
          <ExecutionListFilter
            filters={filters}
            setFilters={setFilters}
            onResetPage={onResetPage}
            onRefresh={() => {
              void refetchExecutions();
            }}
          />
        ) : null}

        <div>
          {isLoading ? (
            <WorkflowDetailSkeleton columns={columns} />
          ) : (
            <>
              {executions.length > 0 ? (
                <DataGrid
                  columns={columns}
                  getRowId={(row) => row.executionId}
                  rows={executions}
                  sortingOrder={sortOrder}
                  rowCount={totalCount}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  paginationMode="server"
                  sortModel={orderBy}
                  onSortModelChange={(val) => {
                    onResetPage();
                    setOrderBy(val);
                  }}
                />
              ) : (
                <NoDataFound
                  className="mt-4"
                  heading="Execution details"
                  subHeading="No execution matches those filters."
                />
              )}
            </>
          )}
        </div>
      </ContentContainer>
      {cloneWorkflowData ? (
        <CloneModal
          onCancel={() => {
            setCloneWorkflowData(null);
          }}
          onSubmit={() => {
            void onCloneWorkflow(cloneWorkflowData);
          }}
          open
          setWorkflowName={(name: string) => {
            setCloneWorkflowData({
              workflowId: cloneWorkflowData.workflowId,
              workflowName: name,
            });
          }}
          workflowName={cloneWorkflowData.workflowName}
        />
      ) : null}
    </ScrollableContainer>
  );
}

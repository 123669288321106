import {
  Add,
  CloseIcon,
  Button,
  EditPencilIcon,
  IconButton,
  Label,
  SubLabel,
  Switch,
} from 'ui-kit';
import React, { useMemo, useState } from 'react';
import {
  DocumentVariable,
  NodeStatusEnum,
  type QueryVariable,
  type TemplateData,
  type Variable,
  VariableTypeEnum,
  type WorkflowDocumentNode,
  type GlobalVariable,
  type VariableMap,
} from 'types-shared';
import { AddEditDocumentVariable } from './AddEditDocumentVariable';
import { AddEditQueryVariable } from './AddEditQueryVariable';
import { DocumentLinkedQueryVariables } from './DocumentLinkedQueryVariables';
import { useDocumentQueryVariables } from './useDocumentQueryVariables';
import { NodeCheck } from '../../NodeCheck';
import { VariableChip } from '../../../../../components/VariableChip';

interface Props {
  node: WorkflowDocumentNode;
  updateNode: (node: WorkflowDocumentNode) => void;
  onCancel: () => void;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  onUploadFile: (file: File) => Promise<{ fileId: string }>;

  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    query: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
  updateNodeStatus: (status: NodeStatusEnum) => void;
}

export function DocumentsBlock({
  node,
  updateNode,
  onCancel,
  variablesMap,
  globalVariablesMap,
  addVariable,
  updateVariable,
  onUploadFile,

  transformApiReqStatus,
  onTransformApiReq,
  updateNodeStatus,
}: Props) {
  const [addingDocumentVariable, setAddingDocumentVariable] = useState(false);
  const [editingDocumentVariable, setEditingDocumentVariable] =
    useState<DocumentVariable | null>(null);

  const [addingQueryVariable, setAddingQueryVariable] = useState(false);
  const [editingQueryVariable, setEditingQueryVariable] =
    useState<QueryVariable | null>(null);

  const documentVariables = useMemo(() => {
    return Object.values(variablesMap).filter(
      (variable): variable is DocumentVariable =>
        DocumentVariable.safeParse(variable).success &&
        variable.type === VariableTypeEnum.Document &&
        variable.data.nodeId === node.id,
    );
  }, [node.id, variablesMap]);

  const queryVariables = useDocumentQueryVariables({
    documentVariables,
    variablesMap,
  });

  const unlinkVariable = (variable: DocumentVariable) => {
    updateVariable({
      ...variable,
      data: { ...variable.data, nodeId: undefined },
    });
  };

  const handleAddVariable = (v: DocumentVariable) => {
    addVariable(v);

    // add new document variable in the existing query variables
    queryVariables.forEach((queryVariable) => {
      updateVariable({
        ...queryVariable,
        data: {
          ...queryVariable.data,
          sourceIds: [...queryVariable.data.sourceIds, v.id],
        },
      });
    });
  };

  if (addingDocumentVariable) {
    return (
      <AddEditDocumentVariable
        node={node}
        onCancel={() => {
          setAddingDocumentVariable(false);
        }}
        onUploadFile={onUploadFile}
        variable={undefined}
        onSubmit={(v) => {
          handleAddVariable(v);
        }}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
      />
    );
  }

  if (editingDocumentVariable) {
    return (
      <AddEditDocumentVariable
        node={node}
        onCancel={() => {
          setEditingDocumentVariable(null);
        }}
        variable={editingDocumentVariable}
        onUploadFile={onUploadFile}
        onSubmit={(v) => {
          updateVariable(v);
        }}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
      />
    );
  }

  if (addingQueryVariable) {
    return (
      <AddEditQueryVariable
        onCancel={() => {
          setAddingQueryVariable(false);
        }}
        addVariable={addVariable}
        updateVariable={updateVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        documentVariables={documentVariables}
        onTransformApiReq={onTransformApiReq}
        transformApiReqStatus={transformApiReqStatus}
      />
    );
  }

  if (editingQueryVariable) {
    return (
      <AddEditQueryVariable
        variable={editingQueryVariable}
        onCancel={() => {
          setEditingQueryVariable(null);
        }}
        addVariable={addVariable}
        updateVariable={updateVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        documentVariables={documentVariables}
        onTransformApiReq={onTransformApiReq}
        transformApiReqStatus={transformApiReqStatus}
      />
    );
  }

  return (
    <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col justify-between space-y-5">
      <div className="overflow-auto">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">
            Documents step
          </span>
          <Button
            className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            <CloseIcon className="text-info" />
          </Button>
        </div>
        <div className="my-6">
          <h2 className="text-cyan-900 text-lg font-medium leading-relaxed tracking-tight">
            Documents step
          </h2>
        </div>
        <div className="space-y-6">
          <div>
            <Label>Add documents</Label>
            <SubLabel>Included as an output and saved as a variable</SubLabel>
          </div>

          {documentVariables.length ? (
            <div className="flex flex-wrap gap-2 mb-6">
              {documentVariables.map((variable) => {
                return (
                  <VariableChip
                    key={variable.id}
                    variableId={variable.id}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    onClick={() => {
                      setEditingDocumentVariable(variable);
                    }}
                    onDelete={() => {
                      unlinkVariable(variable);
                    }}
                  />
                );
              })}
            </div>
          ) : null}

          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setAddingDocumentVariable(true);
            }}
          >
            <Add className="mr-2" />
            Add document
          </Button>
        </div>

        {documentVariables.length ? (
          <div className="mt-10 rounded-lg border border-slate-300">
            <div className="p-6">
              <div className="flex justify-between items-center">
                <Label>Create variables from the documents</Label>
                <IconButton
                  onClick={() => {
                    setAddingQueryVariable(true);
                  }}
                >
                  <EditPencilIcon className="text-cyan-900" />
                </IconButton>
              </div>
              <SubLabel className="w-3/4">
                Use our AI models to query values from documents and create
                variables
              </SubLabel>
              <div className="mt-6">
                <DocumentLinkedQueryVariables
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  documentVariables={documentVariables}
                  onClick={setEditingQueryVariable}
                  noDataUI={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setAddingQueryVariable(true);
                      }}
                    >
                      Create variables
                    </Button>
                  }
                />
              </div>
            </div>
            <div className="px-4 py-4 flex justify-between items-center border-t border-slate-300">
              <Label>Surface variables as outputs</Label>
              <Switch
                color="secondary"
                checked={node.data.surfaceOutputVariables}
                onChange={(_, checked) => {
                  updateNode({
                    ...node,
                    data: { ...node.data, surfaceOutputVariables: checked },
                  });
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col space-y-7">
        <NodeCheck
          description="Mark as reviewed if ready to execute this step"
          isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
          updateNodeStatus={updateNodeStatus}
        />
        <Button
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          Back to flow view
        </Button>
      </div>
    </div>
  );
}

import { RRule } from 'rrule';
import dayjs from '../../config/dayjs';
import { formatWithSuffix } from '../../utils/helper';
import isNil from 'lodash/isNil';

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export enum TriggerInterval {
  DoesNotRepeat = 'Does not repeat',
  Daily = 'Daily',
  EveryWeekday = 'Every weekday',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export const triggerIntervalOptsMap: Record<string, string> = {
  [TriggerInterval.DoesNotRepeat]: 'Does not repeat',
  [TriggerInterval.Daily]: 'Daily',
  [TriggerInterval.EveryWeekday]: 'Every weekday',
  [TriggerInterval.Weekly]: `Weekly on ${dayjs().format('dddd')}`,
  [TriggerInterval.Monthly]: `Monthly on the ${formatWithSuffix(dayjs().date())}`,
};

export const getRRuleFreq = (triggerInterval: TriggerInterval | undefined) => {
  if (!triggerInterval || triggerInterval === TriggerInterval.DoesNotRepeat) {
    return RRule.DAILY;
  } else if (triggerInterval === TriggerInterval.Daily) {
    return RRule.DAILY;
  } else if (triggerInterval === TriggerInterval.EveryWeekday) {
    return RRule.DAILY;
  } else if (triggerInterval === TriggerInterval.Weekly) {
    return RRule.WEEKLY;
  }
  return RRule.MONTHLY;
};

export const getDateInstanceFromTriggerHour = (triggerHour: string) => {
  const dateInstance = dayjs(
    dayjs(`${dayjs().format('YYYY-MM-DD')} ${triggerHour}`).format(),
  ).utc();
  return dateInstance;
};

export const getDateInstanceFromCustomTriggerDateTime = (
  customTriggerDateTime: string,
) => {
  const dateInstance = dayjs(
    `${customTriggerDateTime}+00:00`,
    'YYYY-MM-DDTHH:mm:ssZ',
  ).utc();
  return dateInstance;
};

export const convertUTCTimeToLocalTimeString = ({
  hours,
  minutes,
  seconds,
}: {
  hours: string;
  minutes: string;
  seconds: string;
}): string => {
  const todayDateString = dayjs().format('YYYY-MM-DD');
  return dayjs(`${todayDateString}T${hours}:${minutes}:${seconds}+00:00`)
    .local()
    .format('HH:mm A');
};

export const computeRRuleOptions = (
  triggerInterval: TriggerInterval,
  triggerHr: string | undefined,
  customTriggerHr: string | undefined,
) => {
  if (!triggerHr && !customTriggerHr) {
    return {};
  }
  const dateInstance = customTriggerHr
    ? getDateInstanceFromCustomTriggerDateTime(customTriggerHr)
    : getDateInstanceFromTriggerHour(triggerHr ?? '');
  if (customTriggerHr) {
    return {
      dtstart: new Date(
        Date.UTC(
          dateInstance.year(),
          dateInstance.month(),
          dateInstance.date(),
          dateInstance.hour(),
          dateInstance.minute(),
          dateInstance.second(),
        ),
      ),
    };
  }
  const options: Record<string, unknown> = {
    byhour: [dateInstance.get('hour')],
    byminute: [dateInstance.get('minute')],
    bysecond: [dateInstance.get('second')],
  };
  const todayDateInstance = dayjs().utc();
  if (triggerInterval === TriggerInterval.EveryWeekday) {
    options.byweekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
  } else if (triggerInterval === TriggerInterval.Weekly) {
    // eslint-disable-next-line
    // @ts-expect-error
    options.byweekday = RRule[todayDateInstance.format('dd').toUpperCase()];
  } else if (triggerInterval === TriggerInterval.Monthly) {
    options.bymonthday = [todayDateInstance.date()];
  }
  return options;
};

const getTimezoneOffsetHoursAndMinutes = () => {
  const offset = dayjs().utcOffset();
  const hours = Math.floor(offset / 60);
  const minutes = offset % 60;
  return [hours, minutes];
};

const hasOffset = (rrule: RRule) => {
  const { byhour, byminute } = rrule.options;
  const [hoursOffset, minutesOffset] = getTimezoneOffsetHoursAndMinutes();
  const totalMinutesOffset = byminute[0] + minutesOffset;
  const extraHour = Math.floor(totalMinutesOffset / 60);
  const totalHoursOffset = byhour[0] + hoursOffset + extraHour;
  return totalHoursOffset > 24 || extraHour > 0;
};

const getFreqStringFromRRule = (rrule: RRule) => {
  const { freq, byweekday, bymonthday } = rrule.options;
  if (freq === RRule.DAILY && isNil(byweekday)) {
    return TriggerInterval.Daily;
  }
  if (freq === RRule.DAILY && byweekday.length > 1) {
    return TriggerInterval.EveryWeekday;
  }
  const offset = hasOffset(rrule) ? 1 : 0;
  if (freq === RRule.WEEKLY && byweekday.length === 1) {
    const day = byweekday[0] + offset;
    return `Weekly on ${days[day]}`;
  }
  const date = bymonthday[0] + offset;
  return `Monthly on the ${formatWithSuffix(date)}`;
};

export const parseRRuleStringToScheduleState = (rruleString: string) => {
  const rrule = RRule.fromString(rruleString);
  const { count, dtstart, byhour, byminute, bysecond } = rrule.options;
  if (count === 1) {
    return {
      triggerInterval: TriggerInterval.DoesNotRepeat,
      triggerHr: '',
      customTriggerHr: dtstart.toISOString(),
    };
  }
  const hours = byhour[0].toString().padStart(2, '0');
  const minutes = byminute[0].toString().padStart(2, '0');
  const seconds = bysecond[0].toString().padStart(2, '0');
  return {
    triggerInterval: getFreqStringFromRRule(rrule) as TriggerInterval,
    triggerHr: convertUTCTimeToLocalTimeString({
      hours,
      minutes,
      seconds,
    }),
    customTriggerHr: undefined,
  };
};

export interface ScheduleState {
  id?: string;
  triggerInterval?: TriggerInterval;
  triggerHr?: string;
  customTriggerHr?: string;
}

export const convertScheduleToRRuleString = (schedule: ScheduleState) => {
  const {
    triggerInterval = TriggerInterval.DoesNotRepeat,
    triggerHr,
    customTriggerHr,
  } = schedule;
  const freq = getRRuleFreq(triggerInterval);
  const count =
    triggerInterval === TriggerInterval.DoesNotRepeat ? 1 : undefined;
  return new RRule({
    freq,
    count,
    ...computeRRuleOptions(triggerInterval, triggerHr, customTriggerHr),
  }).toString();
};

export const getScheduleTitle = (schedule: ScheduleState) => {
  const {
    triggerInterval = TriggerInterval.DoesNotRepeat,
    triggerHr = '',
    customTriggerHr,
  } = schedule;
  if (triggerInterval === TriggerInterval.DoesNotRepeat && customTriggerHr) {
    return `AT ${dayjs(customTriggerHr).local().format('MM/DD/YYYY HH:mm A')}`;
  }
  return `AT ${triggerHr} ${triggerInterval}`;
};

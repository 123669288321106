import { useEffect, useState } from 'react';
import { ExecutionStatusEnum, type ExecutionStatus } from 'types-shared';
import { Select } from 'ui-kit';
import startCase from 'lodash/startCase';

const executionStatusSelectOptions: ExecutionStatusEnum[] = [
  ExecutionStatusEnum.Queued,
  ExecutionStatusEnum.PendingUser,
  ExecutionStatusEnum.PendingSystem,
  ExecutionStatusEnum.Canceled,
  ExecutionStatusEnum.Running,
  ExecutionStatusEnum.Success,
  ExecutionStatusEnum.Failed,
  ExecutionStatusEnum.Terminated,
  ExecutionStatusEnum.Paused,
  ExecutionStatusEnum.Timeout,
  ExecutionStatusEnum.Retry,
];

interface Props {
  id: string;
  status: ExecutionStatusEnum;
  executionUpdateStatus?: 'error' | 'idle' | 'pending' | 'success';
  updateExecutionStatus?: ({
    id,
    status,
  }: {
    id: string;
    status: ExecutionStatus;
  }) => unknown;
}

export function ExecutionStatusDropdown({
  id,
  status,
  executionUpdateStatus = 'idle',
  updateExecutionStatus,
}: Props) {
  const [hasRequestedUpdate, setHasRequestedUpdate] = useState(false);
  useEffect(() => {
    if (executionUpdateStatus === 'idle') setHasRequestedUpdate(false);
  }, [executionUpdateStatus]);

  return (
    <Select<ExecutionStatusEnum>
      disabled={executionUpdateStatus === 'pending' && hasRequestedUpdate}
      getLabel={(option) => startCase(option)}
      getValue={(option) => option}
      onChange={(event) => {
        setHasRequestedUpdate(true);
        updateExecutionStatus?.({
          id,
          status: event.target.value as ExecutionStatusEnum,
        });
      }}
      options={executionStatusSelectOptions}
      value={status}
    />
  );
}

import * as React from 'react';
import { Menu, MenuItem, ContentCopy, Delete, Edit } from 'ui-kit';

import { type WorkflowListMenuProps } from '../types';

// TODO Paul: Memoize this
export default function WorkflowListMenu({
  menuProps,
  editWorkflowEnabled,
  enableWorkflowCloning,
  onCloseMenu,
  onEditWorkflow,
  onDeleteWorkflow,
  onOpenDuplicateModal,
}: {
  menuProps: WorkflowListMenuProps | null;
  editWorkflowEnabled: boolean;
  enableWorkflowCloning: boolean;
  onCloseMenu: () => void;
  onEditWorkflow: () => void;
  onDeleteWorkflow: () => void;
  onOpenDuplicateModal: () => void;
}) {
  return (
    <Menu
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
      anchorEl={menuProps?.anchorEl ?? null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={onCloseMenu}
      open={Boolean(menuProps)}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {editWorkflowEnabled ? (
        <MenuItem className="group min-w-72 h-12" onClick={onEditWorkflow}>
          <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
            Edit Workflow
          </span>
          <Edit className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
        </MenuItem>
      ) : null}
      {enableWorkflowCloning ? (
        <MenuItem
          className="group min-w-72 h-12"
          onClick={onOpenDuplicateModal}
        >
          <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
            Duplicate Workflow
          </span>
          <ContentCopy className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
        </MenuItem>
      ) : null}
      <MenuItem className="group min-w-72 h-12" onClick={onDeleteWorkflow}>
        <span className="font-normal leading-6 group-hover:text-primary-blue">
          Delete Workflow
        </span>
        <Delete className="!4-5 !h-4 !w-4 !ml-auto group-hover:text-primary-blue" />
      </MenuItem>
    </Menu>
  );
}

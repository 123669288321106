import React, { useState } from 'react';
import type {
  DatasourceMetadata,
  TemplateData,
  TemplateVariable,
  VariableMap,
  GlobalVariable,
} from 'types-shared';
import { SourceTypeEnum } from 'types-shared';
import { VariableInput } from '../VariableTypes/VariableInput';
import { clsx } from 'clsx';

import {
  openAddVariableModal,
  openPreviewVariableModal,
} from '../../../../utils/helper';

interface Props {
  label?: string;
  placeholder?: string;
  variable?: TemplateVariable;
  variablesMap: VariableMap;
  onChange?: (variable: TemplateVariable) => void;
  datasourceMetadata: DatasourceMetadata | null;
  sourceType?: SourceTypeEnum;
  disabled?: boolean;
  className?: string;
  isHalf?: boolean;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  multiline?: boolean;
}

export function RequestVariableInput({
  label,
  variable,
  variablesMap,
  onChange,
  globalVariablesMap,
  datasourceMetadata,
  sourceType,
  disabled,
  className,
  isHalf,
  multiline,
  placeholder,
}: Props) {
  const [variableData, setVariableData] = useState<TemplateData>(
    variable?.data ?? [],
  );

  const hasDatasource = Boolean(datasourceMetadata);

  return (
    <VariableInput
      allowAddVariable={
        hasDatasource ||
        sourceType === SourceTypeEnum.API ||
        sourceType === SourceTypeEnum.EmailTrigger
      }
      className={clsx(className, isHalf && 'w-[45%]')}
      value={variableData}
      disabled={disabled}
      label={label}
      onClickAddNew={() => {
        openAddVariableModal();
      }}
      onChange={(newData) => {
        setVariableData(newData);

        if (variable && onChange) {
          onChange({
            ...variable,
            data: newData,
          });
        }
      }}
      onClickVariableChip={(variableId) => {
        openPreviewVariableModal({
          variableId,
        });
      }}
      variablesMap={variablesMap}
      globalVariablesMap={globalVariablesMap}
      multiline={Boolean(multiline)}
      placeholder={placeholder}
    />
  );
}

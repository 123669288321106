"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.completeVideoUploadSchema = exports.addWorkflowSchema = exports.ImageUploadData = void 0;
var types_shared_1 = require("types-shared");
var workflow_schema_1 = require("./workflow.schema");
exports.ImageUploadData = types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.z.object({
    imagePresignedS3Url: types_shared_1.z.string(),
    thumbnailPresignedS3Url: types_shared_1.z.string(),
}));
exports.addWorkflowSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({}).nullable(),
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.z.object({
            workflowName: types_shared_1.z.string(),
            imageHashes: types_shared_1.z.array(types_shared_1.z.string()),
            status: workflow_schema_1.WorkflowStatus.optional(),
            videoUploadParts: types_shared_1.z.number().optional(),
        }),
    }),
    response: types_shared_1.z.object({
        workflowId: types_shared_1.z.string(),
        imageUploadMap: exports.ImageUploadData,
        stateUploadUrl: types_shared_1.z.string(),
        variableStoreUploadUrl: types_shared_1.z.string(),
        targetStoreUploadUrl: types_shared_1.z.string(),
        videoUpload: types_shared_1.z.object({
            uploadId: types_shared_1.z.string(),
            urls: types_shared_1.z.array(types_shared_1.z.string()),
        }),
    }),
};
exports.completeVideoUploadSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
        query: types_shared_1.z.object({
            videoReqId: types_shared_1.z.string(),
        }),
        body: types_shared_1.z.object({
            uploadId: types_shared_1.z.string(),
            parts: types_shared_1.z.array(types_shared_1.z.object({
                etag: types_shared_1.z.string(),
                partNumber: types_shared_1.z.number(),
            })),
        }),
    }),
    response: types_shared_1.z.object({
        success: types_shared_1.z.boolean(),
    }),
};

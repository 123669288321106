import { useParams } from 'react-router-dom';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import { useEffect } from 'react';
import { useGetRefData } from '../Editor/hooks';
import { EditorStore } from '../Editor/store/EditorState';
import { Container } from './components/Container';
import { FeatureFlag } from '../../utils/constants';
import { contactModalEventChannel } from '../../utils/contactModal';
import {
  extractDaysHoursMinutes,
  getTotalMinutes,
  useFeatureFlag,
} from '../../utils/helper';

export default function APITriggerSettings() {
  const { nodes, variables } = EditorStore();
  const { workflowId } = useParams();

  if (!workflowId) {
    throw new Error('workflowId not found!');
  }

  const { data: workflowMetadata, isFetching: isFetchingWorkflowMetadata } =
    useFetchWorkflowMetadata(workflowId);

  const showApiPageRetryConfig =
    useFeatureFlag(FeatureFlag.ApiPageRetryConfig) ?? false;

  const { data: nodeViewData, isFetching: isFetchingNodeViewData } =
    useGetRefData(workflowId, false, undefined, 'api-trigger-key');

  const { apiSettings, setMaxAttempts, setRetryInterval, resetApiSettings } =
    EditorStore();

  const openContactModal = () => {
    contactModalEventChannel.emit('open', {
      workflowId,
      workflowName: workflowMetadata?.workflowName,
    });
  };

  useEffect(() => {
    resetApiSettings();

    return () => {
      resetApiSettings();
    };
  }, [resetApiSettings]);

  return (
    <Container
      apiSettings={apiSettings}
      extractDaysHoursMinutes={extractDaysHoursMinutes}
      getTotalMinutes={getTotalMinutes}
      loading={isFetchingWorkflowMetadata || isFetchingNodeViewData}
      nodeViewData={nodeViewData}
      nodes={nodes}
      openContactModal={openContactModal}
      setMaxAttempts={setMaxAttempts}
      setRetryInterval={setRetryInterval}
      showApiPageRetryConfig={showApiPageRetryConfig}
      variablesMap={variables}
      workflowId={workflowId}
      workflowMetadata={workflowMetadata}
    />
  );
}

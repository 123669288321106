import {
  type Variable,
  VariableTypeEnum,
  SourceTypeEnum,
  type GlobalVariable,
  type VariableMap,
  DocumentSourceEnum,
} from 'types-shared';
import { clsx } from 'clsx';
import {
  CalendarIcon,
  DocumentIcon,
  CloseIcon,
  LanguageOutlined,
  StarsIcon,
  Tooltip,
} from 'ui-kit';
import { useMemo } from 'react';
import isNil from 'lodash/isNil';
import { checkIfVariableHasTransformations } from '../pages/Editor/utils/helper';
import {
  executionVariableSubTypeMapping,
  executionVariableTitleMapping,
} from '../pages/Editor/utils/constants';

export function getVariableChipClasses(
  variable: Variable,
  disabled = false,
  bordered = false,
  deletable = false,
) {
  const isApiCallChip =
    variable.type === VariableTypeEnum.Source &&
    variable.data.sourceType === SourceTypeEnum.API;

  const isDocumentScrape =
    variable.type === VariableTypeEnum.Document &&
    variable.data.source === DocumentSourceEnum.Execution;

  const isExecutionVariable = variable.type === VariableTypeEnum.Execution;

  return clsx({
    '!normal-case bg-blue-600 rounded-full px-2 py-1 text-white !text-xs cursor-pointer inline-flex min-w-max':
      true,
    'max-w-[10rem] !truncate': true,
    'gap-1 items-center': true,
    'pl-3 pr-1': deletable,
    'shadow-[0px_0px_0px_3px_rgba(33,150,252,0.5)]': bordered,
    '!bg-secondary-purple':
      isDocumentScrape || variable.type === VariableTypeEnum.Scrape,
    '!bg-white !border-secondary-blue !border-2 !text-secondary-blue':
      isApiCallChip,
    'opacity-75 !cursor-default': disabled,
    '!bg-navy-blue': isExecutionVariable,
  });
}

export function getVariableChipContent(variable: Variable) {
  if (
    variable.type === VariableTypeEnum.Source &&
    variable.data.sourceType === SourceTypeEnum.API
  ) {
    return 'API Trigger';
  } else if (
    variable.type === VariableTypeEnum.Source &&
    variable.data.sourceType === SourceTypeEnum.EmailTrigger
  ) {
    return 'Email Trigger';
  } else if (
    variable.type === VariableTypeEnum.Source &&
    variable.data.sourceType === SourceTypeEnum.Request
  ) {
    return `${(variable.name ?? 'Un-named variable').trim()} Response`;
  } else if (
    variable.type === VariableTypeEnum.Document &&
    variable.data.source === DocumentSourceEnum.Execution
  ) {
    return (variable.name ?? 'Document-1').trim();
  } else if (variable.type === VariableTypeEnum.Execution) {
    return variable.name
      ? executionVariableTitleMapping[variable.name]
      : 'Execution Variable';
  }

  return (variable.name ?? 'Un-named variable').trim();
}

interface Props {
  variableId: string;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  disabled?: boolean;
  className?: string;
  bordered?: boolean;
  onDelete?: () => void;
}

export function VariableChip({
  variableId,
  variablesMap,
  globalVariablesMap,
  onClick,
  disabled,
  className,
  bordered,
  onDelete,
}: Props) {
  const globalVariable = globalVariablesMap[variableId];
  const otherVariable = variablesMap[variableId];

  // eslint-disable-next-line
  const variable = globalVariable ?? otherVariable;

  const hasTransformations = useMemo(() => {
    if (isNil(variable)) return false;
    return checkIfVariableHasTransformations(variable);
  }, [variable]);

  // eslint-disable-next-line
  if (!variable) return null;

  const isGlobalVar = variable.type === VariableTypeEnum.Global;
  const isScrapeVar = variable.type === VariableTypeEnum.Scrape;
  const showCalendarIcon =
    variable.type === VariableTypeEnum.Execution &&
    executionVariableSubTypeMapping[variable.name] === 'Date';

  return (
    <Tooltip
      arrow
      hidden={!hasTransformations}
      placement="right"
      title={
        isScrapeVar
          ? 'This scrape uses GPT transformations'
          : 'This variable uses GPT transformations'
      }
    >
      <span
        className={clsx(
          getVariableChipClasses(
            variable,
            disabled,
            bordered,
            Boolean(onDelete),
          ),
          className,
        )}
        role="presentation"
        onClick={Boolean(disabled) || isGlobalVar ? undefined : onClick}
      >
        {variable.type === VariableTypeEnum.Document ? (
          <DocumentIcon className="!text-lg" />
        ) : null}
        {showCalendarIcon ? <CalendarIcon sx={{ fontSize: 15 }} /> : null}

        {variable.type === VariableTypeEnum.Global ? (
          <LanguageOutlined sx={{ fontSize: 15 }} />
        ) : null}
        {getVariableChipContent(variable)}
        {hasTransformations ? <StarsIcon className="!text-xs !ml-0.5" /> : null}
        {onDelete ? (
          <span
            className="rounded-full hover:bg-gray-200/10 scale-125"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onDelete();
            }}
            role="presentation"
          >
            <CloseIcon className="!text-lg text-white" />
          </span>
        ) : null}
      </span>
    </Tooltip>
  );
}

import type {
  GetExecutionRequest,
  GetExecutionResponse,
  GetZippedExecutionOuputsRequest,
  GetZippedExecutionOuputsResponse,
  ListExecutionsRequest,
  ListExecutionsResponse,
  PopExecutionRequest,
  PopExecutionResponse,
  PutExecutionDataRequest,
  PutExecutionDataResponse,
  QueueExecutionsRequest,
  QueueExecutionsResponse,
  QueueTemporalExecutionRequest,
  QueueTemporalExecutionResponse,
  SignalTemporalExecutionRequest,
  SignalTemporalExecutionResponse,
  UpdateExecutionRequest,
  UpdateExecutionResponse,
  ZodFetcher,
  BatchGetExecutionsResponse,
} from 'api-types-shared';
import {
  axios,
  createZodFetcher,
  getExecutionSchema,
  getZippedExecutionOutputsSchema,
  listExecutionsSchema,
  popExecutionsSchema,
  putExecutionDataSchema,
  queueExecutionsSchema,
  queueTemporalExecutionSchema,
  signalTemporalExecutionSchema,
  updateExecutionSchema,
  UploadTypeEnum,
  zodAxios,
  batchGetExecutionsSchema,
} from 'api-types-shared';
import type { ExecutionBase, ExecutionStatus, UuidSchema } from 'types-shared';
import { apiEndpoints, type NodeEnv } from 'ui-kit';
import type { KyInstance, Options } from 'ky';
import ky from 'ky';
import { getBlobFromS3, uploadBlobToS3, getUrlFromBlob } from '../utils';
import { type ApolloClient } from '@apollo/client';
import { handleException } from 'sentry-browser-shared';
import { GET_EXECUTIONS } from '../utils/schema';

export class ExecutionSDK {
  private _kyFetcher: ZodFetcher<KyInstance>;
  private _apolloClient: ApolloClient<object>;
  readonly endpoint: string;

  constructor(
    env: NodeEnv,
    apolloClient: ApolloClient<object>,
    opts?: Options,
  ) {
    this._kyFetcher = createZodFetcher(opts ? ky.extend(opts) : ky);
    this._apolloClient = apolloClient;
    this.endpoint = apiEndpoints[env].executionApiV1;
  }

  _updateExecution = (
    req: UpdateExecutionRequest,
  ): Promise<UpdateExecutionResponse> => {
    return this._kyFetcher(
      updateExecutionSchema.response,
      `${this.endpoint}/${req.params.executionId}`,
      {
        method: 'PUT',
        body: JSON.stringify(req.body),
      },
    );
  };

  updateExecution = ({
    executionId,
    status,
    statusDescr,
    adminRun,
  }: {
    executionId: string;
    status?: ExecutionStatus;
    statusDescr?: string;
    adminRun?: boolean;
  }): Promise<UpdateExecutionResponse> => {
    return this._updateExecution({
      params: { executionId },
      body: {
        ...(statusDescr ? { statusDescr } : {}),
        ...(status ? { status } : {}),
        ...(typeof adminRun === 'boolean' ? { adminRun } : {}),
      },
      query: null,
    });
  };

  _putExecutionData = (
    req: PutExecutionDataRequest,
  ): Promise<PutExecutionDataResponse> => {
    // TODO(michael): Swap to use _kyFetcher with auth
    return zodAxios(
      putExecutionDataSchema.response,
      `${this.endpoint}/${req.params.executionId}/data`,
      {
        method: 'PUT',
        params: { uploadType: req.query.uploadType },
        data: req.body,
      },
    );
  };

  putExecutionData = async ({
    executionId,
    data,
    uploadType,
    name,
  }: {
    executionId: UuidSchema;
    data: unknown;
    uploadType: UploadTypeEnum;
    name?: string;
  }): Promise<{
    executionId?: UuidSchema;
    name?: string;
  }> => {
    const { uploadUrl } = await this._putExecutionData({
      params: { executionId },
      body: { name },
      query: { uploadType },
    });
    if (uploadType === UploadTypeEnum.Image) {
      await uploadBlobToS3(data as Blob, uploadUrl);
    } else if (uploadType === UploadTypeEnum.Variables) {
      await axios(uploadUrl, {
        method: 'PUT',
        data,
      });
    } else if (uploadType === UploadTypeEnum.Artifact) {
      await uploadBlobToS3(data as Blob, uploadUrl);
    }
    return { executionId, name };
  };
  _getExecution = (req: GetExecutionRequest): Promise<GetExecutionResponse> => {
    return this._kyFetcher(
      getExecutionSchema.response,
      `${this.endpoint}/${req.params.executionId}`,
      {
        method: 'get',
      },
    );
  };

  getExecution = async (
    executionId: UuidSchema,
  ): Promise<GetExecutionResponse> => {
    return this._getExecution({
      params: { executionId },
    });
  };

  batchGetExecutions = async (
    executionIds: string[],
  ): Promise<BatchGetExecutionsResponse> => {
    return this._kyFetcher(
      batchGetExecutionsSchema.response,
      `${this.endpoint}/batch`,
      {
        method: 'POST',
        body: JSON.stringify({ ids: executionIds }),
      },
    );
  };

  getExecutionScreenshots = async (
    imageUrls: string[],
  ): Promise<[string, string][]> =>
    (
      await Promise.allSettled(
        imageUrls.map(async (imageUrl: string): Promise<[string, string]> => {
          const fileUrl = imageUrl.split('?')[0]?.split('/').pop() ?? imageUrl;
          const isVideo = imageUrl.includes('.mp4');
          if (isVideo) {
            return [fileUrl, imageUrl];
          }
          const blob = await getBlobFromS3(imageUrl);
          const blobUrl = getUrlFromBlob(blob);
          return [fileUrl, blobUrl];
        }),
      )
    )
      .filter((result) => result.status === 'fulfilled')
      .map((result) => result.value);

  listExecutions = async (
    req: ListExecutionsRequest,
    filterAdminRun = true,
  ): Promise<ListExecutionsResponse> => {
    let url = `${this.endpoint}/list?workflowId=${req.query.workflowId}`;
    if (req.query.setId) {
      url += `&setId=${req.query.setId}`;
    }

    const resp = await this._kyFetcher(listExecutionsSchema.response, url, {
      method: 'get',
    });

    return resp.filter((exec) => !filterAdminRun || !exec.adminRun);
  };

  queueExecutions = (
    req: QueueExecutionsRequest,
  ): Promise<QueueExecutionsResponse> => {
    return this._kyFetcher(
      queueExecutionsSchema.response,
      `${this.endpoint}/queue-remote/${req.params.workflowId}`,
      {
        method: 'PUT',
        body: JSON.stringify(req.body),
      },
    );
  };

  queueTemporalExecutions = (
    req: QueueTemporalExecutionRequest,
  ): Promise<QueueTemporalExecutionResponse> => {
    return this._kyFetcher(
      queueTemporalExecutionSchema.response,
      `${this.endpoint}/queue-remote/${req.params.workflowId}`,
      {
        method: 'PUT',
        body: JSON.stringify(req.body),
      },
    );
  };

  queueDesktopExecutions = (
    req: QueueTemporalExecutionRequest,
  ): Promise<QueueTemporalExecutionResponse> => {
    return this._kyFetcher(
      queueTemporalExecutionSchema.response,
      `${this.endpoint}/queue-desktop/${req.params.workflowId}`,
      {
        method: 'PUT',
        body: JSON.stringify(req.body),
      },
    );
  };

  popExecutions = (req: PopExecutionRequest): Promise<PopExecutionResponse> => {
    return this._kyFetcher(
      popExecutionsSchema.response,
      `${this.endpoint}/pop`,
      {
        method: 'PUT',
        body: JSON.stringify(req.body),
      },
    );
  };

  getZippedOutputs = async (
    req: GetZippedExecutionOuputsRequest,
  ): Promise<GetZippedExecutionOuputsResponse> => {
    return this._kyFetcher(
      getZippedExecutionOutputsSchema.response,
      `${this.endpoint}/zip`,
      {
        method: 'post',
        json: { executionIds: req.body?.executionIds },
        timeout: 30000,
      },
    );
  };

  sendExecutionSignal = async (
    req: SignalTemporalExecutionRequest,
  ): Promise<SignalTemporalExecutionResponse> => {
    return this._kyFetcher(
      signalTemporalExecutionSchema.response,
      `${this.endpoint}/signal-remote/${req.params.executionId}`,
      {
        method: 'post',
        json: { signalTypeBatch: req.body.signalTypeBatch },
        timeout: 30000,
      },
    );
  };

  fetchExecutionsListBatched = async (
    conditions: Record<string, unknown>,
    page: number,
    pageSize: number,
    orderBy: Record<string, 'asc' | 'desc'>,
  ): Promise<{ executions: ExecutionBase[]; totalCount: number }> => {
    const { workflowId, adminRun, status, executionId } = conditions;
    const variables = {
      where: {
        workflowId: { _eq: workflowId },
        ...(adminRun !== undefined ? { adminRun: { _eq: adminRun } } : {}),
        ...(status ? { status: { _in: status } } : {}),
        ...(executionId !== undefined
          ? { idString: { _like: `%${executionId as string}%` } }
          : {}),
      },
      offset: (page - 1) * pageSize,
      limit: pageSize,
      orderBy,
    };
    const executionsData = await this._apolloClient.query<{
      executions: { id: string }[];
      executions_aggregate: { aggregate: { count: number } };
    }>({
      query: GET_EXECUTIONS,
      variables,
    });
    const executionIds = executionsData.data.executions.map(
      (execution: { id: string }) => execution.id,
    );
    const totalCount = executionsData.data.executions_aggregate.aggregate.count;

    const results = await this._kyFetcher(
      batchGetExecutionsSchema.response,
      `${this.endpoint}/batch`,
      {
        method: 'post',
        body: JSON.stringify({ ids: executionIds }),
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Execution batch fetch failed',
        source: 'executionSDK.fetchExecutionsListBatched',
        extra: { conditions, page, pageSize, orderBy },
      });
      return { executions: {} };
    });
    const executionsList = Object.values(results.executions);
    return { executions: executionsList, totalCount };
  };

  runRemote = (
    workflowId: string,
    recordIds: string[],
    adminRun = false,
  ): Promise<QueueExecutionsResponse> => {
    return this.queueExecutions({
      params: {
        workflowId,
      },
      body: {
        setRecordKeys: recordIds,
        bypassSQS: false,
        adminRun,
      },
    });
  };
}

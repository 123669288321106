import { ExecutionStatusEnum } from 'types-shared/executionTypes';
import dayjs, { extend } from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { dateFormat } from './constants';

extend(utcPlugin);

export const getUtcDate = (date: string): string =>
  dayjs(date).utc().format(dateFormat);

export const getUtcTime = (time: string): string => {
  const timezone = dayjs().format('Z');
  const date = dayjs().format('YYYY-MM-DD');
  return dayjs(`${date}T${time}${timezone}`).utc().format('HH:mm:ssZ');
};

export const runningStatuses: string[] = [
  ExecutionStatusEnum.PendingUser,
  ExecutionStatusEnum.PendingSystem,
  ExecutionStatusEnum.Running,
  ExecutionStatusEnum.Paused,
  ExecutionStatusEnum.Queued,
  ExecutionStatusEnum.Retry,
];

export const failedStatuses: string[] = [
  ExecutionStatusEnum.Terminated,
  ExecutionStatusEnum.Failed,
  ExecutionStatusEnum.Canceled,
  ExecutionStatusEnum.Timeout,
];

export interface AggregateResult {
  aggregate: {
    count: number;
  };
}

export interface ExecutionResult {
  value: string;
  executions_aggregate: AggregateResult;
}

export const convertExecutionsDataToMap = (
  executionsData: ExecutionResult[],
): Record<string, number> => {
  const map: Record<string, number> = {
    [ExecutionStatusEnum.Success]: 0,
    [ExecutionStatusEnum.Failed]: 0,
    [ExecutionStatusEnum.Running]: 0,
  };
  executionsData.forEach(
    ({ value, executions_aggregate: executionsAggregate }) => {
      const count = Number(executionsAggregate.aggregate.count);
      if (runningStatuses.includes(value)) {
        map[ExecutionStatusEnum.Running] += count;
      }
      if (failedStatuses.includes(value)) {
        map[ExecutionStatusEnum.Failed] += count;
      }
      if (value === ExecutionStatusEnum.Success.toString()) {
        map[ExecutionStatusEnum.Success] += count;
      }
    },
  );
  return map;
};

import {
  type TypedDocumentNode,
  useQuery,
  type ApolloError,
  useMutation,
} from '@apollo/client';
import {
  type Create_ScheduleMutation as CreateScheduleMutation,
  type Create_ScheduleMutationVariables as CreateScheduleMutationVariables,
  type Delete_ScheduleMutation as DeleteScheduleMutation,
  type Delete_ScheduleMutationVariables as DeleteScheduleMutationVariables,
  type Edit_ScheduleMutation as EditScheduleMutation,
  type Edit_ScheduleMutationVariables as EditScheduleMutationVariables,
  type GetWorkflowSchedulesQuery,
  type GetWorkflowSchedulesQueryVariables,
  graphql,
} from 'hasura-gql';

const GET_SCHEDULES = graphql(`
  query GetWorkflowSchedules($workflowId: uuid!) {
    workflow_schedules(
      order_by: { createdAt: desc }
      where: { workflowId: { _eq: $workflowId } }
    ) {
      id
      rrule
      createdAt
    }
  }
`) as TypedDocumentNode<
  GetWorkflowSchedulesQuery,
  GetWorkflowSchedulesQueryVariables
>;

export const useGetSchedules = (
  workflowId = '',
): {
  data?: GetWorkflowSchedulesQuery['workflow_schedules'];
  refetch: () => Promise<unknown>;
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error, refetch } = useQuery(GET_SCHEDULES, {
    variables: { workflowId },
    skip: !workflowId,
  });

  return { data: data?.workflow_schedules ?? [], loading, error, refetch };
};

const CREATE_SCHEDULE = graphql(`
  mutation CREATE_SCHEDULE($workflowId: uuid!, $rrule: String!) {
    insert_workflow_schedules_one(
      object: { workflowId: $workflowId, rrule: $rrule }
    ) {
      id
    }
  }
`) as TypedDocumentNode<
  CreateScheduleMutation,
  CreateScheduleMutationVariables
>;

export const useCreateSchedule = () => {
  const [mutate] = useMutation(CREATE_SCHEDULE);

  return mutate;
};

const EDIT_SCHEDULE = graphql(`
  mutation EDIT_SCHEDULE($id: uuid!, $rrule: String!) {
    update_workflow_schedules_by_pk(
      pk_columns: { id: $id }
      _set: { rrule: $rrule }
    ) {
      id
    }
  }
`) as TypedDocumentNode<EditScheduleMutation, EditScheduleMutationVariables>;

export const useEditSchedule = () => {
  const [mutate] = useMutation(EDIT_SCHEDULE);

  return mutate;
};

const DELETE_SCHEDULE = graphql(`
  mutation DELETE_SCHEDULE($id: uuid!) {
    delete_workflow_schedules_by_pk(id: $id) {
      id
    }
  }
`) as TypedDocumentNode<
  DeleteScheduleMutation,
  DeleteScheduleMutationVariables
>;

export const useDeleteSchedule = () => {
  const [mutate] = useMutation(DELETE_SCHEDULE);

  return mutate;
};

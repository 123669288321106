import {
  AccessTimeIcon,
  APITriggerIcon,
  ArrowLeftIcon,
  CloudDownload,
  CloudUpload,
  DatabaseIcon,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  Logo,
  TriggerBlockIcon,
  AlertVariant,
  Autocomplete,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  notify,
  Select,
  Tooltip,
  Typography,
} from 'ui-kit';
import { useCallback, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

import { SourceTypeEnum, type VariableMap } from 'types-shared';
import { AdminVersionEnum, type WorkflowStatusType } from 'api-types-shared';
import { useAPI } from 'dashboard-shared';
import { EditorStore } from '../../store/EditorState';
import { downloadLinkData, sleep } from '../../../../utils/helper';
import { extractGlobalVariablesFromTemplates } from '../../utils/helper';
import ManualRun from '../../../ManualRun';
import { useSourceVariable } from '../../hooks/useSourceVariable';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';
import { ConfirmRunAutomation } from '../ConfirmRunAutomation';
import { useVersionHistory } from '../../hooks/useVersionHistory';
import { useGetWorkflowVideos, useUpdateWorkflowData } from '../../hooks';

// Utility function to convert camel case to title case
const camelToTitleCase = (str: string): string => {
  return startCase(camelCase(str));
};

const versionPushOptionMapping: Record<string, string> = {
  [AdminVersionEnum.ForcePush]: 'Error Push',
};

interface Props {
  workflowId: string;
  workflowName?: string;
  workflowStatus?: WorkflowStatusType;
  selectNode: (nodeId: string | null) => void;
  nodeIds: string[];
  onImport: (replaceNodeId?: string) => Promise<boolean>;
  setSourceType: (sourceType: SourceTypeEnum) => void;
}

export default function AdminToolbar({
  workflowId,
  workflowName,
  nodeIds,
  selectNode,
  onImport,
  setSourceType,
  workflowStatus,
}: Props): JSX.Element {
  const navigate = useNavigate();

  const {
    datasourceMetadata,
    tableData,
    nodes,
    edges,
    variables,
    targets,
    globalVariables,
  } = EditorStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmittedModalOpen, setIsSubmittedModalOpen] = useState(false);
  const [showManualRunPopup, setShowManualRunPopup] = useState(false);

  const [recordIds, setRecordIds] = useState<number[]>([0]);
  const { mutateAsync, status } = useUpdateWorkflowData();
  const { isFetching: loadingVideos, refetch: getVideos } =
    useGetWorkflowVideos(workflowId);

  const { setEditingNodeId } = useEditingNodeId();
  const { isApi, isEmailTrigger, isDataSource } = useSourceVariable(variables);

  const menuItems = [
    {
      label: 'API CALL',
      value: SourceTypeEnum.API,
      isCurrent: isApi,
      icon: (
        <APITriggerIcon
          className="!text-info-dark group-hover:!text-info"
          fontSize="small"
        />
      ),
    },
    {
      label: 'DATABASE',
      value: SourceTypeEnum.Datasource,
      isCurrent: isDataSource,
      icon: (
        <DatabaseIcon
          className="!text-info-dark group-hover:!text-info"
          fontSize="small"
        />
      ),
      disabled: true,
    },
    {
      label: 'EMAIL TRIGGER',
      value: SourceTypeEnum.EmailTrigger,
      isCurrent: isEmailTrigger,
      icon: (
        <TriggerBlockIcon
          className="!text-info-dark group-hover:!text-info"
          fontSize="small"
        />
      ),
    },
  ];

  const currentItem = menuItems.find((item) => item.isCurrent);

  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { toggleVersionHistory } = useVersionHistory();

  const [versionPush, setVersionPush] = useState<AdminVersionEnum>(
    AdminVersionEnum.ProcessingPush,
  );

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const goToWorkflowDetail = () => {
    setIsSubmittedModalOpen(false);
    navigate(`/workflows/${workflowId}`);
  };

  const downloadWorkflow = () => {
    localStorage.removeItem(workflowId);
    window.location.reload();
  };

  const saveWorkflow = async () => {
    const workflowData = {
      edges,
      nodes,
      bulkSelectMode: false,
      selectedAction: null,
      selectedNode: null,
    };

    const withGlobalVars = extractGlobalVariablesFromTemplates(
      variables,
      globalVariables as VariableMap,
      nodes,
    );

    await mutateAsync({
      workflowId,
      workflowData,
      variableData: withGlobalVars,
      targetData: targets,
      datasourceMetadata,
      versionPush:
        versionPush === AdminVersionEnum.ProcessingPush
          ? undefined
          : versionPush,
      status: workflowStatus,
    });
  };

  const { executionSDK } = useAPI();

  const confirmRunHandler = useCallback(async () => {
    try {
      await executionSDK.runRemote(
        workflowId,
        recordIds.map((id) => id.toString()),
        true,
      );
      setIsModalOpen(false);
      setIsSubmittedModalOpen(true);
    } catch (e) {
      notify({
        message:
          e instanceof Error
            ? e.message
            : 'Failed to execute the workflow. Please try again.',
        variant: AlertVariant.ERROR,
      });
    }
  }, [executionSDK, workflowId, recordIds]);

  const fetchVideo = async () => {
    const { data = [] } = await getVideos();
    if (data.length > 0) {
      for await (const url of data) {
        try {
          downloadLinkData(url, workflowId);
          await sleep(1000);
        } catch (e: unknown) {
          // eslint-disable-next-line no-console
          console.log('video download failed', (e as Error).message);
        }
      }
    }
  };

  return (
    <>
      <header className="py-3 px-5 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Logo className="!w-7 !h-7" />
          <Link
            className="flex !border !border-solid !border-info !rounded-lg !p-1"
            replace
            to={`/workflows/${workflowId}`}
          >
            <ArrowLeftIcon className="text-info" />
          </Link>
          <div className="flex flex-col !ml-2 text-xs font-medium">
            <span className="text-gray-500">{workflowName ?? 'Workflow'}</span>
            <span>{workflowId}</span>
          </div>
          <Button
            className="group !mr-2 !px-2 !text-nowrap !font-medium !leading-6 !text-sm !text-info-dark hover:!text-info !min-w-[140px]"
            color="secondary"
            endIcon={
              anchorEl ? (
                <ExpandLessOutlined
                  className="!text-info-dark group-hover:!text-info"
                  fontSize="small"
                />
              ) : (
                <ExpandMoreOutlined
                  className="!text-info-dark group-hover:!text-info"
                  fontSize="small"
                />
              )
            }
            onClick={() => {
              setAnchorEl(triggerRef.current);
            }}
            ref={triggerRef}
            startIcon={currentItem?.icon}
            variant="text"
          >
            {currentItem?.label}
          </Button>
          <Menu
            BackdropProps={{
              style: {
                backgroundColor: 'transparent',
              },
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={onMenuClose}
            open={Boolean(anchorEl)}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '4px',
              },
              '& .MuiMenu-list': {
                padding: '0',
              },
              '& .MuiMenu-paper': {
                minWidth: '140px',
              },
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {menuItems
              .filter((i) => !i.isCurrent)
              .map((item) => (
                <MenuItem
                  className="!font-medium flex-row items-center"
                  key={item.value}
                  disabled={item.disabled}
                  onClick={() => {
                    setEditingNodeId(undefined);
                    setSourceType(item.value);
                    onMenuClose();
                  }}
                >
                  {item.icon}
                  <span className="font-medium text-sm text-info-dark ml-2 mr-4">
                    {item.label}
                  </span>
                </MenuItem>
              ))}
          </Menu>
        </div>
        <div className="flex items-center space-x-2">
          <Autocomplete
            className="ml-4 mr-1 min-w-40"
            floatingLabel
            onChange={(newValue: string | null) => {
              selectNode(newValue);
            }}
            options={nodeIds}
            placeholder="Search for a node"
          />
          <Select
            className="w-32"
            getLabel={(option) =>
              versionPushOptionMapping[option] ?? camelToTitleCase(option)
            }
            getValue={(option) => option}
            onChange={(e) => {
              const value = e.target.value as AdminVersionEnum;
              setVersionPush(value);
            }}
            options={[
              AdminVersionEnum.SilentPush,
              AdminVersionEnum.NotifyPush,
              AdminVersionEnum.ForcePush,
              AdminVersionEnum.ProcessingPush,
            ]}
            value={versionPush}
          />
          <Tooltip title="Version History">
            <IconButton
              onClick={() => {
                toggleVersionHistory(true);
              }}
            >
              <AccessTimeIcon className="!text-info" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Upload Changes">
            <IconButton disabled={status === 'pending'} onClick={saveWorkflow}>
              <CloudUpload className="!text-info" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Changes">
            <IconButton className="!mr-2" onClick={downloadWorkflow}>
              <CloudDownload className="!text-green-600" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Button
            color="secondary"
            onClick={() => {
              void onImport();
            }}
            variant="outlined"
          >
            Import
          </Button>
          <Button
            className="!uppercase"
            color="secondary"
            onClick={() => {
              setShowManualRunPopup(true);
            }}
            variant="contained"
          >
            Execute a test
          </Button>
          <Button
            className="!text-info !border-info"
            disabled={loadingVideos}
            onClick={fetchVideo}
            variant="outlined"
          >
            Video
          </Button>
        </div>
      </header>
      <Modal
        className="w-full max-w-screen-md"
        onClose={() => {
          setIsModalOpen(false);
        }}
        open={isModalOpen}
      >
        <ConfirmRunAutomation
          datasourceState={{ datasourceMetadata, tableData }}
          localRunsEnabled
          onCancel={() => {
            setIsModalOpen(false);
          }}
          onChange={setRecordIds}
          onConfirmRun={confirmRunHandler}
          recordIds={recordIds}
        />
      </Modal>
      <Modal
        className="w-full !max-w-2xl"
        onClose={() => {
          setIsSubmittedModalOpen(false);
        }}
        open={isSubmittedModalOpen}
      >
        <img alt="logo" className="w-32" src="/logo-blue.png" />
        <div className="ml-1">
          <Typography className="!font-medium !mt-7" variant="h5">
            Your execution is in progress!
          </Typography>
          <Typography className="!mt-4 !text-info-dark">
            Your workflow is running remotely; the execution(s) can take a few
            minutes. We'll notify you of progress through <b>Slack</b> and in
            the workflow executions page!
          </Typography>
        </div>
        <Button
          className="!text-info !border-info !my-10"
          onClick={() => {
            goToWorkflowDetail();
          }}
          variant="outlined"
        >
          GO TO WORKFLOW DETAILS
        </Button>
      </Modal>
      {showManualRunPopup ? (
        <ManualRun
          onClose={() => {
            setShowManualRunPopup(false);
          }}
          workflowId={workflowId}
        />
      ) : null}
    </>
  );
}

import { type SvgIconProps } from '@mui/material/SvgIcon';
import isArray from 'lodash/isArray';
import { handleException } from 'sentry-browser-shared';
import { ActionsEnum } from 'types-shared/actionTypes';
import { type ExecutionVariables } from 'types-shared/executionTypes';
import { SourceTypeEnum } from 'types-shared/sourceTypes';
import {
  parseTemplateString,
  parseVariable,
  ParseVariableMode,
  type QueryVariable,
  RequestIntegrationTypeEnum,
  type RequestPayloadType,
  type SourceVariable,
  TemplateData,
  type Variable,
  type VariableBase,
  type VariableMap,
  VariableTypeEnum,
  type WorkflowRequestNode,
  type WorkflowAction,
  type NodeTypesEnum,
} from 'types-shared/workflowTypes';

export const extractTransformedValue = (variable: VariableBase): string => {
  const val =
    variable.executionData?.transformedValue ??
    variable.executionData?.initialValue ??
    {};

  if (typeof val === 'string') {
    return val;
  }

  if ('data' in val) {
    if (isArray(val.data)) {
      return val.data.join(', ');
    }
    return val.data as string;
  }

  const stringified = JSON.stringify(val);

  if (stringified === '{}') {
    return '';
  }

  return stringified;
};

export const extractToEmail = (variables: ExecutionVariables): string => {
  const outputVariables = extractOutputsData(variables);
  const toVariable = outputVariables.find(({ title }) => title === 'To');

  if (!toVariable) return '';

  return toVariable.description;
};

export const extractOutputsData = (
  variables: ExecutionVariables,
): { id: string; title: string; description: string }[] => {
  return Object.entries(variables)
    .map(([key, value]) => {
      const isStringValue = typeof value === 'string';
      const executionVariable = value as VariableBase;
      const title = isStringValue ? key : executionVariable.name;

      const description = isStringValue
        ? value
        : extractTransformedValue(executionVariable);

      return { id: executionVariable.id, title: title ?? '', description };
    })
    .filter(({ id, title, description }) => id && title && description);
};

export const extractSourceOutputsData = (
  variablesMap: ExecutionVariables,
): {
  id: string;
  title: string;
  description: string;
  scrapeText?: string;
}[] => {
  const variables = Object.entries(variablesMap);
  return variables
    .map(([key, value]) => {
      const isStringValue = typeof value === 'string';
      const executionVariable = value as VariableBase;
      const title = isStringValue ? key : executionVariable.name;

      const description = isStringValue
        ? value
        : extractTransformedValue(executionVariable);

      const output = {
        id: executionVariable.id,
        title: title ?? '',
        description,
        scrapeText: '',
      };

      const queryVariables = variables.filter(([, variable]) => {
        if ((variable as Variable).type !== VariableTypeEnum.Query) {
          return;
        }
        const { data } = variable as QueryVariable;
        // TODO: add better handling for attachment variables
        return data.sourceIds[0] === executionVariable.id;
      });

      if (queryVariables.length > 0) {
        const scrapedStrings = queryVariables
          .map((item) => {
            const variable = item[1] as QueryVariable;
            const scrapeText = extractTransformedValue(variable);
            return scrapeText
              ? `${variable.name ? `${variable.name}: ` : ''}${scrapeText}`
              : '';
          })
          .filter((v) => v);
        return {
          ...output,
          scrapeText:
            scrapedStrings.length > 0 ? scrapedStrings.join('<br />') : '',
        };
      }

      return output;
    })
    .filter(
      ({ id, title, description, scrapeText }) =>
        id && title && (description || scrapeText),
    );
};

export function extractFilename(path: string): string {
  const parts = path.split('/');
  return parts[parts.length - 1];
}

export const getRequestNodeBody = (
  body: RequestPayloadType[] | { variableId: string },
  executionVariables: VariableMap,
): string => {
  let str;
  if (
    typeof body === 'object' &&
    !Array.isArray(body) &&
    TemplateData.safeParse(executionVariables[body.variableId].data).success
  ) {
    str = parseTemplateString({
      data: executionVariables[body.variableId].data as TemplateData,
      variableMap: executionVariables,
      mode: ParseVariableMode.Execution,
      handleException,
    }) as string;
  } else {
    str = (body as RequestPayloadType[])
      .map(({ key, value }: RequestPayloadType) => {
        const keyStr = parseVariable({
          variable: executionVariables[key.variableId],
          variableMap: executionVariables,
          mode: ParseVariableMode.Execution,
          handleException,
        }) as string;
        const valueStr = parseVariable({
          variable: executionVariables[value.variableId],
          variableMap: executionVariables,
          mode: ParseVariableMode.Execution,
          handleException,
        }) as string;
        return `Key: ${keyStr}<br />Value: ${valueStr}`;
      })
      .join(`<br /><br />`);
  }

  return `<span class="text-cyan-900 text-sm font-medium">Content</span><br />${str}`;
};

export const getRequestNodeTitle = (node: WorkflowRequestNode) => {
  return node.data.integrationType === RequestIntegrationTypeEnum.Salesforce
    ? 'Salesforce Integration'
    : 'HTTP Request';
};

export const getTriggerBlockShortTitle = (variable: SourceVariable) => {
  const isEmail = variable.data.sourceType === SourceTypeEnum.EmailTrigger;

  return isEmail ? 'Email trigger' : undefined;
};

export const getActionTitle = ({
  options,
  actionType,
  title = '',
}: WorkflowAction): string => {
  const { download, captcha, mfa, reCaptcha, terminal } = options ?? {};
  if (download ?? actionType === ActionsEnum.Download) {
    return 'Download';
  }

  if (actionType === ActionsEnum.MultiChoice) {
    return 'Multiple Choice';
  }

  if (actionType === ActionsEnum.KeyPress) {
    return 'Press';
  }

  if (actionType === ActionsEnum.ManualApproval) {
    return 'Manual approval step';
  }

  if (actionType === ActionsEnum.PickFromList) {
    return 'Pick from list';
  }

  if (actionType === ActionsEnum.Arbitrary) {
    return title;
  }

  if (actionType === ActionsEnum.SwitchTab) {
    return 'Switch Tab';
  }

  if (actionType === ActionsEnum.NewTab) {
    return 'New Tab';
  }

  if (actionType === ActionsEnum.MultiSelect) {
    return 'Multi Select';
  }

  if (actionType === ActionsEnum.MagicLoop) {
    return `${terminal ? 'End' : 'Begin'} Magic Loop`;
  }

  if (mfa) {
    return 'Two-factor authentication';
  }

  if (reCaptcha) {
    return 'ReCaptcha';
  }

  if (captcha) {
    return 'Captcha';
  }

  return actionType;
};

export const getMetadataFromImageUrl = (
  url: string,
): {
  timestamp: string;
  retryCount: string;
  nodeId?: string;
  stepId?: string;
} => {
  const [rawTimestamp, retryCount, nodeId, stepId] = url.split('_');
  const timestamp = decodeURIComponent(rawTimestamp);

  // The nodeId doesn't exist (or make sense to include) if we submit a manual screenshot signal, so we handle that case here
  return {
    timestamp,
    retryCount,
    nodeId: nodeId ? nodeId.replace('.png', '') : undefined,
    stepId: stepId ? stepId.replace('.png', '') : undefined,
  };
};

export interface ScreenshotUrl {
  type: string;
  nodeType: NodeTypesEnum;
  src: string | JSX.Element | ((props: SvgIconProps) => JSX.Element);
  sortData: {
    timestamp?: string;
    nodeId?: string;
    stepId?: string;
    name?: string;
  };
}

import { useMemo, useState, useEffect } from 'react';
import { WorkflowImageNode } from 'types-shared';

import { EditorStore } from '../../store/EditorState';
import type { SelectedAction } from './ActionsList';
import ActionsList from './ActionsList';
import EditAction from './EditAction';
import EditTarget from './EditTarget';
import MoveAction from './MoveAction';
import { useSearchParams } from 'react-router-dom';
import { useOnEscape } from '../../../../hooks/useOnEscape';

interface Props {
  setHasSelectedAction: (status: boolean) => void;
}

export default function ActionsManager({ setHasSelectedAction }: Props) {
  const editorData = EditorStore();
  const [, setSearchParams] = useSearchParams();
  const { selectedNode, setSelectedNode, nodes } = editorData;
  const [selectedAction, setSelectedAction] = useState<SelectedAction | null>(
    null,
  );
  const [selectedTarget, setSelectedTarget] = useState<SelectedAction | null>(
    null,
  );
  const [actionToMove, setActionToMove] = useState<SelectedAction | null>(null);

  useOnEscape((e) => {
    e.stopPropagation();
    if (selectedAction) {
      setSelectedAction(null);
      return;
    }

    if (selectedNode) {
      setSearchParams({});
      setSelectedNode(null);
    }
  });

  useEffect(() => {
    if (selectedAction) {
      setHasSelectedAction(true);
    } else {
      setHasSelectedAction(false);
    }
  }, [selectedAction, setHasSelectedAction]);

  const node = useMemo(() => {
    const _selectedNode = nodes.find((_node) => _node.id === selectedNode);
    const parsedImageNode = WorkflowImageNode.safeParse(_selectedNode);
    if (parsedImageNode.success) {
      return parsedImageNode.data;
    }
    return null;
  }, [nodes, selectedNode]);

  const onClose = () => {
    setSearchParams({});
    setSelectedNode(null);
    setSelectedAction(null);
    setSelectedTarget(null);
    setActionToMove(null);
  };

  const onCloseEditTarget = () => {
    setSelectedTarget(null);
  };

  const onCloseMoveAction = () => {
    setActionToMove(null);
  };

  const onEditTarget = (action: SelectedAction) => {
    setSelectedTarget(action);
  };

  const onMoveAction = (action: SelectedAction) => {
    setActionToMove(action);
  };

  if (!node) {
    return null;
  }

  return (
    <div className="min-w-125 max-w-125 max-h-screen overflow-y-auto p-8 flex flex-col space-y-6 h-full bg-white relative">
      {!selectedTarget && !actionToMove && !selectedAction ? (
        <ActionsList
          node={node}
          onClose={onClose}
          onEditTarget={onEditTarget}
          onMoveAction={onMoveAction}
          setSelectedAction={setSelectedAction}
        />
      ) : null}
      {selectedAction ? (
        <EditAction
          action={selectedAction}
          i={selectedAction.i}
          setSelectedAction={setSelectedAction}
        />
      ) : null}

      {selectedTarget ? (
        <EditTarget action={selectedTarget} onClose={onCloseEditTarget} />
      ) : null}
      {actionToMove ? (
        <MoveAction
          action={actionToMove}
          onClose={onCloseMoveAction}
          sourceNode={node}
        />
      ) : null}
    </div>
  );
}

import { Button, Select, Input, Modal, WarningAmberOutlined } from 'ui-kit';

import { useEffect, useState, useMemo, useCallback } from 'react';
import {
  type FormValues,
  type VariableModalOpenPayload,
  type TransformationFormValues,
  variableModalEventChannel,
  defaultFormValue,
  VariableEventsEnum,
  defaultTransformationValues,
  defaultModalInput,
} from '../../utils/variableModal';
import { Transformations } from '../../pages/Editor/components/Transformations';
import { useTransformApiReq } from '../../pages/Editor/hooks';
import {
  EditorStore,
  type EditorStoreProps,
} from '../../pages/Editor/store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import {
  DocumentSourceEnum,
  type DocumentVariable,
  type GlobalVariable,
  QueryValueType,
  QueryValueTypeEnum,
  QueryVariable,
  type ScrapeVariable,
  SourceTypeEnum,
  SourceVariable,
  type TemplateData,
  type Variable,
  VariableBase,
  VariableString,
  VariableTypeEnum,
} from 'types-shared';
import { isAdmin } from '../../utils/env';
import sortBy from 'lodash/sortBy';
import { getVariableChipContent } from '../VariableChip';
import { clsx } from 'clsx';
import values from 'lodash/values';
import { v4 as uuid } from 'uuid';
import DocumentVariableModalContent from './DocumentVariableModalContent';
import { OtherVariablesModalContent } from './OtherVariablesModalContent';
import { SameVariableNameError } from '../../pages/Editor/components/SameVariableNameError';
import { VariableInput } from '../../pages/Editor/components/VariableTypes/VariableInput';

const submitTitles = {
  [VariableEventsEnum.EDIT_VARIABLE]: 'Save changes',
  [VariableEventsEnum.VARIABLE_PREVIEW]: 'Edit variable',
  [VariableEventsEnum.NEW_VARIABLE]: 'Add variable',
};

export default function VariableModal() {
  const {
    updateVariable,
    addVariable,
    variables: variablesMap,
    globalVariables: globalVariablesMap,
    tableData,
    datasourceMetadata,
  } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      variables: state.variables,
      globalVariables: state.globalVariables,
      updateVariable: state.updateVariable,
      addVariable: state.addVariable,
      tableData: state.tableData,
      datasourceMetadata: state.datasourceMetadata,
    })),
  );

  const [open, setOpen] = useState<boolean>(false);
  const [transformationsEnabled, setTransformationsEnabled] = useState(false);
  const [userRequestedEnabled, setUserRequestedEnabled] = useState(false);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(defaultFormValue);
  const [modalInput, setModalInput] = useState<VariableModalOpenPayload>(
    {} as VariableModalOpenPayload,
  );
  const [transformationFormValues, setTransformationFormValues] =
    useState<TransformationFormValues>(defaultTransformationValues);

  const { mutateAsync: transformApiReq, status: transformApiReqStatus } =
    useTransformApiReq(variablesMap);

  const { variableId, modalAction, isCondition, updateBranchData } = modalInput;

  const onClose = () => {
    setHasMadeChanges(false);
    setFormValues(defaultFormValue);
    setTransformationFormValues(defaultTransformationValues);
    setModalInput(defaultModalInput);
    setTransformationsEnabled(false);
    setUserRequestedEnabled(false);
    setOpen(false);
  };

  const setFormValue = (key: string, value: string | boolean) => {
    setFormValues((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const onTransformApiReq = useCallback(
    async (prompt: TemplateData, textToTransform: string) => {
      if (prompt.length > 0 && textToTransform) {
        const value = await transformApiReq({
          data: textToTransform,
          prompt,
        });
        return value?.processedData;
      }
      return undefined;
    },
    [transformApiReq],
  );

  const handletransformApiReq = useCallback(
    async (prompt: TemplateData) => {
      return await onTransformApiReq(
        prompt,
        transformationFormValues.initialValue ?? '',
      );
    },
    [transformationFormValues.initialValue, onTransformApiReq],
  );

  const changeModalAction = (newModalAction: VariableEventsEnum) => {
    setModalInput({
      ...modalInput,
      modalAction: newModalAction,
    });
  };

  const onSubmit = () => {
    if (modalAction === VariableEventsEnum.EDIT_VARIABLE && variable) {
      saveOrUpdateVariable();
      onClose();
    }
    if (modalAction === VariableEventsEnum.VARIABLE_PREVIEW && variable) {
      changeModalAction(VariableEventsEnum.EDIT_VARIABLE);
    }
    if (modalAction === VariableEventsEnum.NEW_VARIABLE) {
      saveOrUpdateVariable();
    }
  };

  const { sourceVariable, showKey } = useMemo(() => {
    const _sourceVariable = Object.values(variablesMap).find(
      (v) => v.id === transformationFormValues.sourceId,
    );

    const _showKey =
      !(
        SourceVariable.safeParse(_sourceVariable).success &&
        SourceVariable.parse(_sourceVariable).data.sourceType ===
          SourceTypeEnum.API
      ) ||
      (QueryVariable.safeParse(_sourceVariable).success &&
        QueryVariable.parse(_sourceVariable).data.valueType ===
          QueryValueTypeEnum.Object);

    return {
      sourceVariable: _sourceVariable,
      showKey: _showKey,
    };
  }, [transformationFormValues.sourceId, variablesMap]);

  useEffect(() => {
    const unsubscribe = variableModalEventChannel.on(
      'open',
      (openPayload: VariableModalOpenPayload) => {
        setOpen(true);
        setModalInput(openPayload);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const processTitle = (title?: string) => {
    const result = title
      ?.toLowerCase()
      .replace(/_/g, ' ')
      .replace(/^\w/, (char) => char.toUpperCase());

    if (isDocumentScrape) {
      if (result === 'Variable preview') {
        return 'Download preview';
      }
      if (result === 'Edit variable') {
        return 'Edit download';
      }
    }

    return result;
  };

  const { variable, isScrape, isSource, isApiCall, isDocumentScrape } =
    useMemo(() => {
      const payload: {
        isScrape: boolean;
        isSource: boolean;
        variable: Variable | undefined;
        isApiCall: boolean;
        isDocumentScrape: boolean;
      } = {
        isScrape: false,
        isSource: false,
        isApiCall: false,
        variable: undefined,
        isDocumentScrape: false,
      };

      if (globalVariablesMap && variableId) {
        const _variable =
          globalVariablesMap[variableId] ?? variablesMap[variableId];

        setFormValues((oldFormValues) => ({
          ...oldFormValues,
          ...(_variable.name ? { variableName: _variable.name } : {}),
          variableType: _variable.type,
        }));

        payload.variable = _variable;
        payload.isScrape = _variable.type === VariableTypeEnum.Scrape;
        payload.isSource = _variable.type === VariableTypeEnum.Source;
        payload.isApiCall =
          _variable.type === VariableTypeEnum.Source &&
          _variable.data.sourceType === SourceTypeEnum.API;
        payload.isDocumentScrape =
          _variable.type === VariableTypeEnum.Document &&
          _variable.data.source === DocumentSourceEnum.Execution;
      }
      return payload;
    }, [variableId, globalVariablesMap, variablesMap]);

  const variableTypeOptions = useMemo(() => {
    const sourceVariables = Object.values(variablesMap).filter(
      (v) => v.type === VariableTypeEnum.Source,
    ) as SourceVariable[];

    const objectQueryVariables = Object.values(variablesMap).filter(
      (v) =>
        v.type === VariableTypeEnum.Query &&
        v.data.valueType === QueryValueTypeEnum.Object &&
        v.id !== variable?.id,
    ) as QueryVariable[];

    const sortedSourceVariables = sortBy(sourceVariables, (obj) => {
      if (obj.data.sourceType === SourceTypeEnum.API) return 1;
      if (obj.data.sourceType === SourceTypeEnum.Datasource) return 2;
      if (obj.data.sourceType === SourceTypeEnum.EmailTrigger) return 3;
      return 10;
    });

    if (!isAdmin) {
      return sortedSourceVariables;
    }

    return [...sortedSourceVariables, ...objectQueryVariables];
  }, [variable?.id, variablesMap]);

  const showNameError = [
    VariableEventsEnum.EDIT_VARIABLE,
    VariableEventsEnum.NEW_VARIABLE,
  ].includes(modalAction);

  const hasError = useMemo(() => {
    const hasVariableWithName = values({
      ...variablesMap,
      ...globalVariablesMap,
    }).some((v) => {
      if (variable && variable.name === formValues.variableName) {
        return false;
      }
      return v.name === formValues.variableName;
    });

    return hasVariableWithName && showNameError;
  }, [
    formValues.variableName,
    showNameError,
    variable,
    variablesMap,
    globalVariablesMap,
  ]);

  const disableAddVariable = useMemo(
    () =>
      !formValues.variableName ||
      (transformationsEnabled &&
        (!transformationFormValues.transformQuery ||
          !transformationFormValues.previewTransform ||
          !transformationFormValues.initialValue)) ||
      (!isScrape &&
        !isSource &&
        !transformationFormValues.valueType &&
        !isDocumentScrape) ||
      (!isScrape &&
        !isSource &&
        variable?.type === VariableTypeEnum.Query &&
        !transformationFormValues.key.length &&
        !isDocumentScrape) ||
      hasError ||
      !hasMadeChanges ||
      (transformationsEnabled && transformApiReqStatus === 'pending'),
    [
      transformationsEnabled,
      transformationFormValues.transformQuery,
      transformationFormValues.previewTransform,
      transformationFormValues.initialValue,
      transformationFormValues.key,
      transformationFormValues.valueType,
      formValues.variableName,
      hasError,
      hasMadeChanges,
      isScrape,
      isSource,
      isDocumentScrape,
      transformApiReqStatus,
      variable?.type,
    ],
  );

  const handleSetEnabled = (newEnabled: boolean) => {
    setUserRequestedEnabled(true); // Prevent overriding of setTransformationsEnabled elsewhere in the file
    setTransformationsEnabled(newEnabled);
    if (variable?.id && modalAction !== VariableEventsEnum.EDIT_VARIABLE) {
      changeModalAction(VariableEventsEnum.EDIT_VARIABLE);
    }
  };

  useEffect(() => {
    if (variable) {
      const { name = '' } = VariableBase.parse(variable);
      const transformedValue = VariableString.parse(
        variable.dashboardData?.transformInputs?.transformedValue ?? '',
      );
      const valueType =
        variable.type === VariableTypeEnum.Query ? variable.data.valueType : '';
      const query = variable.dashboardData?.transformInputs?.query;
      const initialValue = VariableString.parse(
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        variable.dashboardData?.initialValue ||
          (isScrape ? (variable as ScrapeVariable).data.scrapedText : ''),
      );

      const shouldEnableTransformations = Boolean(
        (transformedValue && transformedValue.length > 0) ||
          (query && query.length > 0 && query[0]),
      );

      // Do not override the transformation enabled that has just been set from the handle function.
      if (!userRequestedEnabled) {
        setTransformationsEnabled(shouldEnableTransformations);
      }

      setTransformationFormValues({
        name,
        key:
          variable.type === VariableTypeEnum.Query ? variable.data.query : [],
        previewTransform: transformedValue,
        transformQuery: query,
        initialValue,
        valueType,
        sourceId:
          variable.type === VariableTypeEnum.Query
            ? variable.data.sourceIds[0]
            : variableTypeOptions[0].id,
      });
    } else {
      setTransformationFormValues((f) => ({
        ...f,
        sourceId: variableTypeOptions[0]?.id ?? '',
      }));
    }
  }, [
    userRequestedEnabled,
    variable,
    isScrape,
    isApiCall,
    variablesMap,
    variableTypeOptions,
    transformationsEnabled,
  ]);

  const saveOrUpdateVariable = () => {
    const { key, sourceId, transformQuery, previewTransform, initialValue } =
      transformationFormValues;

    const { variableName: name } = formValues;

    const dashboardData: Variable['dashboardData'] = {
      transformInputs:
        transformationsEnabled &&
        transformQuery &&
        transformQuery.length > 0 &&
        previewTransform
          ? {
              query: transformQuery,
              transformedValue: previewTransform,
            }
          : undefined,
      initialValue: transformationsEnabled ? initialValue ?? '' : '',
    };

    if ((isScrape && variable) ?? (isSource && variable)) {
      updateVariable({
        ...variable,
        dashboardData,
        name: formValues.variableName,
      });
      onClose();
      return;
    }

    if (isDocumentScrape) {
      updateVariable({
        ...variable,
        name: formValues.variableName,
      } as DocumentVariable);
      onClose();
      return;
    }

    const newId = uuid();
    const datasourceQueryVariable: QueryVariable = {
      id: variable ? variable.id : newId,
      name,
      type: VariableTypeEnum.Query,
      dashboardData,
      data: {
        sourceIds: sourceId ? [sourceId] : [variableTypeOptions[0]?.id ?? ''],
        query: key,
        valueType: QueryValueType.safeParse(transformationFormValues.valueType)
          .success
          ? QueryValueType.parse(transformationFormValues.valueType)
          : QueryValueTypeEnum.String,
      },
    };

    if (variable) {
      updateBranchData?.(
        variable,
        modalAction === VariableEventsEnum.NEW_VARIABLE,
      );
      updateVariable(datasourceQueryVariable);
    } else {
      addVariable(datasourceQueryVariable);
    }

    onClose();
  };

  return (
    <Modal
      className="flex item-center justify-items-center max-w-[62rem] py-5 max-h-[90vh] my-5"
      onClose={onClose}
      open={open}
      enableEventBubbling
      borderOnCloseIcon={false}
    >
      <div className="w-[40rem]">
        <div className="flex-1 flex flex-col">
          <p className="font-medium text-lg text-info-dark">
            {processTitle(modalAction)}
          </p>
        </div>

        {!isDocumentScrape &&
        Boolean(variable) &&
        modalAction === VariableEventsEnum.EDIT_VARIABLE ? (
          <div className="bg-warning-light my-6 flex flex-row justify-between gap-4 rounded-lg px-4 py-3.5">
            <WarningAmberOutlined className="text-warning" />
            <div className="flex flex-col">
              <p className="text-warning-dark text-base font-medium">
                Variable changes are global
              </p>
              <p className="text-warning-dark text-sm">
                The changes you make over existing variables will impact all the
                places where the variable is used.
              </p>
            </div>
          </div>
        ) : null}

        {variable &&
        modalAction !== VariableEventsEnum.NEW_VARIABLE &&
        isDocumentScrape ? (
          <DocumentVariableModalContent
            variable={variable}
            onCancel={onClose}
            modalAction={modalInput.modalAction}
            changeModalAction={changeModalAction}
          />
        ) : null}

        {variable &&
        modalAction !== VariableEventsEnum.NEW_VARIABLE &&
        modalAction !== VariableEventsEnum.EDIT_VARIABLE &&
        !isDocumentScrape ? (
          <OtherVariablesModalContent
            variable={variable}
            onCancel={onClose}
            onEdit={() => {
              changeModalAction(VariableEventsEnum.EDIT_VARIABLE);
            }}
            variables={variablesMap}
            globalVariables={globalVariablesMap ?? {}}
            edgeName=""
            label=""
            isCondition={isCondition}
            updateVariable={updateVariable}
            tableData={tableData ?? null}
            datasourceMetadata={datasourceMetadata ?? null}
            variablesMap={variablesMap}
            transformationResult={transformationFormValues.previewTransform}
            transformationKey={showKey ? transformationFormValues.key : []}
            changeModalAction={changeModalAction}
          />
        ) : null}

        {!isDocumentScrape &&
        (modalAction === VariableEventsEnum.EDIT_VARIABLE ||
          modalAction === VariableEventsEnum.NEW_VARIABLE) ? (
          <div className="flex-1">
            <Input
              classes={{ wrapper: 'flex flex-col mt-10' }}
              floatingLabel
              label="Variable name"
              onChange={(variableName: string) => {
                setHasMadeChanges(true);

                const isSourceApi =
                  SourceVariable.safeParse(sourceVariable).success &&
                  SourceVariable.parse(sourceVariable).data.sourceType ===
                    SourceTypeEnum.API;

                setFormValue('variableName', variableName);
                setTransformationFormValues({
                  ...transformationFormValues,
                  ...(isSourceApi ? { key: [variableName] } : {}),
                });
              }}
              placeholder="Name"
              rows={5}
              value={formValues.variableName}
              disabled={
                ![
                  VariableEventsEnum.EDIT_VARIABLE,
                  VariableEventsEnum.NEW_VARIABLE,
                ].includes(modalAction)
              }
            />
            <SameVariableNameError
              name={formValues.variableName}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap ?? {}}
              editingVariableId={variable?.id}
            />

            {!isScrape && !isSource ? (
              <>
                {[
                  VariableEventsEnum.EDIT_VARIABLE,
                  VariableEventsEnum.NEW_VARIABLE,
                ].includes(modalAction) ? (
                  <div className={clsx('mt-3 flex flex-col')}>
                    <Select
                      classes={{ select: '!py-4' }}
                      defaultValue={variableTypeOptions[0]?.id}
                      disabled={variableTypeOptions.length < 2}
                      getLabel={(opt: string) =>
                        getVariableChipContent(
                          variablesMap[opt] ?? globalVariablesMap?.[opt],
                        )
                      }
                      getValue={(opt: string) => opt}
                      label="Variable source"
                      labelId="template-select-variable-source"
                      onChange={(e) => {
                        setTransformationFormValues((form) => ({
                          ...form,
                          sourceId: e.target.value,
                        }));
                        setHasMadeChanges(true);
                      }}
                      options={variableTypeOptions.map((v) => v.id)}
                      value={transformationFormValues.sourceId}
                    />
                  </div>
                ) : null}

                <div className={clsx('my-3 flex flex-col')}>
                  <Select
                    classes={{ select: '!py-4' }}
                    getLabel={(opt: string) => opt}
                    getValue={(opt: string) => opt}
                    label="Variable type"
                    labelId="template-select-variable-source"
                    onChange={(e) => {
                      setTransformationFormValues((form) => {
                        const updatedForm = {
                          ...form,
                          valueType: e.target.value,
                        };
                        // If the user selects QueryVariable type as object, we need to clear the transformation query and preview
                        if (e.target.value === 'Object') {
                          updatedForm.transformQuery = [];
                          updatedForm.previewTransform = '';
                        }
                        return updatedForm;
                      });
                      setHasMadeChanges(true);
                    }}
                    options={
                      isAdmin
                        ? ['String', 'Date', 'Object']
                        : ['String', 'Date']
                    }
                    value={transformationFormValues.valueType}
                    disabled={
                      ![
                        VariableEventsEnum.EDIT_VARIABLE,
                        VariableEventsEnum.NEW_VARIABLE,
                      ].includes(modalAction)
                    }
                  />
                </div>

                {showKey ? (
                  <div className={clsx('mt-4 flex flex-col')}>
                    <VariableInput
                      value={transformationFormValues.key}
                      onChange={(updatedQuery) => {
                        setTransformationFormValues((form) => ({
                          ...form,
                          key: updatedQuery,
                        }));
                        setHasMadeChanges(true);
                      }}
                      label="Key"
                      variablesMap={variablesMap}
                      globalVariablesMap={globalVariablesMap ?? {}}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        ) : null}

        {transformationFormValues.previewTransform &&
        modalAction === VariableEventsEnum.VARIABLE_PREVIEW ? null : (
          <Transformations
            isAddingAVariable={modalAction === VariableEventsEnum.NEW_VARIABLE}
            key={modalAction}
            formValues={transformationFormValues}
            setFormValues={(newformValues) => {
              setTransformationFormValues((oldFormValues) => ({
                ...oldFormValues,
                ...newformValues,
              }));
            }}
            onTransformApiReq={handletransformApiReq}
            variablesMap={variablesMap}
            transformApiReqStatus={transformApiReqStatus}
            setEnabled={handleSetEnabled}
            enabled={transformationsEnabled}
            isDirty={hasMadeChanges}
            setIsDirty={setHasMadeChanges}
            hidden={
              isDocumentScrape ||
              !(!isApiCall && transformationFormValues.valueType !== 'Object')
            }
            globalVariablesMap={
              globalVariablesMap as Record<string, GlobalVariable>
            }
          />
        )}

        {!isDocumentScrape ? (
          <div className="flex flex-row gap-7 mt-10 pb-5">
            <Button
              className="w-40 h-9"
              color="secondary"
              onClick={onSubmit}
              variant="contained"
              disabled={
                [
                  VariableEventsEnum.NEW_VARIABLE,
                  VariableEventsEnum.EDIT_VARIABLE,
                ].includes(modalAction)
                  ? disableAddVariable
                  : false
              }
            >
              {submitTitles[modalAction]}
            </Button>
            <Button
              className="h-9"
              color="secondary"
              onClick={onClose}
              variant="outlined"
            >
              CANCEL
            </Button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}

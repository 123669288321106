import { GetUserClusterDataDocument, ClusterData } from 'hasura-gql';
import { useQuery as useApolloQuery, type ApolloError } from '@apollo/client';
import {
  executionBucket,
  executionsMetadataTableName,
} from '../../../utils/env';
import { useAPI } from '../../../hooks/useApi';
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseMutationResult,
} from '@tanstack/react-query';
import { AlertVariant, notify } from 'ui-kit';
import {
  type SignalTypeEnum,
  type GetExecutionResponse,
} from 'api-types-shared';
import { ddbShim, s3Shim } from '../../../config/aws';
import { type ExecutionBase, type ExecutionStatus } from 'types-shared';
import { TEMPORAL_UI_ADDRESS_MAPPING } from '../../../utils/constants';
import { handleException } from 'sentry-browser-shared';

export { default as useWorkflowCurrentStepActions } from './useWorkflowCurrentStepActions';
export { default as useWorkflowScreenshotUrls } from './useWorkflowScreenshotUrls';

const useGetClusterData = (userId: string) => {
  const { data, loading, error } = useApolloQuery(GetUserClusterDataDocument, {
    variables: { userId },
  });

  let clusterData: ClusterData | null = null;
  clusterData =
    data?.users[0]?.memberships
      ?.map((membership) =>
        ClusterData.parse(membership.team.clusterConfiguration?.cluster),
      )
      .filter(Boolean)[0] ?? null;

  // If there's an Apollo error or no cluster data, and the query isn't still loading, then something went wrong.
  if ((!clusterData || error) && !loading) {
    handleException(new Error(), {
      name: 'Cluster data not found or error.',
      source: 'Execution/useGetClusterData',
      extra: { loading, data, error },
    });
  }

  return { clusterData, loading, error };
};

export const useGetSeleniumAddress = (
  userId: string,
): { data?: string; loading: boolean; error?: ApolloError } => {
  const { clusterData, loading, error } = useGetClusterData(userId);

  return {
    data: clusterData?.seleniumAddress,
    loading,
    error,
  };
};

export function useDownloadExecutionData(): UseMutationResult<
  string,
  Error,
  string[]
> {
  const { executionSDK: sdk } = useAPI();
  return useMutation<string, Error, string[]>({
    mutationFn: (executionIds) =>
      sdk
        .getZippedOutputs({
          body: { executionIds },
        })
        .then((res: { url: string }) => res.url),
    onSuccess: () => {
      notify({
        message: 'Zip downloaded successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
  });
}

const executionDetailsRefetchFrequency = 5 * 1000;

export const useFetchExecutionDetail = (
  executionId: string,
  stopFetching?: boolean,
) => {
  const { executionSDK: sdk } = useAPI();
  return useQuery<GetExecutionResponse>({
    refetchInterval: stopFetching ? false : executionDetailsRefetchFrequency,
    queryKey: ['executions', executionId],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk.getExecution(executionId);
    },
  });
};

export const useFetchExecutionScreenshots = (
  executionId: string,
  imageUrls: string[],
  stopFetching?: boolean,
) => {
  const { executionSDK: sdk } = useAPI();
  return useQuery<[string, string][]>({
    refetchInterval: stopFetching ? false : executionDetailsRefetchFrequency,
    queryKey: ['executionId-screenshots', executionId],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk.getExecutionScreenshots(imageUrls);
    },
    enabled: imageUrls.length > 0,
  });
};

interface DeleteScreenshotPayload {
  executionId: string;
  screenshotId: string;
  metadata?: Partial<ExecutionBase>;
}

export function useDeleteExecutionScreenshot(): UseMutationResult<
  unknown,
  Error,
  DeleteScreenshotPayload
> {
  return useMutation<unknown, Error, DeleteScreenshotPayload>({
    mutationFn: async ({
      executionId,
      screenshotId,
      metadata,
    }: DeleteScreenshotPayload) => {
      if (metadata) {
        if ('currentStep' in metadata) {
          await ddbShim.removeFieldsFromItem(
            executionsMetadataTableName,
            { executionId },
            ['currentStep'],
          );
          delete metadata.currentStep;
        } else {
          await ddbShim.updateItem(
            executionsMetadataTableName,
            { executionId },
            metadata,
          );
        }
      } else {
        await s3Shim.deleteObject(
          executionBucket,
          `${executionId}/images/${screenshotId}`,
        );
      }
    },
    onSuccess: () => {
      notify({
        message: 'Screenshot deleted successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
  });
}

interface SendExecutionSignalPayload {
  executionId: string;
  signalType: SignalTypeEnum;
  payload?: Record<string, unknown>;
}

export function useSendExecutionSignal(): UseMutationResult<
  unknown,
  Error,
  SendExecutionSignalPayload
> {
  const { executionSDK: sdk } = useAPI();

  return useMutation<unknown, Error, SendExecutionSignalPayload>({
    mutationFn: ({ executionId, signalType, payload }) =>
      sdk.sendExecutionSignal({
        params: { executionId },
        body: {
          signalTypeBatch: [{ signal: signalType, payload: payload ?? {} }],
        },
      }),
    onSuccess: () => {
      notify({
        message: 'Execution signal sent successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
  });
}

export function useUpdateExecution(): UseMutationResult<
  unknown,
  Error,
  {
    id: string;
    status?: ExecutionStatus;
    adminRun?: boolean;
    statusDescr?: string;
  }
> {
  const { executionSDK: sdk } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    Error,
    {
      id: string;
      status?: ExecutionStatus;
      adminRun?: boolean;
      statusDescr?: string;
    }
  >({
    mutationFn: ({ id, status, adminRun, statusDescr }) =>
      sdk
        .updateExecution({
          executionId: id,
          ...(status ? { status } : {}),
          ...(typeof adminRun === 'boolean' ? { adminRun } : {}),
          ...(statusDescr ? { statusDescr } : {}),
        })
        .catch((error: unknown) => {
          handleException(error, {
            name: 'Error updating execution status',
            source: 'Execution/useUpdateExecution',
            extra: {
              id,
              status,
              adminRun,
              statusDescr,
            },
          });
          throw error;
        }),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['workflowExecutions'] });
      notify({
        message: 'Execution status updated successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
    onError: () => {
      notify({
        message: 'Error updating execution status',
        variant: AlertVariant.ERROR,
      });
    },
  });
}

export const useGetTemporalUiAddress = (userId: string) => {
  const { clusterData, loading, error } = useGetClusterData(userId);

  const temporalBaseUrl = clusterData
    ? TEMPORAL_UI_ADDRESS_MAPPING[clusterData.id]
    : undefined;
  return { temporalBaseUrl, loading, error };
};

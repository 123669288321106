import { useMemo, useState } from 'react';
import type {
  NodeData,
  WorkflowAction,
  WorkflowActionsOptions,
  WorkflowNode,
} from 'types-shared';
import {
  WorkflowImageNode,
  NodeStatusEnum,
  ActionsEnum,
  NodeTypesEnum,
  getNodeActions,
} from 'types-shared';
import { EditorStore } from '../../store/EditorState';
import { imageNodeEventChannel } from '../NodeElement/SelectedImageNodeContent';
import { v4 as uuid } from 'uuid';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import { Action } from './Action';
import { ActionHeader } from './ActionHeader';
import {
  constructVariable,
  createTarget,
  removeNode,
} from '../../utils/helper';
import { NodeCheck } from '../NodeCheck';
import { isAdmin } from '../../../../utils/env';
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from '@hello-pangea/dnd';
import { useSearchParams } from 'react-router-dom';
import { Button, Select, DragIndicator } from 'ui-kit';
import { clsx } from 'clsx';
import { CreateActionButtons } from './CreateActionButtons';
import { type SelectChangeEvent } from '@mui/material/Select';
import { useFeatureFlag } from '../../../../utils/helper';
import { FeatureFlag } from '../../../../utils/constants';

export type SelectedAction = WorkflowAction & {
  i: number;
};

const actionTypeOptions: ActionsEnum[] = [
  ActionsEnum.Click,
  ActionsEnum.Download,
  ActionsEnum.Input,
  ActionsEnum.Select,
  ActionsEnum.MultiChoice,
  ActionsEnum.Scrape,
  ActionsEnum.Arbitrary,
  ActionsEnum.KeyPress,
  ActionsEnum.UploadDocument,
  ActionsEnum.SwitchTab,
  ActionsEnum.NewTab,
  ActionsEnum.PickFromList,
  ActionsEnum.MultiSelect,
  ActionsEnum.MagicLoop,
];

const actionTypeToTitleMap: Record<string, string> = {
  [ActionsEnum.Click]: 'Click',
  [ActionsEnum.Download]: 'Download',
  [ActionsEnum.Input]: 'Input',
  [ActionsEnum.Select]: 'Select',
  [ActionsEnum.MultiChoice]: 'Multiple Choice',
  [ActionsEnum.Scrape]: 'Scrape',
  [ActionsEnum.Arbitrary]: 'Arbitrary',
  [ActionsEnum.UploadDocument]: 'Upload Document',
  [ActionsEnum.NewTab]: 'New tab',
  [ActionsEnum.SwitchTab]: 'Switch tab',
  [ActionsEnum.PickFromList]: 'Pick from list',
  [ActionsEnum.KeyPress]: 'Press',
  [ActionsEnum.MultiSelect]: 'Multi Select',
  [ActionsEnum.MagicLoop]: 'Magic Loop',
};

interface Props {
  node: WorkflowImageNode;
  onClose: () => void;
  setSelectedAction: (actionData: SelectedAction) => void;
  onEditTarget?: (action: SelectedAction) => void;
  onMoveAction?: (action: SelectedAction) => void;
}

function ActionsList({
  node,
  setSelectedAction,
  onEditTarget,
  onMoveAction,
}: Props) {
  const editorData = EditorStore();
  const {
    nodes,
    edges,
    setNodes,
    setEdges,
    variables,
    selectedNode,
    setSelectedNode,
    targets,
    globalVariables = {},
    addVariable,
    addTarget,
    updateVariableData,
  } = editorData;
  const [, setSearchParams] = useSearchParams();
  const [actionType, setActionType] = useState<ActionsEnum>(ActionsEnum.Click);
  const [isCreatingAction, setIsCreatingAction] = useState<boolean>(false);
  const downloadEditEnabled =
    useFeatureFlag(FeatureFlag.EditDownloads) ?? false;

  const actions = useMemo(
    () =>
      getNodeActions({
        ...node.data,
        isAdmin,
      }),
    [node],
  );

  const updateNodeData = (data: Partial<NodeData>) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.type !== NodeTypesEnum.Image) return _node;
        const updateNode = WorkflowImageNode.parse(_node);
        if (updateNode.id === node.id) {
          return {
            ...updateNode,
            data: {
              ...updateNode.data,
              ...data,
            },
          };
        }
        return updateNode;
      }),
    );
  };

  const updateAction = (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => {
    updateNodeData({
      actionData: {
        ...node.data.actionData,
        [action.id]: {
          ...action,
          options,
        },
      },
    });
  };

  const onDeleteAction = (action: WorkflowAction) => {
    let actionData = omit(node.data.actionData, [action.id]);
    let actionOrder = node.data.actionOrder.filter((id) => id !== action.id);

    if (action.actionType === ActionsEnum.MagicLoop) {
      actionData = omitBy(
        actionData,
        (item: WorkflowAction) => item.variableId === action.variableId,
      );
      actionOrder = actionOrder.filter((id) => id in actionData);
    }

    updateNodeData({
      actionData,
      actionOrder,
    });
  };

  const handleOnDragEnd = (results: DropResult) => {
    const { source, destination, type } = results;
    if (!destination) {
      return null;
    }
    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return null;
    }
    if (type === 'ACTIONS') {
      const sourceIndex = source.index;
      const destinationIndex = destination.index;

      const [removedAction] = actions.splice(sourceIndex, 1);
      actions.splice(destinationIndex, 0, removedAction);
      const actionOrder = actions.map((action) => action.id);
      updateNodeData({ actionOrder });
    }
  };

  const createAction = (options?: string[]) => {
    const actionId = uuid();
    const targetId = uuid();
    const variableId = uuid();
    const isArbitraryAction = actionType === ActionsEnum.Arbitrary;
    const isDownload = actionType === ActionsEnum.Download;
    const actualActionType = isDownload ? ActionsEnum.Click : actionType;
    const isMagicLoopAction = actionType === ActionsEnum.MagicLoop;

    const action: WorkflowAction = {
      id: actionId,
      // Change download action to a click, but leave the options
      actionType: actualActionType,
      targetId,
      variableId,
      ...(isArbitraryAction
        ? {
            title: 'Test title',
          }
        : {}),
      options: {
        hitl: isArbitraryAction,
        ...(isDownload ? { download: [] } : {}),
      },
    };
    const newActionOrder = [...node.data.actionOrder, actionId];
    const newActionData = {
      ...node.data.actionData,
      [actionId]: action,
    };
    if (isMagicLoopAction) {
      const magicLoopEndAction: WorkflowAction = {
        id: uuid(),
        actionType: ActionsEnum.MagicLoop,
        targetId,
        variableId,
        options: {
          terminal: true,
        },
      };
      newActionData[magicLoopEndAction.id] = magicLoopEndAction;
      newActionOrder.push(magicLoopEndAction.id);
    }
    updateNodeData({
      actionOrder: newActionOrder,
      actionData: newActionData,
    });
    addTarget(createTarget(targetId));
    const newVariable = constructVariable(
      variableId,
      actualActionType,
      options,
      action,
    );
    if (newVariable) {
      addVariable(newVariable);
    }
    setIsCreatingAction(false);
  };

  const deleteNode = () => {
    const { nodes: filteredNodes, edges: filteredEdges } = removeNode(
      nodes,
      edges,
      node.id,
    );
    setSearchParams({});
    setSelectedNode(null);
    setNodes(filteredNodes);
    setEdges(filteredEdges);
  };

  const selectedNodeData: WorkflowNode | undefined = useMemo(() => {
    return nodes.find((_node) => _node.id === selectedNode);
  }, [nodes, selectedNode]);

  const handleAddApprovalStep = (
    position: 'before' | 'after',
    index: number,
  ) => {
    const actionId = uuid();
    const targetId = uuid();
    const action: WorkflowAction = {
      id: actionId,
      targetId,
      actionType: ActionsEnum.ManualApproval,
      options: { hitl: true },
    };
    const newActionOrder = [...node.data.actionOrder];
    newActionOrder.splice(
      position === 'before' ? index : index + 1,
      0,
      actionId,
    );
    updateNodeData({
      actionOrder: newActionOrder,
      actionData: {
        ...node.data.actionData,
        [actionId]: action,
      },
    });
  };

  return (
    <>
      <ActionHeader
        node={selectedNodeData}
        onDeleteNode={deleteNode}
        setSelectedNode={setSelectedNode}
      />
      <div className="actions-list-container flex flex-col flex-1 space-y-6">
        <h2 className="font-medium text-lg">Actions</h2>
        <DragDropContext
          onDragEnd={(results) => {
            handleOnDragEnd(results);
          }}
        >
          <Droppable
            direction="vertical"
            droppableId="droppable-action"
            type="ACTIONS"
            isDropDisabled={!isAdmin}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {actions.map((action, i) => (
                  <Draggable
                    draggableId={`draggable-${action.id}`}
                    index={i}
                    key={action.id}
                    isDragDisabled={!isAdmin}
                  >
                    {(providedD, snapshot) => (
                      <div
                        className="mt-4 relative"
                        ref={providedD.innerRef}
                        {...providedD.draggableProps}
                      >
                        <Action
                          action={action}
                          allowDeleteAction={
                            isAdmin ||
                            action.actionType === ActionsEnum.ManualApproval
                          }
                          downloadEditEnabled={downloadEditEnabled || isAdmin}
                          i={i + 1}
                          imageNodeEventChannel={imageNodeEventChannel}
                          isDragging={snapshot.isDragging}
                          onAddApprovalStep={(position: 'before' | 'after') => {
                            handleAddApprovalStep(position, i);
                          }}
                          onDeleteAction={() => {
                            onDeleteAction(action);
                          }}
                          onEditClick={() => {
                            setSelectedAction({
                              ...action,
                              i: i + 1,
                            });
                          }}
                          onEditTarget={
                            onEditTarget
                              ? () => {
                                  onEditTarget({ ...action, i: i + 1 });
                                }
                              : undefined
                          }
                          onMoveAction={
                            onMoveAction
                              ? () => {
                                  onMoveAction({ ...action, i: i + 1 });
                                }
                              : undefined
                          }
                          onUpdateActionOptions={updateAction}
                          showManualHandleOption
                          updateVariableData={updateVariableData}
                          globalVariablesMap={globalVariables}
                          variablesMap={variables}
                          targets={targets}
                        >
                          {isAdmin ? (
                            <div
                              {...providedD.dragHandleProps}
                              className={clsx(
                                'rotate-90 text-gray-400 cursor-grab z-20 flex items-center justify-center w-8 h-8 hover:text-gray-800',
                                snapshot.isDragging && 'text-gray-800',
                              )}
                            >
                              <DragIndicator />
                            </div>
                          ) : null}
                        </Action>
                        {isAdmin ? <div>{action.id}</div> : null}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {isCreatingAction ? (
          <div className="border rounded-lg flex justify-between items-center">
            <div className="flex flex-col text-sm w-full p-4">
              <div className="mb-6">
                <Select
                  color="secondary"
                  fullWidth
                  getLabel={(opt: string) => {
                    if (actionTypeToTitleMap[opt])
                      return actionTypeToTitleMap[opt];
                    return opt;
                  }}
                  getValue={(opt: string) => opt}
                  label="Select action type"
                  name="actionType"
                  onChange={(event: SelectChangeEvent) => {
                    setActionType(event.target.value as ActionsEnum);
                  }}
                  options={actionTypeOptions}
                  value={actionType}
                />
              </div>
              <CreateActionButtons
                actionType={actionType}
                onCancel={() => {
                  setIsCreatingAction(false);
                }}
                onSubmit={(options) => {
                  createAction(options);
                }}
              />
            </div>
          </div>
        ) : null}
        {!isCreatingAction && isAdmin ? (
          <Button
            color="secondary"
            onClick={() => {
              setIsCreatingAction(true);
            }}
            variant="outlined"
          >
            Add Action
          </Button>
        ) : null}

        <span className="flex-1" />
        <NodeCheck
          isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
          updateNodeStatus={(status: NodeStatusEnum) => {
            updateNodeData({ nodeStatus: status });
          }}
        />
      </div>
    </>
  );
}

export default ActionsList;

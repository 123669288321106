import { createEventBus, type EventMap } from 'ui-kit';

export enum ProblemTypesEnum {
  GeneralIssue = 'General Issue',
  RecordingIssue = 'Recording Issue',
  ExecutionIssue = 'Execution Issue',
  IncorrectAction = 'Incorrect Action',
}

export const problemTypes: string[] = Object.values(ProblemTypesEnum);

export interface ContactModalOpenPayload {
  problemType?: string;
  workflowId?: string;
  workflowName?: string;
}

export interface ContactModalEvents extends EventMap {
  open: (problemType: ContactModalOpenPayload) => void;
  submit: () => void;
}

export const contactModalEventChannel = createEventBus<ContactModalEvents>();

export interface FormValues {
  problemType: string;
  file?: string;
  fileName?: string;
  description: string;
}

export const defaultFormValue: FormValues = {
  problemType: 'General Issue',
  file: '',
  description: '',
  fileName: '',
};

import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { Button, Flex, Modal, CustomTypography } from 'ui-kit';

interface Props {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  workflowName: string;
  setWorkflowName: (name: string) => void;
  isWorkflowCloning?: boolean;
}

export default function CloneModal({
  open,
  onCancel,
  onSubmit,
  workflowName,
  setWorkflowName,
  isWorkflowCloning,
}: Props) {
  return (
    <Modal className="w-full !max-w-3xl" onClose={onCancel} open={open}>
      <CustomTypography variant="h5">Clone workflow</CustomTypography>

      <div className="mt-7">
        <InputLabel className="!text-primary">Workflow name</InputLabel>
        <TextField
          color="secondary"
          focused
          fullWidth
          onChange={(e) => {
            setWorkflowName(e.target.value);
          }}
          size="medium"
          value={workflowName}
          variant="outlined"
        />
      </div>

      <Flex className="gap-4 mt-10">
        <Button
          color="secondary"
          loading={isWorkflowCloning}
          onClick={onSubmit}
          variant="contained"
        >
          Clone workflow
        </Button>
        <Button color="secondary" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}

import { useMemo, useState } from 'react';
import { ExecutionStatusEnum } from 'types-shared';
import { Button, Checkbox, Input, MenuItem, Select } from 'ui-kit';
import MuiSelect, { type SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import { titleCase } from '../../../utils/helper';
import { isAdmin } from '../../../utils/env';
import { statusTitleMapping } from '../../../utils/constants';
import {
  defaultFilters,
  type ExecutionListFilters,
  failedStatuses,
  runningStatuses,
} from '../utils/helper';
import uniq from 'lodash/uniq';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const adminRunsOptions = ['All', 'true', 'false'];

interface ExecutionListFilterProps {
  filters: ExecutionListFilters;
  setFilters: (filters: ExecutionListFilters) => void;
  onRefresh: () => void;
  onResetPage: () => void;
}

export default function ExecutionListFilter({
  filters,
  setFilters,
  onResetPage,
}: ExecutionListFilterProps) {
  const [id, setId] = useState<string>(filters.id ?? '');

  const onClear = () => {
    setFilters(defaultFilters);
    onResetPage();
  };

  const updateFilters = (key: string, value: unknown) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const getFilterTitle = (status: string) => {
    const formattedStatus = status.toLowerCase().replace('_', ' ');
    const title = isAdmin
      ? formattedStatus
      : statusTitleMapping[status] ?? formattedStatus;

    return isAdmin ? titleCase(status) : titleCase(title);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    onResetPage();
    let selectedStatuses = typeof value === 'string' ? value.split(',') : value;
    if (!isAdmin) {
      if (selectedStatuses.includes(ExecutionStatusEnum.Running)) {
        selectedStatuses = selectedStatuses.concat(runningStatuses);
      }
      if (selectedStatuses.includes(ExecutionStatusEnum.Terminated)) {
        selectedStatuses = selectedStatuses.concat(failedStatuses);
      }
    }
    updateFilters('status', uniq(selectedStatuses));
  };

  const hasFilters = useMemo(
    () => !isDeepEqual(filters, defaultFilters),
    [filters],
  );

  const executionStatusOptions = useMemo(() => {
    let statusOpts: ExecutionStatusEnum[] | string[] = [];

    if (isAdmin) {
      statusOpts = Object.values(ExecutionStatusEnum);
    } else {
      statusOpts = Object.values(ExecutionStatusEnum).filter(
        (s) =>
          ![...runningStatuses, ...failedStatuses].includes(
            s as ExecutionStatusEnum,
          ),
      );
      statusOpts.push(ExecutionStatusEnum.Running);
      statusOpts.push(ExecutionStatusEnum.Terminated);
    }

    return statusOpts;
  }, []);

  return (
    <div className="w-full flex items-center space-x-4 mt-10 mb-5">
      <Input
        classes={{ root: 'flex-1' }}
        floatingLabel
        label="Execution ID"
        onBlur={() => {
          onResetPage();
          updateFilters('id', id);
        }}
        onChange={(val) => {
          setId(val);
        }}
        value={id}
      />

      <MuiSelect
        classes={{ root: 'custom-select' }}
        input={<OutlinedInput label="Status" notched />}
        multiple
        value={filters.status as unknown as string}
        onChange={handleStatusChange}
        renderValue={(selected) => {
          if (!Array.isArray(selected)) {
            return selected;
          }
          const hasRunning = selected.includes(ExecutionStatusEnum.Running);
          const hasFailed = selected.includes(ExecutionStatusEnum.Terminated);
          return selected
            .filter(
              (item: ExecutionStatusEnum) =>
                (!hasRunning || !runningStatuses.includes(item)) &&
                (!hasFailed || !failedStatuses.includes(item)),
            )
            .slice(selected.length === 1 ? 0 : 1)
            .concat(hasRunning ? [ExecutionStatusEnum.Running] : [])
            .concat(hasFailed ? [ExecutionStatusEnum.Terminated] : [])
            .map((item) => getFilterTitle(item as string))
            .join(', ');
        }}
        MenuProps={MenuProps}
      >
        {executionStatusOptions.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox
              color="secondary"
              checked={filters.status.includes(item)}
              classes={{ root: 'mr-2' }}
            />
            <ListItemText primary={getFilterTitle(item)} />
          </MenuItem>
        ))}
      </MuiSelect>
      {isAdmin ? (
        <Select
          className="min-w-56"
          getLabel={(op: string) => op}
          getValue={(op: string) => op}
          label="Admin Runs"
          onChange={(e) => {
            const value = e.target.value;
            const hideAdminRuns =
              value === 'All' ? undefined : value === 'true';
            onResetPage();
            updateFilters('hideAdminRuns', hideAdminRuns);
          }}
          options={adminRunsOptions}
          value={
            filters.hideAdminRuns === undefined
              ? 'All'
              : String(filters.hideAdminRuns)
          }
        />
      ) : null}

      {hasFilters ? (
        <Button color="secondary" onClick={onClear} variant="text">
          Clear Filters
        </Button>
      ) : null}
    </div>
  );
}

import type {
  AddWorkflowRequest,
  AddWorkflowResponse,
  AdminVersion,
  CloneWorkflowRequest,
  GetWorkflowMetadataResponse,
  GetWorkflowResponse,
  UpdateWorkflowRequest,
  UpdateWorkflowResponse,
  UploadVideoResponse,
  WorkflowMetadataType,
  ZodFetcher,
} from 'api-types-shared';
import {
  addWorkflowSchema,
  axios,
  cloneWorkflowSchema,
  completeVideoUploadSchema,
  deleteWorkflowSchema,
  getWorkflowMetadataSchema,
  getWorkflowSchema,
  batchGetWorkflowsSchema,
  listWorkflowsSchema,
  updateWorkflowSchema,
  WorkflowStatusEnum,
  zodAxios,
} from 'api-types-shared';
import type { ApolloClient } from '@apollo/client';
import type { KyInstance, Options } from 'ky';
import { handleException } from 'sentry-browser-shared';
import {
  BackwardsCompatibleVariableMap,
  type CommitWorkflowState,
  ExecutionStatusEnum,
  RunnableWorkflowState,
  TargetMap,
  type VariableMap,
  WorkflowData,
} from 'types-shared';
import type { NodeEnv } from 'ui-kit';
import { apiEndpoints } from 'ui-kit';
import dayjs from 'dayjs';
import { getBlobFromS3 } from '../utils/blob';
import { createZodKyFetcher } from '../fetcher';
import { checkApolloQueryResult, ListAllWorkflowsDocument } from 'hasura-gql';
import { dateFormat, FETCH_BATCH_SIZE } from '../utils/constants';
import {
  generateWorkflowExecutionHourlyMetricsQuery,
  generateWorkflowExecutionMonthlyMetricsQuery,
  GET_TOTAL_EXECUTION_METRICS,
  GET_WORKFLOW_EXECUTION_METRICS,
  GET_WORKFLOW_METRICS,
} from '../utils/schema';
import {
  type AggregateResult,
  convertExecutionsDataToMap,
  type ExecutionResult,
  failedStatuses,
  runningStatuses,
} from '../utils/helper';
import ky from 'ky';

export class WorkflowSDK {
  readonly endpoint: string;
  private _apolloClient: ApolloClient<object>;
  private _kyZodFetcher: ZodFetcher<KyInstance>;
  private _kyNoAuthFetcher: KyInstance;

  constructor(
    env: NodeEnv,
    apolloClient: ApolloClient<object>,
    kyOpts?: Options,
  ) {
    this.endpoint = apiEndpoints[env].workflowApiV1;
    this._kyZodFetcher = createZodKyFetcher(kyOpts);
    this._kyNoAuthFetcher = ky;
    this._apolloClient = apolloClient;
  }

  fetchWorkflowMetrics = async (): Promise<{
    workflowsCount: number;
    executionsCount: number;
  }> => {
    const startOfThisMonth = dayjs().startOf('month').format(dateFormat);
    const [workflowMetrics, executionMetrics] = await Promise.all([
      this._apolloClient.query({
        query: GET_WORKFLOW_METRICS,
      }),
      this._apolloClient.query({
        query: GET_TOTAL_EXECUTION_METRICS,
        variables: {
          startOfThisMonth,
        },
      }),
    ]);
    const { totalWorkflows } = checkApolloQueryResult(
      workflowMetrics,
      'totalWorkflowsCountQuery',
    ) as {
      totalWorkflows: AggregateResult;
    };
    const { executionsThisMonth } = checkApolloQueryResult(
      executionMetrics,
      'executionsThisMonthQuery',
    ) as {
      executionsThisMonth: AggregateResult;
    };
    return {
      workflowsCount: totalWorkflows.aggregate.count,
      executionsCount: executionsThisMonth.aggregate.count,
    };
  };

  fetchWorkflowExecutionMetrics = async (
    workflowId: string,
  ): Promise<{
    totalExecutions: number;
    executionsCount: Record<string, number>;
    monthlyExecutions: Record<string, number>[];
    hourlyExecutions: Record<string, number>[];
  }> => {
    const startOfThisMonth = dayjs().startOf('month').format(dateFormat);
    const endOfThisMonth = dayjs().endOf('month').format(dateFormat);
    const prevMonthsList = [5, 4, 3, 2, 1].map((diff) => {
      const month = dayjs().subtract(diff, 'month');
      return [
        month.format('MMM'),
        month.startOf('month').format(dateFormat),
        month.endOf('month').format(dateFormat),
      ];
    });
    const monthsList = [
      ...prevMonthsList,
      [dayjs().format('MMM'), startOfThisMonth, endOfThisMonth],
    ];
    const timeRanges = [
      ['am12', '00:00:00', '03:59:59'],
      ['am4', '04:00:00', '07:59:59'],
      ['am8', '08:00:00', '11:59:59'],
      ['pm12', '12:00:00', '15:59:59'],
      ['pm4', '16:00:00', '19:59:59'],
      ['pm8', '20:00:00', '23:59:59'],
    ];
    const statuses = [
      // Success status
      ExecutionStatusEnum.Success,
      ...runningStatuses,
      ...failedStatuses,
    ];
    const monthlyQuery =
      generateWorkflowExecutionMonthlyMetricsQuery(monthsList);
    const dateRange = [
      '',
      dayjs().subtract(1, 'month').format(dateFormat),
      dayjs().format(dateFormat),
    ];
    const hourlyQuery = generateWorkflowExecutionHourlyMetricsQuery(
      dateRange,
      timeRanges,
    );
    const [executionMetrics, monthlyExecutionMetrics, hourlyExecutionMetrics] =
      await Promise.all([
        this._apolloClient.query({
          query: GET_WORKFLOW_EXECUTION_METRICS,
          variables: {
            workflowId,
            statuses,
          },
        }),
        this._apolloClient.query({
          query: monthlyQuery,
          variables: {
            workflowId,
            statuses,
          },
        }),
        this._apolloClient.query({
          query: hourlyQuery,
          variables: {
            workflowId,
            statuses,
          },
        }),
      ]);
    const { overallExecutions, totalExecutions } = checkApolloQueryResult(
      executionMetrics,
      'workflowExecutionStatusWiseCountQuery',
    ) as {
      overallExecutions: ExecutionResult[];
      totalExecutions: AggregateResult;
    };
    const executionsMap = convertExecutionsDataToMap(overallExecutions);
    const monthlyExecutionsData = checkApolloQueryResult(
      monthlyExecutionMetrics,
      'monthlyExecutionsQuery',
    ) as Record<string, ExecutionResult[]>;
    const monthlyExecutionsMap = monthsList.map(([month]) =>
      convertExecutionsDataToMap(monthlyExecutionsData[`${month}Executions`]),
    );
    const hourlyExecutionsData = checkApolloQueryResult(
      hourlyExecutionMetrics,
      'hourlyExecutionsQuery',
    ) as Record<string, ExecutionResult[]>;
    const hourlyExecutionsMap = timeRanges.map(([time]) =>
      convertExecutionsDataToMap(hourlyExecutionsData[`${time}Executions`]),
    );
    return {
      executionsCount: executionsMap,
      totalExecutions: totalExecutions.aggregate.count,
      monthlyExecutions: monthlyExecutionsMap,
      hourlyExecutions: hourlyExecutionsMap,
    };
  };

  fetchWorkflowsList = async (): Promise<WorkflowMetadataType[]> => {
    const { userWorkflows } = await this._kyZodFetcher(
      listWorkflowsSchema.response,
      `${this.endpoint}/list`,
      {
        method: 'get',
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Fetch workflows error',
        source: 'WorkflowSDK.fetchWorkflowsList',
      });
      return { userWorkflows: {} };
    });

    return Object.values(userWorkflows);
  };

  fetchWorkflowsListBatched = async (): Promise<WorkflowMetadataType[]> => {
    // Get all workflow IDs from Hasura
    const workflowIds = await this._apolloClient
      .query({ query: ListAllWorkflowsDocument })
      .then((res) => res.data.workflows.map((workflow) => workflow.id));

    // Get workflow metadata in batches
    const batchSize = FETCH_BATCH_SIZE.WORKFLOWS;
    const fetchPromises = [];
    for (let i = 0; i < workflowIds.length; i += batchSize) {
      const batch = workflowIds.slice(i, i + batchSize);

      fetchPromises.push(
        this._kyZodFetcher(
          batchGetWorkflowsSchema.response,
          `${this.endpoint}/batch`,
          {
            method: 'post',
            body: JSON.stringify({ ids: batch }),
          },
        ).catch((err: unknown) => {
          handleException(err, {
            name: 'Fetch workflows error',
            source: 'WorkflowSDK.fetchWorkflowsListBatched',
            extra: { batch },
          });
          return { workflows: {} };
        }),
      );
    }

    const results = await Promise.all(fetchPromises);
    return results.flatMap(({ workflows: batchWorkflows }) =>
      Object.values(batchWorkflows),
    );
  };

  fetchWorkflowMetadata = async (
    workflowId: string,
  ): Promise<GetWorkflowMetadataResponse | null> => {
    return this._kyZodFetcher(
      getWorkflowMetadataSchema.response,
      `${this.endpoint}/${workflowId}/metadata`,
      { method: 'get' },
    ).catch((err: unknown) => {
      if ((err as Error | undefined)?.message.includes('404')) {
        window.location.replace('/');
        return null;
      }
      handleException(err, {
        name: 'Fetch workflow metadata error',
        source: 'WorkflowSDK.fetchWorkflowMetadata',
        extra: { workflowId },
      });
      return null;
    });
  };

  fetchWorkflowData = (_workflowId: string, _type: 'json' | 'text') => {
    throw new Error('fetchWorkflowData not implemented');
  };

  createWorkflow = async (
    createWorkflowData: AddWorkflowRequest['body'],
    env?: NodeEnv,
  ): Promise<AddWorkflowResponse> => {
    const parsedRequest =
      addWorkflowSchema.request.shape.body.parse(createWorkflowData);
    if (env === 'development') {
      parsedRequest.status = WorkflowStatusEnum.ProcessedImport;
    }

    const response = await this._kyZodFetcher(
      addWorkflowSchema.response,
      `${this.endpoint}/new`,
      {
        method: 'put',
        body: JSON.stringify(parsedRequest),
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Create workflow error',
        source: 'WorkflowSDK.createWorkflow',
        extra: { data: parsedRequest },
      });
    });
    return addWorkflowSchema.response.parse(response);
  };

  completeUploadVideo = async ({
    uploadVideoData,
    workflowId,
    uploadId,
    videoReqId,
  }: {
    uploadVideoData: UploadVideoResponse;
    workflowId: string;
    uploadId: string;
    videoReqId?: string;
  }) => {
    const confirmUpload = await this._kyZodFetcher(
      completeVideoUploadSchema.response,
      `${this.endpoint}/${workflowId}/complete-video-upload`,
      {
        method: 'put',
        searchParams: {
          videoReqId: videoReqId ?? 'main',
        },
        body: JSON.stringify({
          uploadId,
          parts: uploadVideoData,
        }),
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Complete video upload error',
        source: 'WorkflowSDK.completeUploadVideo',
        extra: { workflowId, uploadId, videoReqId },
      });
    });

    return completeVideoUploadSchema.response.parse(confirmUpload);
  };

  deleteWorkflow = (workflowId: string): Promise<object> => {
    return this._kyZodFetcher(
      deleteWorkflowSchema.response,
      `${this.endpoint}/${workflowId}`,
      { method: 'delete' },
    );
  };

  private _getWorkflowUrls = async (params: {
    workflowId: string;
    stateTypes: string[];
    update?: boolean;
    commitStatus?: WorkflowStatusEnum;
    versionId?: string;
    adminUserPush?: AdminVersion;
  }): Promise<GetWorkflowResponse> => {
    const {
      workflowId,
      stateTypes,
      update = false,
      commitStatus = null,
      versionId,
      adminUserPush,
    } = params;
    const response = await this._kyZodFetcher(
      getWorkflowSchema.response,
      `${this.endpoint}/${workflowId}`,
      {
        method: update ? 'post' : 'get',
        searchParams: stateTypes.reduce(
          (acc: Record<string, boolean | string>, stateType) => {
            acc[stateType] = true;
            return acc;
          },
          versionId ? { versionId } : {},
        ),
        ...(update && {
          json: {
            status: commitStatus,
            ...(adminUserPush ? { adminUserPush } : {}),
          },
        }),
      },
    );

    return getWorkflowSchema.response.parse(response);
  };

  getWorkflowUrls = async (
    workflowId: string,
    stateTypes: string[],
    versionId?: string,
  ): Promise<GetWorkflowResponse> => {
    const res = this._getWorkflowUrls({
      workflowId,
      stateTypes,
      update: false,
      commitStatus: undefined,
      versionId,
    }).catch((err: unknown) => {
      const errorRes = {
        stateUrl: undefined,
        targetUrl: undefined,
        variableUrl: undefined,
      };
      handleException(err, {
        name: 'Workflow URLs fetch failed',
        source: 'WorkflowSDK.getWorkflowUrls',
        extra: { workflowId, stateTypes, versionId },
      });

      window.location.replace('/');
      return errorRes;
    });

    return res;
  };

  updateWorkflowUrls = async (
    workflowId: string,
    stateTypes: string[],
    commitStatus?: WorkflowStatusEnum,
    adminUserPush?: AdminVersion,
  ): Promise<UpdateWorkflowResponse> => {
    const res = this._getWorkflowUrls({
      workflowId,
      stateTypes,
      update: true,
      commitStatus,
      adminUserPush,
    }).catch((err: unknown) => {
      handleException(err, {
        name: 'Update workflow URLs fetch failed',
        source: 'WorkflowSDK.updateWorkflowUrls',
        extra: { workflowId, stateTypes, commitStatus, adminUserPush },
      });
      return {
        stateUrl: undefined,
        targetUrl: undefined,
        variableUrl: undefined,
      };
    });

    return res;
  };

  getWorkflowStateData = async (
    workflowId: string,
    versionId?: string,
  ): Promise<WorkflowData | null> => {
    const { stateUrl } = await this.getWorkflowUrls(
      workflowId,
      ['stateReq'],
      versionId,
    );
    if (!stateUrl) {
      handleException(new Error(), {
        name: 'Workflow state URL fetch failed',
        source: 'WorkflowSDK.getWorkflowStateData',
        extra: { workflowId, versionId },
      });
      return null;
    }
    const rawWorkflowData = await this._kyNoAuthFetcher
      .get(stateUrl)
      .json<WorkflowData>()
      .catch((error: unknown) => {
        handleException(error, {
          name: 'Workflow state data fetch failed',
          source: 'WorkflowSDK.getWorkflowStateData',
          extra: { workflowId, versionId },
        });
        return null;
      });
    const parsedWorkflowData = WorkflowData.safeParse(rawWorkflowData);
    if (!parsedWorkflowData.success) {
      handleException(new Error(), {
        name: 'Workflow state data schema validation failed',
        source: 'WorkflowSDK.getWorkflowStateData',
        extra: { workflowId, versionId, parsedWorkflowData, rawWorkflowData },
      });
      // We return the raw workflow data because it's likely that the data is
      // still renderable in the UI, if not, the UI should handle the error.
      return rawWorkflowData;
    }
    return parsedWorkflowData.data;
  };

  getWorkflowTargetData = async (
    workflowId: string,
    versionId?: string,
  ): Promise<TargetMap> => {
    const { targetUrl } = await this.getWorkflowUrls(
      workflowId,
      ['targetReq'],
      versionId,
    );
    if (!targetUrl) {
      handleException(new Error(), {
        name: 'Workflow target URL fetch failed',
        source: 'WorkflowSDK.getWorkflowTargetData',
        extra: { workflowId, versionId },
      });
      return {};
    }
    return zodAxios(TargetMap, targetUrl, {
      method: 'get',
    }).catch((error: unknown) => {
      handleException(error, {
        name: 'Workflow target data fetch failed',
        source: 'WorkflowSDK.getWorkflowTargetData',
        extra: { workflowId, versionId },
      });
      return {};
    });
  };

  getWorkflowVariableData = async (
    workflowId: string,
    versionId?: string,
  ): Promise<VariableMap> => {
    const { variableUrl } = await this.getWorkflowUrls(
      workflowId,
      ['variableReq'],
      versionId,
    );
    if (!variableUrl) {
      handleException(new Error(), {
        name: 'Workflow variable URL fetch failed',
        source: 'WorkflowSDK.getWorkflowVariableData',
        extra: { workflowId, versionId },
      });
      return {};
    }
    return zodAxios(BackwardsCompatibleVariableMap, variableUrl, {
      method: 'get',
    }).catch((error: unknown) => {
      handleException(error, {
        name: 'Workflow variable data fetch failed',
        source: 'WorkflowSDK.getWorkflowVariableData',
        extra: { workflowId, versionId },
      });
      return {};
    });
  };

  updateWorkflowName = async (
    workflowId: string,
    data: Pick<WorkflowMetadataType, 'workflowName'>,
  ) => {
    return this.updateWorkflowMetadata({
      body: data,
      params: { workflowId },
      query: {
        stateReq: false,
        targetReq: false,
        variableReq: false,
      },
    });
  };

  updateWorkflowStatus = async (
    workflowId: string,
    data: Pick<WorkflowMetadataType, 'status'>,
  ) => {
    return this.updateWorkflowMetadata({
      body: data,
      params: { workflowId },
      query: {
        stateReq: false,
        targetReq: false,
        variableReq: false,
      },
    });
  };

  updateWorkflowMetadata = async (req: UpdateWorkflowRequest) => {
    return this._kyZodFetcher(
      updateWorkflowSchema.response,
      `${this.endpoint}/${req.params.workflowId}`,
      {
        method: 'post',
        body: JSON.stringify(req.body),
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Update workflow metadata error',
        source: 'WorkflowSDK.updateWorkflowMetadata',
        extra: { req },
      });
    });
  };

  updateAllWorkflowData = async (
    workflowId: string,
    workflowData: CommitWorkflowState,
    commitStatus?: WorkflowStatusEnum,
    adminVersion?: AdminVersion,
  ): Promise<{
    state: WorkflowData | null;
    variables: VariableMap;
    targets: TargetMap;
  } | null> => {
    const runnableCommit =
      RunnableWorkflowState.safeParse(workflowData).success;
    const { stateUrl, variableUrl, targetUrl } = await this.updateWorkflowUrls(
      workflowId,
      ['stateReq', 'variableReq', 'targetReq'],
      commitStatus ??
        (runnableCommit
          ? WorkflowStatusEnum.Ready
          : WorkflowStatusEnum.Invalid),
      adminVersion,
    );
    if (!stateUrl || !variableUrl || !targetUrl) {
      handleException(new Error(), {
        name: 'Update workflow data error',
        source: 'WorkflowSDK.updateAllWorkflowData',
        extra: { workflowId, commitStatus, workflowData, adminVersion },
      });
      return null;
    }

    const { nodes, edges, targets, variables } = workflowData;
    const workflowState = { nodes, edges };

    const [updatedState, updatedVariables, updatedTargets] = await Promise.all([
      this.updateWorkflowStateData(workflowId, workflowState, stateUrl),
      this.updateWorkflowVariableData(workflowId, variables, variableUrl),
      this.updateWorkflowTargetData(workflowId, targets, targetUrl),
    ]);

    return {
      state: updatedState,
      variables: updatedVariables,
      targets: updatedTargets,
    };
  };

  updateWorkflowStateData = async (
    workflowId: string,
    workflowData: WorkflowData,
    providedStateUrl?: string,
  ): Promise<WorkflowData | null> => {
    let stateUrl = providedStateUrl;
    if (!stateUrl) {
      ({ stateUrl } = await this.updateWorkflowUrls(workflowId, ['stateReq']));
      if (!stateUrl) {
        handleException(new Error(), {
          name: 'Workflow state URL fetch failed',
          source: 'WorkflowSDK.updateWorkflowStateData',
          extra: { workflowId, workflowData, providedStateUrl },
        });
        return null;
      }
    }

    await axios
      .put(stateUrl, workflowData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((error: unknown) => {
        handleException(error, {
          name: 'Upload workflow state data error',
          source: 'WorkflowSDK.updateWorkflowStateData',
          extra: { workflowId, workflowData, providedStateUrl },
        });
        return null;
      });
    return workflowData;
  };

  updateWorkflowTargetData = async (
    workflowId: string,
    targetData: TargetMap,
    providedTargetUrl?: string,
  ): Promise<TargetMap> => {
    let targetUrl = providedTargetUrl;
    if (!targetUrl) {
      ({ targetUrl } = await this.updateWorkflowUrls(workflowId, [
        'targetReq',
      ]));
      if (!targetUrl) {
        handleException(new Error(), {
          name: 'Get update workflow target data URLs error',
          source: 'WorkflowSDK.updateWorkflowTargetData',
          extra: { workflowId, targetData, providedTargetUrl },
        });
        return {};
      }
    }

    await axios
      .put(targetUrl, targetData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((error: unknown) => {
        handleException(error, {
          name: 'Upload workflow target data error',
          source: 'WorkflowSDK.updateWorkflowTargetData',
          extra: { workflowId, targetData, providedTargetUrl },
        });
        return {};
      });
    return targetData;
  };

  updateWorkflowVariableData = async (
    workflowId: string,
    variableData: VariableMap,
    providedVariableUrl?: string,
  ): Promise<VariableMap> => {
    let variableUrl = providedVariableUrl;
    if (!variableUrl) {
      ({ variableUrl } = await this.updateWorkflowUrls(workflowId, [
        'variableReq',
      ]));
      if (!variableUrl) {
        handleException(new Error(), {
          name: 'Workflow variable URL fetch failed',
          source: 'WorkflowSDK.updateWorkflowVariableData',
          extra: { workflowId, variableData, providedVariableUrl },
        });
        return {};
      }
    }
    await axios
      .put(variableUrl, variableData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((error: unknown) => {
        handleException(error, {
          name: 'Upload workflow variable data error',
          source: 'WorkflowSDK.updateWorkflowVariableData',
          extra: { workflowId, variableData, providedVariableUrl },
        });
        return {};
      });

    return variableData;
  };

  getImageData = async (
    workflowId: string,
    imageIds: string[],
    imageOriginal = false,
  ): Promise<Record<string, string | null>> => {
    const response = await this._kyZodFetcher(
      getWorkflowSchema.response,
      `${this.endpoint}/${workflowId}`,
      {
        method: 'get',
        searchParams: {
          imageOriginal,
          imageIds: imageIds.join(','),
        },
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Fetch workflow get image urls error',
        source: 'WorkflowSDK.getImageData',
        extra: { workflowId, imageIds, imageOriginal },
      });
      return { imageUrls: null };
    });

    const { imageUrls } = response;

    if (!imageUrls) {
      throw new Error('Image URLs are not available');
    }

    const blobMap: Record<string, string | null> = {};
    const promises = Object.entries(imageUrls).map(
      async ([imageId, dataUrl]) => {
        const blob = await getBlobFromS3(dataUrl).catch((error: unknown) => {
          handleException(error, {
            name: 'Fetch workflow get image data error',
            source: 'WorkflowSDK.getImageData',
            extra: { workflowId, imageIds, imageOriginal },
          });
          return null;
        });

        if (blob) {
          blobMap[imageId] = URL.createObjectURL(blob);
        }
      },
    );

    try {
      await Promise.allSettled(promises);
    } catch (e) {
      handleException(e, {
        name: 'Failed resolving image blobs',
        source: 'WorkflowSDK.getImageData',
        extra: { workflowId, imageIds, imageOriginal },
      });
    }

    return blobMap;
  };

  revertWorkflow = async (workflowId: string, revertVersionId: string) => {
    return this._kyZodFetcher(
      updateWorkflowSchema.response,
      `${this.endpoint}/${workflowId}`,
      {
        method: 'post',
        body: JSON.stringify({ revertVersionId }),
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Revert workflow error',
        source: 'WorkflowSDK.revertWorkflow',
        extra: { workflowId, revertVersionId },
      });
      return null;
    });
  };

  cloneWorkflow = async (payload: CloneWorkflowRequest) => {
    const { params, body } = payload;
    const { workflowId } = params;
    const { workflowName } = body;

    return this._kyZodFetcher(
      cloneWorkflowSchema.response,
      `${this.endpoint}/${workflowId}/clone`,
      {
        method: 'post',
        body: JSON.stringify({ workflowName }),
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Clone workflow error',
        source: 'WorkflowSDK.cloneWorkflow',
        extra: { workflowId, workflowName },
      });
      return null;
    });
  };

  getVideos = async (workflowId: string): Promise<string[]> => {
    const response = await this._kyZodFetcher(
      getWorkflowSchema.response,
      `${this.endpoint}/${workflowId}`,
      {
        searchParams: {
          videoReq: true,
        },
        method: 'get',
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Get workflow video data failed',
        source: 'WorkflowSDK.getVideos',
        extra: { workflowId },
      });
      return null;
    });

    const videoUrls = response?.videoUrls ?? [];

    if (!videoUrls.length) {
      handleException(new Error(), {
        name: 'Videos URLs are empty',
        source: 'WorkflowSDK.getVideos',
        extra: { workflowId },
      });
      throw new Error('Videos are empty');
    }

    return videoUrls;
  };
}

import React from 'react';
import { type ExecutionBase } from 'types-shared/executionTypes';
import { CustomFullscreenExitIcon, Button, Chip } from 'ui-kit';

export function FullscreenHeader({
  workflowName,
  isAPITrigger,
  executionMetadata,
  _selectedIndex,
  screenshotUrlsLength,
  setIsFullscreen,
  visible,
}: {
  workflowName: string | undefined;
  isAPITrigger: boolean;
  executionMetadata: ExecutionBase;
  _selectedIndex: number;
  screenshotUrlsLength: number;
  visible: boolean;
  setIsFullscreen: (value: React.SetStateAction<boolean>) => void;
}) {
  return visible ? (
    <div className="border-b px-8 py-5 flex justify-between items-center">
      <div className="flex flex-col space-y-1">
        <span className="text-sm">{workflowName ?? 'Workflow'}</span>
        <div className="flex space-x-12 text-xs items-center">
          <span>
            Execution ID: {isAPITrigger ? 'N/A' : executionMetadata.setId}
          </span>
          <span>
            Step: {_selectedIndex + 1}/{screenshotUrlsLength}
          </span>
          <Chip
            className="!mr-auto"
            color="success"
            label="Completed"
            size="small"
            variant="filled"
          />
        </div>
      </div>
      <Button
        className="!p-2 !min-w-min !rounded-lg !bg-white !border-info"
        color="primary"
        onClick={() => {
          setIsFullscreen((val) => !val);
        }}
        variant="outlined"
      >
        <CustomFullscreenExitIcon className="!w-5 !h-5 !text-info" />
      </Button>
    </div>
  ) : null;
}

import { WorkflowStatusEnum } from 'api-types-shared';
import { InfoOutlined, Tooltip } from 'ui-kit';
import { capitalizeFirstLetter } from '../utils/helper';

interface Props {
  workflowStatus: WorkflowStatusEnum;
  showProcessingState: boolean;
}

export default function StatusLabel({
  workflowStatus,
  showProcessingState,
}: Props) {
  if (workflowStatus === WorkflowStatusEnum.ProcessingImport) {
    return showProcessingState ? (
      <Tooltip
        arrow
        containerClassName="max-w-max"
        title="We are processing your workflow. You'll get a notification when it's ready!"
      >
        <div className="flex items-center space-x-1 max-w-full">
          <span className="flex-1 truncate">Processing</span>
          <InfoOutlined className="!h-4 !w-4" />
        </div>
      </Tooltip>
    ) : (
      <span>Importing</span>
    );
  } else if (workflowStatus === WorkflowStatusEnum.Ready) {
    return <span className="normal-case">Ready to run</span>;
  } else if (workflowStatus === WorkflowStatusEnum.ProcessedImport) {
    return <span className="normal-case">Needs review</span>;
  } else if (workflowStatus === WorkflowStatusEnum.ProcessingImportError) {
    return (
      <Tooltip
        arrow
        containerClassName="max-w-max"
        title="Error during processing. Please retry or contact support."
      >
        <div className="flex items-center space-x-1 max-w-full">
          <span className="flex-1 normal-case">Processing error</span>
          <InfoOutlined className="!h-4 !w-4 text-[#d32e2f]" />
        </div>
      </Tooltip>
    );
  } else if (
    workflowStatus === WorkflowStatusEnum.ProcessingImportErrorAuthentication
  ) {
    return (
      <Tooltip
        arrow
        containerClassName="max-w-max"
        title="Authentication is required to execute this workflow. Please record the workflow with login and try importing again or contact support."
      >
        <div className="flex items-center space-x-1 max-w-full">
          <span className="flex-1 normal-case">Missing access</span>
          <InfoOutlined className="!h-4 !w-4 text-[#ee6c00]" />
        </div>
      </Tooltip>
    );
  }

  return (
    <span className="normal-case">{capitalizeFirstLetter(workflowStatus)}</span>
  );
}

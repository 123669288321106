import { useMemo, useRef, useState, type MouseEvent } from 'react';
import type { WorkflowImageNode, WorkflowNodeProps } from 'types-shared';
import { NodeStatusEnum, NodeTypesEnum, getNodeActions } from 'types-shared';
import { useStore } from 'types-shared/reactflow';
import { minZoomLevelForFullContent } from '../../../utils/constants';
import NodeElement from '../index';
import { EditorStore, type EditorStoreProps } from '../../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import {
  AlertVariant,
  notify,
  IconButton,
  Menu,
  MenuItem,
  ActionsIcon,
  MoreVertRounded,
} from 'ui-kit';
import { useParams, useSearchParams } from 'react-router-dom';
import { clsx } from 'clsx';
import { isAdmin } from '../../../../../utils/env';
import deepClone from 'lodash/cloneDeep';
import UpdateImageModal from './UpdateImageModal';
import { v4 as uuid } from 'uuid';
import values from 'lodash/values';

enum NodeActions {
  copyNode = 'copyNode',
  deleteNode = 'deleteNode',
  updateImage = 'updateImage',
}

const zoomSelector = (s: { transform: number[] }) =>
  s.transform[2] >= minZoomLevelForFullContent;

export default function ImageNode(workflowData: WorkflowNodeProps) {
  const { id } = workflowData;
  const { workflowId = '' } = useParams();
  const imgRef = useRef<HTMLImageElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

  const [, setSearchParams] = useSearchParams();
  const {
    nodes,
    edges,
    targets,
    setSelectedNode,
    thumbnails,
    setEdges,
    setNodes,
    addTarget,
    addVariable,
    variables,
    globalVariables,
  } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
      setNodes: state.setNodes,
      setSelectedNode: state.setSelectedNode,
      thumbnails: state.thumbnails,
      edges: state.edges,
      targets: state.targets,
      setEdges: state.setEdges,
      addVariable: state.addVariable,
      addTarget: state.addTarget,
      variables: state.variables,
      globalVariables: state.globalVariables,
    })),
  );
  const allowedActions = [
    {
      action: NodeActions.copyNode,
      label: 'Copy Node',
    },
    {
      action: NodeActions.deleteNode,
      label: 'Delete Node',
    },
    {
      action: NodeActions.updateImage,
      label: 'Update Image',
    },
  ];

  const handleAction = (action: keyof typeof NodeActions) => {
    if (action === 'updateImage') {
      setIsImageModalOpen(true);
      return;
    }

    if (action === 'deleteNode') {
      const filteredNodes = nodes.filter((node) => node.id !== id);
      const outgoingEdge = edges.find((e) => e.source === id);

      const filteredEdges = edges
        .filter((edge) => edge.source !== id)
        .filter((edge) => outgoingEdge ?? edge.target !== id)
        .map((edge) => {
          if (edge.target === id && outgoingEdge) {
            return {
              ...edge,
              target: outgoingEdge.target,
            };
          }
          return edge;
        });
      setNodes(filteredNodes);
      setEdges(filteredEdges);
    }

    if (action === 'copyNode') {
      const newNodeId = uuid();
      const currentNode = nodes.find((node) => node.id === id);
      const currentNodeIndex = nodes.findIndex((node) => node.id === id);
      const lastNode = nodes[nodes.length - 1];
      if (currentNode && currentNode.type === NodeTypesEnum.Image) {
        let newNode = deepClone(currentNode);
        newNode = {
          ...newNode,
          id: newNodeId,
          position: {
            x: currentNode.position.x + 400,
            y: currentNode.position.y,
          },
        };
        values(newNode.data.actionData).forEach((_action) => {
          const oldActionId = _action.id;
          const oldTargetId = _action.targetId;
          const oldVariableId = _action.variableId;
          const newActionId = uuid();
          const newTargetId = uuid();
          const newVariableId = uuid();
          if (oldTargetId) {
            const newTarget = deepClone(targets[oldTargetId]);
            newTarget.id = newTargetId;
            addTarget(newTarget);
          }
          if (oldVariableId) {
            const newVariable = deepClone(
              variables[oldVariableId] ?? globalVariables?.[oldVariableId],
            );
            newVariable.id = newVariableId;
            addVariable(newVariable);
          }
          const newAction = {
            ..._action,
            id: newActionId,
            ...(oldTargetId ? { targetId: newTargetId } : {}),
            ...(oldVariableId ? { variableId: newVariableId } : {}),
          };
          // eslint-disable-next-line
          delete newNode.data.actionData[oldActionId];
          newNode.data.actionData[newActionId] = newAction;
          const i = newNode.data.actionOrder.indexOf(oldActionId);
          newNode.data.actionOrder[i] = newActionId;
        });
        const newEdges = [
          ...edges.map((edge) => {
            if (edge.target === nodes[currentNodeIndex + 1]?.id) {
              return { ...edge, target: newNodeId };
            }
            return edge;
          }),
          {
            id: uuid(),
            source:
              currentNodeIndex === nodes.length - 1 ? lastNode.id : newNode.id,
            target:
              currentNodeIndex === nodes.length - 1
                ? newNode.id
                : nodes[currentNodeIndex + 1]?.id,
          },
        ];
        const newNodes = nodes.map((_node, index) => {
          if (index > currentNodeIndex) {
            return {
              ..._node,
              position: {
                ..._node.position,
                x: _node.position.x + 400,
              },
            };
          }
          return _node;
        });
        newNodes.splice(currentNodeIndex + 1, 0, newNode);
        setNodes(newNodes);
        setEdges(newEdges);
      }
    }
  };

  const selectedNode: WorkflowImageNode | null = useMemo(() => {
    const workflowNode = nodes.find((node) => node.id === id);

    if (!workflowNode) {
      return null;
    }

    if (workflowNode.type === NodeTypesEnum.Image) {
      return workflowNode;
    }

    notify({
      message: 'Selected step is not an image step',
      variant: AlertVariant.ERROR,
    });
    throw new Error(
      `Selected step ${id} is not an image step. Steps: ${JSON.stringify(nodes)}`,
    );
  }, [id, nodes]);

  const showFullContent = useStore(zoomSelector);

  if (!selectedNode) {
    return null;
  }

  const {
    data: { actionData, actionOrder, nodeStatus: status, imageData },
  } = selectedNode;

  const actionDataSize = getNodeActions({
    actionOrder,
    actionData,
    isAdmin,
  }).length;

  const thumbnailData = thumbnails[imageData.imageId];

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  };

  return (
    <>
      <NodeElement
        allowBulkCheck
        onClick={() => {
          setSearchParams({ focusNode: id });
          setSelectedNode(id);
        }}
        showAddButton
        showDeleteButton
        workflowData={workflowData}
      >
        {showFullContent ? (
          <>
            <div className="relative flex-1 rounded-lg overflow-hidden border border-indigo-light w-full">
              <img
                alt=""
                className={clsx('h-full w-full object-cover', {
                  'bg-gray-300': !thumbnailData,
                })}
                ref={imgRef}
                src={thumbnailData ?? undefined}
              />
            </div>
            <div className="flex items-center space-x-0.5">
              <ActionsIcon className="text-info -ml-1" />
              <span className="text-sm font-bold">
                {actionDataSize}
                {status === NodeStatusEnum.NotViewed
                  ? ` ${actionDataSize > 1 ? 'actions' : 'action'} to review`
                  : null}
              </span>
            </div>

            {isAdmin ? (
              <div className="absolute right-0 bottom-1">
                <IconButton aria-haspopup="true" onClick={openMenu}>
                  <MoreVertRounded />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  onClose={closeMenu}
                  open={menuOpen}
                >
                  {allowedActions.map((action) => (
                    <MenuItem
                      key={action.action}
                      onClick={(e) => {
                        closeMenu(e);
                        handleAction(action.action);
                      }}
                    >
                      {action.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ) : null}
          </>
        ) : (
          <img
            alt=""
            className={clsx('h-full w-full object-cover', {
              'bg-gray-300': !thumbnailData,
            })}
            ref={imgRef}
            src={thumbnailData ?? undefined}
          />
        )}
      </NodeElement>
      <UpdateImageModal
        nodeId={id}
        onClose={() => {
          setIsImageModalOpen(false);
        }}
        open={isImageModalOpen}
        workflowData={workflowData}
        workflowId={workflowId}
      />
    </>
  );
}

import { InfoOutlined, MoreVert, IconButton } from 'ui-kit';
import React, { useState, useMemo } from 'react';
import { clsx } from 'clsx';
import {
  ActionsEnum,
  type ExecutionVariables,
  NodeTypesEnum,
  type WorkflowAction,
  type WorkflowNode,
} from 'types-shared';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { type ExecutionDocument } from 'api-types-shared';
import { Output } from './RecordOutputs';
import { SourceOutputs } from './SourceOutputs';
import { RequestOutputs } from './RequestOutputs';
import { getActionTitle } from '../utils';

import { isAdmin } from '../../../utils/env';
import { type StepActions } from '../hooks/useWorkflowCurrentStepActions';

const hideTabsNodeTypes = [
  NodeTypesEnum.Source,
  NodeTypesEnum.Request,
  NodeTypesEnum.Freeform,
];

const getActionFlags = (action: WorkflowAction) => {
  const flags: string[] = [];
  for (const [key, value] of Object.entries(action.options ?? {})) {
    if (value === true) {
      flags.push(key);
    }
  }

  return flags;
};

type NodeType = (typeof NodeTypesEnum)[keyof typeof NodeTypesEnum];

export default function WorkflowScreenshotTabs({
  currentNode,
  errorMsg,
  nodeType,
  selectedIndex,
  screenshotUrlsLength,
  currentStepId,
  hasError,
  currentStepActions,
  executionVariables,
  onDownloadLinkData,
  executionArtifacts,
  setMenuProps,
}: {
  currentNode: WorkflowNode | undefined;
  errorMsg: string | undefined;
  nodeType: NodeType;
  selectedIndex: number | undefined;
  screenshotUrlsLength: number;
  currentStepId: string | undefined;
  hasError: boolean;
  currentStepActions: StepActions;
  executionVariables: ExecutionVariables;
  onDownloadLinkData?: (url: string) => void;
  executionArtifacts: ExecutionDocument[];

  setMenuProps: React.Dispatch<
    React.SetStateAction<
      | {
          el: HTMLButtonElement | null;
          actionId: string;
          isScrape: boolean;
        }
      | null
      | undefined
    >
  >;
}) {
  const [selectedTab, setSelectedTab] = useState('actions');

  const handleChange = (event: unknown, newValue: string) => {
    setSelectedTab(newValue);
  };

  const currentStepOutputs = useMemo(() => {
    return currentStepActions
      .filter((a) => Boolean(a.outputs))
      .map((a) => a.outputs);
  }, [currentStepActions]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {currentNode?.type &&
        hideTabsNodeTypes.includes(currentNode.type) ? null : (
          <Tabs
            className="!px-6"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#2196F3',
              },
              '& .Mui-selected': {
                color: '#2196F3 !important',
              },
            }}
            onChange={handleChange}
            value={selectedTab}
          >
            {nodeType !== NodeTypesEnum.Source ? (
              <Tab label="Actions" value="actions" />
            ) : null}
          </Tabs>
        )}
      </Box>

      {/* // TODO: revisit this behaviour later, @neil @manu */}
      {errorMsg &&
      (selectedIndex === screenshotUrlsLength - 1 || selectedIndex === -1) ? (
        <div className="m-4 !mb-0 border border-error flex space-x-2 px-6 py-4 rounded">
          <InfoOutlined className="!w-5 !h-5 !text-error !mt-0.5" />
          <span className="text-red-900 text-sm font-medium">{errorMsg}</span>
        </div>
      ) : null}

      {currentNode?.type === NodeTypesEnum.Source ? (
        <SourceOutputs
          executionVariables={executionVariables}
          node={currentNode}
          onDownloadLinkData={onDownloadLinkData}
          executionArtifacts={executionArtifacts}
        />
      ) : null}

      {currentNode?.type === NodeTypesEnum.Request ? (
        <RequestOutputs
          executionVariables={{
            ...executionVariables,
          }}
          node={currentNode}
        />
      ) : null}

      {currentNode?.type === NodeTypesEnum.Image ? (
        <>
          {selectedTab === 'outputs' ? (
            <div className="flex flex-col space-y-4 p-9 overflow-y-auto">
              {currentStepOutputs.map((output) => {
                if (!output?.description) return null;

                return (
                  <Output
                    action="copy"
                    className="!max-w-[100%]"
                    description={output.description}
                    key={output.title}
                    title={output.title}
                  />
                );
              })}
            </div>
          ) : null}

          {selectedTab === 'actions' ? (
            <div className="flex flex-col space-y-4 p-9 overflow-y-auto">
              {currentStepActions.map(({ action, value, variableName }, i) => {
                const flags = getActionFlags(action);
                const flagStr =
                  isAdmin && flags.length > 0 ? ` (${flags.join(', ')})` : null;
                const showError =
                  hasError && currentStepId && action.id === currentStepId;

                return (
                  <div key={action.id}>
                    <div className="flex items-center space-x-2">
                      <span
                        className={clsx('text-sm font-medium', {
                          'text-warning':
                            action.id === currentStepId && isAdmin,
                        })}
                        key={action.id}
                      >
                        {showError ? (
                          <span className="text-error mr-1">
                            Error <sup>*</sup>
                          </span>
                        ) : null}
                        Action {i + 1} - {getActionTitle(action)}
                        {variableName ? (
                          <span className="text-gray-600">
                            &nbsp;({variableName})
                          </span>
                        ) : null}
                        {flagStr}
                        {showError ? (
                          <sup className="text-error font-bold ml-1">*</sup>
                        ) : null}
                      </span>

                      {isAdmin ? (
                        <IconButton
                          className="!p-0"
                          onClick={(e) => {
                            setMenuProps({
                              el: e.target as HTMLButtonElement,
                              actionId: action.id,
                              isScrape:
                                action.actionType === ActionsEnum.Scrape,
                            });
                          }}
                        >
                          <MoreVert className="!w-5 !h-5 text-black" />
                        </IconButton>
                      ) : null}
                    </div>
                    {isAdmin && typeof value === 'string' ? (
                      <span className="block text-gray-500 break-all text-sm font-normal leading-normal">
                        {value}
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
}

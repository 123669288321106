import {
  ScrapeVariable,
  type VariableBase,
  type ExecutionVariables,
} from 'types-shared';
import {
  Button,
  DataLoader,
  Flex,
  IconButton,
  ContentCopyOutlined,
  DescriptionOutlined,
  CustomDownloadIcon,
} from 'ui-kit';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import type { ExecutionDocument } from 'api-types-shared';
import { useParams } from 'react-router-dom';
import { clsx } from 'clsx';
import { copyToClipboard } from '../../../utils/helper';
import OutputsTable, {
  type OutputItem,
  OutputItemActions,
} from './OutputsTable';
import { v4 as uuid } from 'uuid';

interface Props {
  downloadZippedOutput: () => Promise<void>;
  downloadLinkData: (url: string) => void;
  downloadZippedOutputStatus: 'error' | 'success' | 'pending' | 'idle';
  variables?: ExecutionVariables;
  artifacts: ExecutionDocument[];
  artifactsLoading: boolean;
  fetchExecutionArtifacts: () => void;
  outputDetailsExportButtonEnabled: boolean;
}

export function RecordOutputs({
  artifacts,
  variables,
  downloadZippedOutput,
  downloadZippedOutputStatus,
  downloadLinkData,
  fetchExecutionArtifacts,
  artifactsLoading,
  outputDetailsExportButtonEnabled,
}: Props) {
  const { executionId } = useParams();

  if (!executionId) {
    throw Error('executionId not found!');
  }

  const getFileName = (filePath: string) => {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  };

  const onDownloadLinkData = useCallback(
    (uri: string) => {
      downloadLinkData(uri);
    },
    [downloadLinkData],
  );

  const outputItems: OutputItem[] = useMemo(() => {
    const variablesToReturn = Object.entries(variables ?? {})
      .filter(([, variable]) => ScrapeVariable.safeParse(variable).success)
      .map(([key, value]) => {
        const isStringValue = typeof value === 'string';
        const executionVariable = value as VariableBase;
        const title = isStringValue ? key : executionVariable.name;
        const description = isStringValue
          ? value
          : executionVariable.executionData?.transformedValue ??
            executionVariable.executionData?.initialValue;

        const action: OutputItemActions = OutputItemActions.COPY;

        if (!title || !description) {
          return null;
        }

        const outputItem: OutputItem = {
          id: uuid(),
          action,
          description,
          onDownloadLinkData,
          title,
          type: 'Scrape',
        };

        return outputItem;
      })
      .filter((item): item is OutputItem => item !== null);

    const downloads = artifacts.map((doc) => {
      const { s3Key, uri, mediaType } = doc;

      return {
        id: uuid(),
        action:
          mediaType === 'application/octet-stream'
            ? OutputItemActions.DOWNLOAD
            : OutputItemActions.COPY,
        description: getFileName(s3Key) || s3Key,
        onDownloadLinkData,
        title: s3Key,
        uri,
        type: 'Document',
      } as OutputItem;
    });

    return [...variablesToReturn, ...downloads];
  }, [variables, artifacts, onDownloadLinkData]);

  useEffect(() => {
    fetchExecutionArtifacts();
  }, [fetchExecutionArtifacts]);

  return artifactsLoading ? (
    <DataLoader />
  ) : (
    <div>
      <Flex alignItems="center" className="my-8" justifyContent="space-between">
        <div>
          <h2 className="text-cyan-900 text-2xl font-medium leading-9 tracking-tight">
            Output details
          </h2>
          <span className="text-slate-500 text-sm leading-normal">
            Review, copy or download the record outputs.
          </span>
        </div>

        <div>
          <Button
            className="!border-none"
            color="secondary"
            disabled={
              downloadZippedOutputStatus === 'pending' ||
              artifacts.length === 0 ||
              !outputDetailsExportButtonEnabled
            }
            onClick={downloadZippedOutput}
            variant="outlined"
          >
            <CustomDownloadIcon
              fontSize="small"
              className={clsx({
                'text-gray-350 mr-1':
                  downloadZippedOutputStatus === 'pending' ||
                  artifacts.length === 0 ||
                  !outputDetailsExportButtonEnabled,
                'text-info mr-1': !(
                  downloadZippedOutputStatus === 'pending' ||
                  artifacts.length === 0 ||
                  !outputDetailsExportButtonEnabled
                ),
              })}
            />
            Export
          </Button>
        </div>
      </Flex>

      <OutputsTable outputItems={outputItems} loading={artifactsLoading} />
    </div>
  );
}

interface OutputProps {
  title: string;
  description: string | object;
  onDownloadLinkData?: (url: string) => void;
  action: 'download' | 'copy';
  uri?: string;
  className?: string;
}
export function Output({
  title,
  description,
  action = 'copy',
  uri,
  onDownloadLinkData,
  className,
}: OutputProps) {
  const copy = useCallback(() => {
    const text =
      typeof description === 'string'
        ? description
        : JSON.stringify(description, null, 2);
    copyToClipboard(text);
  }, [description]);

  const downloadFile = useCallback(() => {
    if (uri && onDownloadLinkData) {
      onDownloadLinkData(uri);
    }
  }, [onDownloadLinkData, uri]);

  const descriptionText = useMemo(() => {
    if (typeof description === 'string') {
      const parts = description.split('\n');
      if (parts.length === 1) {
        return parts[0];
      }
      return parts.map((line: string) => (
        <Fragment key={line}>
          {line}
          <br />
        </Fragment>
      ));
    }
    return JSON.stringify(description, null, 2);
  }, [description]);

  const cookiesTitle =
    descriptionText === 'cookies.json'
      ? 'Workflow Execution Cookies'
      : undefined;

  return (
    <div
      className={clsx(
        'px-4 py-2 bg-neutral-50 rounded-lg border border-slate-300 flex items-start gap-2 max-w-[60%]',
        className,
      )}
    >
      {action === 'download' ? (
        <DescriptionOutlined color="action" fontSize="small" />
      ) : null}
      <div className="flex flex-col mr-6">
        <div className="text-gray-500 text-xs leading-3 tracking-tight break-all">
          {cookiesTitle ?? title}
        </div>
        <div className="text-cyan-900 text-base leading-normal tracking-tight break-all whitespace-pre-wrap font-mono">
          {descriptionText}
        </div>
      </div>
      <IconButton
        className="mt-2"
        onClick={action === 'download' ? downloadFile : copy}
      >
        {action === 'download' ? (
          <CustomDownloadIcon color="secondary" fontSize="small" />
        ) : (
          <ContentCopyOutlined color="secondary" fontSize="small" />
        )}
      </IconButton>
    </div>
  );
}

import type { ChipProps } from '@mui/material/Chip';
import { ExecutionStatusEnum } from 'types-shared';
import { Chip } from 'ui-kit';
import { isAdmin } from '../../../../utils/env';

const getStatusStyles = (status: ExecutionStatusEnum) => {
  switch (status) {
    case ExecutionStatusEnum.Success:
      return {
        backgroundColor: '#e7f8ea',
        color: '#00590f',
      };

    case ExecutionStatusEnum.PendingSystem:
    case ExecutionStatusEnum.PendingUser:
      return isAdmin
        ? {
            backgroundColor: '#e2ecff',
            color: '#033bb9',
          }
        : {
            backgroundColor: '#0f3d61',
            color: '#ffffff',
          };
    case ExecutionStatusEnum.Canceled:
    case ExecutionStatusEnum.Failed:
    case ExecutionStatusEnum.Terminated:
    case ExecutionStatusEnum.Timeout:
      return {
        backgroundColor: '#fdeced',
        color: '#5f2120',
      };
    case ExecutionStatusEnum.Running:
      return {
        backgroundColor: '#0f3d61',
        color: '#ffffff',
      };

    case ExecutionStatusEnum.Queued:
    case ExecutionStatusEnum.Paused:
    case ExecutionStatusEnum.Retry:
      return {
        backgroundColor: '#eaf1f3',
        color: '#0f3d61',
      };
    default:
      return {};
  }
};

interface ExtendedProps {
  status: ExecutionStatusEnum;
}

export default function ExecutionChip({
  label,
  status,
  ...props
}: ChipProps & ExtendedProps) {
  const statusStyles = getStatusStyles(status);

  return (
    <Chip
      {...props}
      label={label}
      statusStyles={{ ...statusStyles, borderRadius: '4px' }}
      className="font-medium text-sm"
      size="small"
    />
  );
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  downloadLinkData,
  useFeatureFlag,
  createVariableMap,
} from '../../utils/helper';
import {
  allowedUserExecutionStatuses,
  executionSignalsConfig,
  FeatureFlag,
  notAllowedExecutionSignalStatuses,
  terminalStatuses,
} from '../../utils/constants';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import { useGetRefData, useGetWorkflowData } from '../Editor/hooks';
import { contactModalEventChannel } from '../../utils/contactModal';
import { useGetGlobalVariables } from '../GlobalVariables/hooks.gql';
import uniqBy from 'lodash/uniqBy';
import truncate from 'lodash/truncate';
import { isUUID } from '../Editor/utils/helper';
import {
  HelpOutlineOutlined,
  Button,
  DataLoader,
  Flex,
  IconButton,
  Input,
  Tooltip,
} from 'ui-kit';
import { SignalTypeEnum } from 'api-types-shared';
import {
  DocumentVariable,
  ExecutionTriggerEnum,
  GlobalVariable,
  QueryVariable,
  ScrapeVariable,
  SourceVariable,
  VariableTypeEnum,
  WorkflowSourceNode,
  type Variable,
} from 'types-shared';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { isAdmin } from '../../utils/env';
import { Toolbar } from '../../components/Toolbar';
import Box from '@mui/material/Box';
import { RecordOutputs } from './components/RecordOutputs';
import { NoDataFound } from '../../components/NoDataFound';
import { Select } from './components/Select';
import {
  useFetchExecutionDetail,
  useFetchExecutionScreenshots,
  useDownloadExecutionData,
  useSendExecutionSignal,
  useUpdateExecution,
  useDeleteExecutionScreenshot,
  useWorkflowScreenshotUrls,
} from './hooks';
import { RunOverview } from './components/RunOverview';
import { LiveView } from './components/LiveView';
import BranchDecider from './components/BranchDecider';
import ExecutionDetailHeader from './components/ExecutionDetailHeader';
import { type ScreenshotUrl } from './utils';
import pick from 'lodash/pick';
import { RecordInputVariables } from './components/RecordInputVariables';
import { useFeatureFlagPayload } from 'posthog-js/react';

const defaultSignalForm = {
  variable: undefined,
  value: '',
};

export default function Execution() {
  const { executionId } = useParams();
  const navigate = useNavigate();

  const [stopPolling, setStopPolling] = useState(false);
  const [executionImageUrls, setExecutionImageUrls] = useState<string[]>([]);
  const [downloadingExecution, setDownloadingExecution] =
    useState<boolean>(false);
  const [imageArray, setImageArray] = useState<[string, string][]>([]);
  const [selectedTab, setSelectedTab] = useState<
    'old' | 'overview' | 'outputs' | 'inputs'
  >('overview');
  const [signalForm, setSignalForm] = useState<{
    variable: Variable | undefined;
    value: string;
  }>(defaultSignalForm);

  if (!executionId) {
    throw Error('executionId not found!');
  }

  const liveExecutionViewEnabled = useFeatureFlag(
    FeatureFlag.LiveExecutionView,
  );
  const executionDetailsEnabled = useFeatureFlag(FeatureFlag.ExecutionDetails);
  let executionScreenshotDetailsEnabled = useFeatureFlag(
    FeatureFlag.ExecutionScreenshotDetails,
  );
  const showAllNodeLogs = useFeatureFlag(FeatureFlag.ShowAllNodeLogs);
  const outputDetailsExportButtonEnabled = useFeatureFlag(
    FeatureFlag.OutputDetailsExportButton,
  );
  const demoFeatureEnabled = useFeatureFlag(FeatureFlag.DemoFeatures);

  const {
    data: executionDetail,
    isLoading: executionDetailLoading,
    refetch: refetchExecutionDetails,
    error,
  } = useFetchExecutionDetail(
    executionId,
    stopPolling && selectedTab !== 'outputs',
  );
  const imageUrls = useMemo(
    () => executionDetail?.imageUrls ?? [],
    [executionDetail?.imageUrls],
  );
  const {
    data: executionImages = [],
    isLoading: executionScreenshotsLoading,
    refetch: refetchExecutionImages,
  } = useFetchExecutionScreenshots(
    executionId,
    imageUrls.slice(executionImageUrls.length),
    stopPolling,
  );
  const variables = useMemo(
    () => executionDetail?.variableData ?? {},
    [executionDetail?.variableData],
  );

  const {
    mutateAsync: getExecutionZippedFile,
    status: downloadExecutionDataStatus,
  } = useDownloadExecutionData();
  const { mutate: sendExecutionSignal, status: signalStatus } =
    useSendExecutionSignal();
  const isSignalLoading = signalStatus === 'pending';

  const { mutateAsync: updateExecution, status: updateExecutionStatus } =
    useUpdateExecution();
  const { mutateAsync: deleteScreenshot } = useDeleteExecutionScreenshot();
  const { data: workflowMetadata, isLoading } = useFetchWorkflowMetadata(
    executionDetail?.metadata.workflowId ?? '',
  );
  const workflowId = executionDetail?.metadata.workflowId ?? '';

  // TODO(rafic): Remove this once we have execution details for readonly (desktop) workflows
  const readOnlyWorkflowsPayload = useFeatureFlagPayload(
    FeatureFlag.ReadonlyWorkflows,
  ) as { readOnlyWorkflows?: string[] } | undefined;
  const isReadonlyView = useMemo(
    () => readOnlyWorkflowsPayload?.readOnlyWorkflows?.includes(workflowId),
    [readOnlyWorkflowsPayload, workflowId],
  );
  executionScreenshotDetailsEnabled =
    executionScreenshotDetailsEnabled && !isReadonlyView;

  const workflowVersionId = executionDetail?.metadata.workflowVersionId;
  const { data: workflowData, isLoading: isLoadingWorkflowData } =
    useGetWorkflowData(
      workflowId,
      false,
      Boolean(workflowId && workflowVersionId),
      workflowVersionId,
    );
  const nodes = useMemo(() => workflowData?.nodes ?? [], [workflowData?.nodes]);
  const completedSteps = useMemo(
    () => executionDetail?.metadata.completedSteps ?? [],
    [executionDetail?.metadata.completedSteps],
  );
  const currentStep = executionDetail?.metadata.currentStep;
  const { data: refData, isLoading: isLoadingRefData } = useGetRefData(
    workflowId,
    !workflowId || !workflowVersionId,
    workflowVersionId,
    'execution-key',
  );

  const isAPITrigger =
    executionDetail?.metadata.trigger === ExecutionTriggerEnum.API;

  const sourceNode = useMemo(() => {
    return nodes.find((n) => WorkflowSourceNode.safeParse(n).success) as
      | WorkflowSourceNode
      | undefined;
  }, [nodes]);

  const sourceVariable = useMemo(() => {
    if (!sourceNode) {
      return undefined;
    }
    const { variableId } = sourceNode.data;
    return SourceVariable.parse(variables[variableId]);
  }, [sourceNode, variables]);

  const screenshotUrls = useWorkflowScreenshotUrls({
    nodes,
    sourceNode,
    sourceVariable,
    completedSteps,
    showAllNodeLogs: Boolean(showAllNodeLogs) || isAdmin,
    currentStep,
    imageArray,
  });

  const runId = useMemo(() => {
    let _runId = '';

    if (executionDetail) {
      const { setId } = executionDetail.metadata;
      _runId = truncate(setId, { length: 5 });
    }

    return _runId;
  }, [executionDetail]);

  const downloadZippedOutput = async () => {
    const link = await getExecutionZippedFile([executionId]);
    downloadLinkData(link);
  };

  const onTabChange = (
    _event: React.SyntheticEvent,
    newValue: 'overview' | 'outputs' | 'inputs',
  ) => {
    setSelectedTab(newValue);
  };

  const onSignalClick =
    (signalType: SignalTypeEnum, payload = {}) =>
    () => {
      sendExecutionSignal({
        executionId,
        signalType,
        payload,
      });
    };

  const onDeleteScreenshot = async (
    id: string,
    isImage: boolean,
    item?: ScreenshotUrl,
  ) => {
    const { timestamp } = item?.sortData ?? {};
    const isCurrentStep = currentStep?.nodeId === id;
    const isCompletedStep = executionDetail?.metadata.completedSteps?.some(
      (step) => step.timestamp === timestamp && step.nodeId === id,
    );
    const newMetadata = pick(executionDetail?.metadata, [
      'currentStep',
      'completedSteps',
    ]);
    if (!isImage) {
      if (isCompletedStep) {
        newMetadata.completedSteps =
          executionDetail?.metadata.completedSteps?.filter(
            (step) => step.timestamp !== timestamp || step.nodeId !== id,
          );
      } else if (isCurrentStep) {
        newMetadata.currentStep = undefined;
      }
    }
    await deleteScreenshot({
      executionId,
      screenshotId: decodeURIComponent(id),
      ...(!isImage ? { metadata: newMetadata } : {}),
    });
    await refetchExecutionDetails();
    if (isImage) {
      setExecutionImageUrls([]);
      setImageArray([]);
    }
  };

  const handleUpdateExecution = useCallback(
    async (newStatusDesc: string) => {
      const newStatus = {
        cause: {
          failure: {
            message: newStatusDesc,
          },
        },
      };
      if (executionDetail?.metadata.executionId) {
        await updateExecution({
          id: executionDetail.metadata.executionId,
          statusDescr: JSON.stringify(newStatus),
        });
      }
    },
    [updateExecution, executionDetail?.metadata.executionId],
  );

  const { data: globalVariablesRaw } = useGetGlobalVariables();
  const globalVariables = useMemo(() => {
    if (!globalVariablesRaw?.length) return {};

    const varResult = globalVariablesRaw
      .map((gVar) => {
        const parsed = GlobalVariable.safeParse({
          id: gVar.id,
          name: gVar.name,
          type: VariableTypeEnum.Global,
          data: [gVar.value],
        });

        if (parsed.success) {
          return parsed.data;
        }
        return undefined;
      })
      .filter((v): v is GlobalVariable => Boolean(v));

    return createVariableMap(varResult);
  }, [globalVariablesRaw]);

  const executionInFinalState = useMemo(
    () =>
      executionDetail
        ? notAllowedExecutionSignalStatuses.includes(
            executionDetail.metadata.status,
          )
        : false,
    [executionDetail],
  );

  useEffect(() => {
    if (executionInFinalState && !stopPolling) {
      setStopPolling(true);
    }
  }, [executionInFinalState, stopPolling]);

  useEffect(() => {
    if (imageUrls.length > executionImageUrls.length) {
      void refetchExecutionImages();
      setExecutionImageUrls(imageUrls);
    }
  }, [executionImageUrls.length, imageUrls, refetchExecutionImages]);

  useEffect(() => {
    if (executionImages.length > 0) {
      setImageArray((blobs) => {
        const newBlobs = [...blobs, ...executionImages];
        return uniqBy(newBlobs, ([url]) => url);
      });
    }
  }, [executionImages]);

  useEffect(() => {
    if (
      !isUUID(executionId) ||
      (error as Error | undefined)?.message.includes('404')
    ) {
      navigate(workflowId ? `/workflows/${workflowId}` : '/');
    }
  }, [error, executionId, navigate, workflowId]);

  useEffect(() => {
    if (
      !isAdmin &&
      executionDetailsEnabled === false &&
      executionDetail?.metadata &&
      !allowedUserExecutionStatuses.includes(executionDetail.metadata.status)
    ) {
      navigate(`/workflows/${executionDetail.metadata.workflowId}`);
    }
  }, [executionDetail?.metadata, executionDetailsEnabled, navigate]);

  useEffect(() => {
    if (!isAdmin && executionDetail?.metadata.adminRun) {
      navigate(`/workflows/${executionDetail.metadata.workflowId}`);
    }
  }, [executionDetail?.metadata, navigate]);

  return (
    <>
      {Boolean(isLoading) ||
      Boolean(executionDetailLoading) ||
      Boolean(isLoadingWorkflowData) ||
      Boolean(isLoadingRefData) ? (
        <DataLoader />
      ) : (
        <div>
          <Flex
            alignItems="center"
            className="flex-1 pr-8"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Toolbar
              onBack={() => {
                if (executionDetail?.metadata) {
                  navigate(
                    `/workflows/${executionDetail.metadata.workflowId}`,
                    {
                      replace: true,
                    },
                  );
                }
              }}
              pageInfo={
                <>
                  <span className="text-xs text-info-dark">
                    Execution {runId}
                  </span>
                  <span className="text-xs">
                    &nbsp;/&nbsp;Execution details
                  </span>
                </>
              }
              title={workflowMetadata?.workflowName ?? 'Execution Details'}
            />
            <div className="flex items-center space-x-2">
              {isAdmin
                ? executionSignalsConfig.map(
                    ({ Icon, signal, tooltipText }) => {
                      const disabled =
                        Boolean(isSignalLoading) ||
                        Boolean(executionInFinalState);

                      return (
                        <Tooltip
                          title={
                            executionInFinalState
                              ? "Can't send signals to execution in Queue, Failed, Terminated, or Success"
                              : tooltipText
                          }
                          key={signal}
                        >
                          <span>
                            <IconButton
                              className={disabled ? 'opacity-50' : ''}
                              onClick={onSignalClick(signal)}
                              disabled={disabled}
                            >
                              <Icon className="!w-5 !h-5 !text-black hover:!text-info" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      );
                    },
                  )
                : null}
              {demoFeatureEnabled ? (
                <Button
                  color="secondary"
                  loading={downloadingExecution}
                  onClick={() => {
                    setDownloadingExecution(true);
                    setTimeout(() => {
                      setDownloadingExecution(false);
                    }, 10000);
                  }}
                  variant="contained"
                >
                  Download Video
                </Button>
              ) : null}
              <IconButton
                className="order-5 ml-2 rounded-lg group"
                onClick={() => {
                  contactModalEventChannel.emit('open', {
                    workflowId,
                    workflowName: workflowMetadata?.workflowName,
                  });
                }}
              >
                <HelpOutlineOutlined className="!w-5 !h-5 text-info" />
              </IconButton>
            </div>
          </Flex>

          <div className="pt-12 pb-8 px-8 overflow-y-auto relative flex-grow h-[calc(100vh_-_92px)] flex flex-col">
            {isAdmin ? (
              <>
                <div className="flex justify-end items-stretch space-x-2">
                  <Select
                    allowAddVariable={false}
                    className="!min-w-48 !mt-2"
                    data={signalForm.variable ? [signalForm.variable] : []}
                    filterVariables={false}
                    label="Variable"
                    onChange={(data) => {
                      setSignalForm((form) => ({
                        ...form,
                        variable: data[0] as Variable,
                      }));
                    }}
                    options={[]}
                    globalVariablesMap={globalVariables}
                    variablesMap={Object.fromEntries(
                      Object.entries(refData?.variableData ?? {}).filter(
                        ([, variable]) =>
                          QueryVariable.safeParse(variable).success ||
                          ScrapeVariable.safeParse(variable).success ||
                          DocumentVariable.safeParse(variable).success,
                      ),
                    )}
                  />
                  <Input
                    label="Value"
                    floatingLabel
                    value={signalForm.value}
                    onChange={(value: string) => {
                      setSignalForm((form) => ({
                        ...form,
                        value,
                      }));
                    }}
                  />
                  <Button
                    className="!mt-2"
                    color="secondary"
                    disabled={
                      !signalForm.variable ||
                      !signalForm.value ||
                      isSignalLoading
                    }
                    onClick={() => {
                      onSignalClick(SignalTypeEnum.SetVariable, {
                        variableId: signalForm.variable?.id,
                        value: signalForm.value,
                      })();
                      setSignalForm(defaultSignalForm);
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>

                {executionDetail && workflowData ? (
                  <BranchDecider
                    nodes={workflowData.nodes}
                    edges={workflowData.edges}
                    onResume={onSignalClick}
                    signalNotAllowed={executionInFinalState}
                    isLoading={isSignalLoading}
                    executionUpdateStatus={signalStatus}
                  />
                ) : null}
              </>
            ) : null}
            {executionDetail ? (
              <>
                <ExecutionDetailHeader
                  executionDetail={executionDetail}
                  executionId={executionId}
                />

                {!executionScreenshotsLoading &&
                screenshotUrls.length === 0 &&
                executionDetail.artifacts.length === 0 ? (
                  <NoDataFound
                    heading="Execution Details"
                    subHeading="Something went wrong. This execution does not have any data."
                    showIcon={false}
                  />
                ) : (
                  <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs
                        onChange={onTabChange}
                        sx={{
                          '& .MuiTabs-indicator': {
                            backgroundColor: '#2196F3',
                          },
                          '& .Mui-selected': {
                            color: '#2196F3 !important',
                          },
                        }}
                        value={selectedTab}
                      >
                        <Tab label="Overview" value="overview" />
                        <Tab label="Inputs" value="inputs" />
                        <Tab label="Outputs" value="outputs" />
                      </Tabs>
                    </Box>
                    {selectedTab === 'overview' &&
                      (screenshotUrls.length !== 0 ? (
                        <RunOverview
                          updateExecutionStatus={updateExecutionStatus}
                          updateExecution={handleUpdateExecution}
                          executionDetail={executionDetail}
                          executionScreenshotDetailsEnabled={Boolean(
                            executionScreenshotDetailsEnabled,
                          )}
                          liveExecutionViewEnabled={liveExecutionViewEnabled}
                          screenshotUrls={screenshotUrls}
                          isAPITrigger={isAPITrigger}
                          loading={executionScreenshotsLoading}
                          nodes={workflowData?.nodes}
                          onSignalClick={
                            isAdmin
                              ? (signal, payload) => {
                                  onSignalClick(signal, payload)();
                                }
                              : undefined
                          }
                          showAllNodeLogs={Boolean(showAllNodeLogs)}
                          showLive={Boolean(
                            executionDetailsEnabled &&
                              executionDetail.metadata.sessionId,
                          )}
                          status={executionDetail.metadata.status}
                          targetData={refData?.targetData}
                          variablesData={executionDetail.variableData}
                          workflowData={workflowData ?? undefined}
                          workflowName={workflowMetadata?.workflowName}
                          workflowId={workflowMetadata?.workflowId}
                          executionVariables={variables}
                          onDownloadLinkData={downloadLinkData}
                          hasSuggestions={false}
                          onDeleteScreenshot={onDeleteScreenshot}
                        >
                          {liveExecutionViewEnabled &&
                          executionDetail.metadata.sessionId &&
                          !terminalStatuses.includes(
                            executionDetail.metadata.status,
                          ) ? (
                            <div className="!h-[70vh] w-full">
                              <LiveView
                                sessionId={executionDetail.metadata.sessionId}
                                userId={executionDetail.metadata.userId}
                              />
                            </div>
                          ) : null}
                        </RunOverview>
                      ) : (
                        <NoDataFound
                          heading="Execution Screenshots"
                          subHeading="This execution does not have any screenshots."
                          showIcon={false}
                        />
                      ))}

                    {selectedTab === 'outputs' ? (
                      <RecordOutputs
                        artifacts={executionDetail.artifacts}
                        artifactsLoading={executionDetailLoading}
                        downloadLinkData={downloadLinkData}
                        downloadZippedOutput={downloadZippedOutput}
                        downloadZippedOutputStatus={downloadExecutionDataStatus}
                        fetchExecutionArtifacts={refetchExecutionDetails}
                        variables={variables}
                        outputDetailsExportButtonEnabled={Boolean(
                          outputDetailsExportButtonEnabled,
                        )}
                      />
                    ) : null}

                    {selectedTab === 'inputs' ? (
                      <RecordInputVariables
                        artifacts={executionDetail.artifacts}
                        artifactsLoading={executionDetailLoading}
                        downloadLinkData={downloadLinkData}
                        downloadZippedOutput={downloadZippedOutput}
                        downloadZippedOutputStatus={downloadExecutionDataStatus}
                        fetchExecutionArtifacts={refetchExecutionDetails}
                        executionVariables={variables}
                        variableMetadata={executionDetail.metadata.variableData}
                        outputDetailsExportButtonEnabled={Boolean(
                          outputDetailsExportButtonEnabled,
                        )}
                      />
                    ) : null}
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

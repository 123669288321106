import { useMemo } from 'react';
import { isAdmin } from '../../../utils/env';
import { ExecutionTriggerEnum } from 'types-shared';
import dayjs from '../../../config/dayjs';
import { type GetExecutionResponse } from 'api-types-shared';
import { Flex, CustomTypography } from 'ui-kit';
import { getStatusColor, titleCase } from '../../../utils/helper';
import {
  executionStatusTitleMapping,
  triggerTitleMapping,
} from '../../../utils/constants';
import { useGetTemporalUiAddress } from '../hooks';
import ExecutionChip from '../../Workflows/components/Chips/ExecutionChip';
import { capitalizeFirstLetter } from '../../Workflows/utils/helper';

const getTitle = ({ metadata }: GetExecutionResponse) => {
  const { trigger, createdAt } = metadata;
  const executionDate = dayjs(new Date(createdAt)).format('MM/DD/YY -  h:mm A');
  switch (trigger) {
    case ExecutionTriggerEnum.API:
      return `API Execution: ${executionDate}`;
    case ExecutionTriggerEnum.Email:
      return `Email: ${executionDate}`;
    default:
      return `Manual Execution: ${executionDate}`;
  }
};

export default function ExecutionDetailHeader({
  executionDetail,
  executionId,
}: {
  executionDetail: GetExecutionResponse;
  executionId: string;
}) {
  const { temporalBaseUrl, loading } = useGetTemporalUiAddress(
    executionDetail.metadata.userId,
  );

  const { statusTitle, executionDate, triggerType, datasource } =
    useMemo(() => {
      const summaryData = {
        statusTitle: '',
        executionDate: '',
        triggerType: '' as ExecutionTriggerEnum,
        datasource: '-',
      };

      const { trigger, status: _status, createdAt } = executionDetail.metadata;

      const formattedStatus = _status.toLowerCase().replace('_', ' ');
      summaryData.statusTitle = isAdmin
        ? formattedStatus
        : executionStatusTitleMapping[_status] ?? formattedStatus;
      summaryData.executionDate = dayjs(new Date(createdAt)).format(
        'MM/DD/YY -  h:mm A',
      );

      if (summaryData.statusTitle === 'retry') {
        summaryData.statusTitle = 'Waiting to retry';
      }
      summaryData.triggerType = trigger;
      return summaryData;
    }, [executionDetail]);

  return (
    <>
      <div className="flex items-center space-x-6 !mb-4">
        <CustomTypography className="!font-bold text-info-dark" variant="h4">
          {getTitle(executionDetail)}
        </CustomTypography>
        {isAdmin ? (
          <>
            {(() => {
              if (loading) {
                return <span>Loading...</span>;
              } else if (!temporalBaseUrl) {
                return (
                  <span className="text-red-500">
                    Error: Temporal URL not available.
                  </span>
                );
              }

              return (
                <a
                  className="px-2 py-1 border border-gray-800 rounded"
                  target="_blank"
                  rel="noreferrer"
                  href={`${temporalBaseUrl}/${executionId}`}
                >
                  View on temporal
                </a>
              );
            })()}
          </>
        ) : null}
      </div>

      <Flex
        className="gap-14 rounded-lg border border-indigo-light px-8 pb-10 pt-9 mb-8"
        flexDirection="row"
      >
        <div>
          <p className="text-color-grey text-base mb-1.5">Execution time</p>
          <p className="text-info-dark text-base">{executionDate}</p>
        </div>
        {triggerType !== ExecutionTriggerEnum.Email ? (
          <div>
            <p className="text-color-grey text-base mb-1.5">Datasource</p>
            <p className="text-info-dark text-base">{datasource}</p>
          </div>
        ) : null}
        <div>
          <p className="text-color-grey text-base mb-1.5">Trigger</p>
          <p className="text-info-dark text-base capitalize">
            {triggerTitleMapping[triggerType] ?? titleCase(triggerType)}
          </p>
        </div>
        <div>
          <p className="text-color-grey text-base mb-1.5">Attempts</p>
          <p className="text-info-dark text-base capitalize">
            {executionDetail.metadata.attempt}
          </p>
        </div>
        <div>
          <p className="text-color-grey text-base mb-1.5">Status</p>
          <ExecutionChip
            className="!h-6"
            color={getStatusColor(executionDetail.metadata.status)}
            label={
              <span className="normal-case">
                {capitalizeFirstLetter(statusTitle)}
              </span>
            }
            status={executionDetail.metadata.status}
            sx={{
              '& .MuiChip-label': { textTransform: 'capitalize' },
            }}
            variant="filled"
          />
        </div>
      </Flex>
    </>
  );
}

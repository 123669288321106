import NavigationItem from './NavigationItem';
import { Profile, Tooltip, Logo } from 'ui-kit';
import { useMemo } from 'react';
import { MOCK_USER_ID } from 'types-shared';
import { useTeamDetails } from '../../hooks/useTeamDetails.gql';
import { FeatureFlag } from '../../utils/constants';
import { isAdmin } from '../../utils/env';
import { useAuth, useFeatureFlag } from '../../utils/helper';

export function NavigationPanel() {
  const { user } = useAuth();
  const userID = user?.sub ?? (isAdmin ? MOCK_USER_ID : undefined);
  const { data: teamDetails } = useTeamDetails(userID);
  const integrationsPageEnabled = useFeatureFlag(
    FeatureFlag.IntegrationsPage,
    false,
  );
  const apiKeysManagerPageEnabled = useFeatureFlag(FeatureFlag.ApiKeysManager);
  const extensionPageRedirectEnabled = useFeatureFlag(
    FeatureFlag.ExtensionPageRedirect,
  );
  const globalVariablesEnabled = useFeatureFlag(FeatureFlag.GlobalVariables);
  const insightsPageEnabled = useFeatureFlag(FeatureFlag.InsightsPage);

  const menuItems = useMemo(
    () => [
      { text: 'Workflows', to: '/', disabled: false, hide: false },
      {
        text: 'Insights',
        to: '/insights',
        disabled: !insightsPageEnabled,
        tooltip:
          'Monitor operational performance and access valuable insights for informed decision-making. Contact sales to learn more!',
      },
      {
        text: 'Members',
        to: '/members',
        disabled: false,
        hide: !teamDetails?.isAdmin || isAdmin,
      },
      {
        text: 'Integrations',
        to: '/integrations',
        disabled: !integrationsPageEnabled,
        tooltip:
          'Configure external platforms and connect them with your workflows. Contact sales to learn more!',
        hide: isAdmin,
      },
      {
        text: 'Shared Variables',
        to: '/global-variables',
        disabled: !globalVariablesEnabled,
        tooltip:
          'Use shared variables across your team’s workflows. Contact sales to learn more!',
        hide: false,
      },
      {
        text: 'API Keys',
        to: '/api-keys-manager',
        disabled: !apiKeysManagerPageEnabled,
        tooltip:
          'As soon as your setup is complete, this feature will be available to you!',
        hide: isAdmin,
      },
    ],
    [
      insightsPageEnabled,
      integrationsPageEnabled,
      apiKeysManagerPageEnabled,
      globalVariablesEnabled,
      teamDetails,
    ],
  );

  const menuItemsBottom = useMemo(
    () => [
      {
        text: 'Extension',
        to: extensionPageRedirectEnabled
          ? 'https://chromewebstore.google.com/detail/sola-rpa/acmmogacpkfigcjmeahglkijobjpljgh'
          : '/extension',
        disabled: false,
        target: extensionPageRedirectEnabled ? '_blank' : undefined,
      },
    ],
    [extensionPageRedirectEnabled],
  );

  return (
    <div className="py-4 min-w-56 xl:min-w-72 border-r-2 border-primary-blue-light flex flex-col">
      <div className="px-6 pt-2.5 h-14 mb-14">
        <Logo className="!w-7 !h-7" />
      </div>

      <div className="flex flex-col justify-between h-full">
        <ul className="px-4">
          {menuItems.map((menuItem) => {
            const { to, tooltip, disabled, text, hide } = menuItem;

            if (hide) {
              return null;
            }

            const navigationItem = (
              <NavigationItem
                className="mb-1"
                disabled={disabled}
                href={to}
                key={to}
                text={text}
              />
            );

            return tooltip && disabled ? (
              <Tooltip arrow key={to} placement="right" title={tooltip}>
                {navigationItem}
              </Tooltip>
            ) : (
              navigationItem
            );
          })}
        </ul>

        {!isAdmin ? (
          <div>
            <ul className="px-4 py-2">
              {menuItemsBottom.map((menuItem) => (
                <NavigationItem
                  className="mb-1"
                  disabled={menuItem.disabled}
                  href={menuItem.to}
                  key={menuItem.to}
                  text={menuItem.text}
                  target={menuItem.target}
                />
              ))}
            </ul>

            <div className="border-t px-4 py-2">
              <Profile teamDetails={teamDetails} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

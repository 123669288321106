import { useNavigate } from 'react-router-dom';
import { useGetRefData } from '../Editor/hooks';
import { useEffect, useMemo, useState } from 'react';
import {
  parseTemplateString,
  ParseVariableMode,
  RunnableWorkflowState,
  CommitWorkflowState,
} from 'types-shared/workflowTypes';
import { AlertVariant, Button, Input, notify, Modal } from 'ui-kit';
import { useRunTestExecution } from './hooks';
import { EditorStore } from '../Editor/store/EditorState';
import { LoadingManualRun } from './components/LoadingManualRun';
import { VariableString } from 'types-shared';
import isEmpty from 'lodash/isEmpty';
import { handleException } from 'sentry-browser-shared';
import { getFilteredVariables } from '../../utils/helper';
import values from 'lodash/values';
import { FeatureFlag } from '../../utils/constants';
import { useFeatureFlagPayload } from 'posthog-js/react';

interface ErrorWithResponse {
  response: Response;
  message?: string;
}

interface Props {
  workflowId: string;
  onClose: () => void;
}

export default function ManualRun({ workflowId, onClose }: Props) {
  const editorState = EditorStore();
  const { nodes, variables: variablesMap } = editorState;
  const navigate = useNavigate();

  const [variableState, setVariableState] = useState<Record<string, string>>(
    {},
  );

  if (!workflowId) {
    throw new Error('workflowId not found!');
  }

  const readOnlyWorkflowsPayload = useFeatureFlagPayload(
    FeatureFlag.ReadonlyWorkflows,
  ) as { readOnlyWorkflows?: string[] } | undefined;
  const isDesktopWorkflow = useMemo(
    () =>
      readOnlyWorkflowsPayload?.readOnlyWorkflows?.includes(workflowId) ??
      false,
    [readOnlyWorkflowsPayload, workflowId],
  );

  const { mutateAsync: runTestExecution, isPending: isPendingTest } =
    useRunTestExecution(isDesktopWorkflow);

  const { data: nodeViewData, isFetching: isFetchingNodeViewData } =
    useGetRefData(workflowId, false, undefined, 'refetch-var-map');

  const variables = useMemo(() => {
    if (!nodeViewData?.variableData) {
      return [];
    }

    return values(
      getFilteredVariables(nodeViewData.variableData, nodes, variablesMap),
    );
  }, [nodeViewData?.variableData, nodes, variablesMap]);

  const processSubmissionError = (throwDefaultError: () => void) => {
    const committedWorkflowState = CommitWorkflowState.safeParse(editorState);
    if (!committedWorkflowState.success) {
      throwDefaultError();
    } else {
      const runnableWorkflowState = RunnableWorkflowState.safeParse(
        committedWorkflowState.data,
      );

      const isSuccess = runnableWorkflowState.success;
      if (isSuccess) {
        throwDefaultError();
      } else {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const errorMessage = JSON.parse(
            runnableWorkflowState.error.message,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          )[0];
          if (errorMessage) {
            notify({
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              message: JSON.parse(runnableWorkflowState.error.message)[0]
                .message as string,
              variant: AlertVariant.INFO,
              timeoutInMs: 5 * 1000, // 5 seconds
            });
          }
        } catch (error) {
          throwDefaultError();
          handleException(error, {
            name: 'Manual run submission error',
            source: 'ManualRun/processSubmissionError',
            extra: {
              committedWorkflowState,
              runnableWorkflowState,
            },
          });
        }
      }
    }
  };

  const handleSubmit = () => {
    runTestExecution({
      params: { workflowId },
      body: { variableData: variableState },
    })
      .then(() => {
        notify({
          variant: AlertVariant.SUCCESS,
          message: 'Workflow execution started.',
        });
        navigate(`/workflows/${workflowId}`);
      })
      .catch(async (e: unknown) => {
        handleException(e, {
          name: 'Manual workflow execution error',
          source: 'ManualRun/handleSubmit',
          extra: {
            workflowId,
            variableState,
          },
        });
        let errVariant = AlertVariant.ERROR;
        const err = e as ErrorWithResponse;

        let errorMessage = err.message ?? 'Failed to execute workflow';
        const statusCode = err.response.status;
        const errText = await err.response.text();

        if (errText && statusCode && statusCode >= 400 && statusCode < 500) {
          if (errText.includes('is not in a valid state to be queued')) {
            errVariant = AlertVariant.WARNING;
            errorMessage = 'Workflow needs review';
          }
        }

        processSubmissionError(() => {
          notify({
            message: errorMessage,
            variant: errVariant,
          });
        });
      })
      .finally(() => {
        onClose();
      });
  };

  useEffect(() => {
    if (isEmpty(variableState) && variables.length > 0) {
      setVariableState(
        variables.reduce((acc, variable) => {
          const parsedId = VariableString.parse(
            parseTemplateString({
              data: variable.data.query,
              variableMap: {},
              mode: ParseVariableMode.Dashboard,
              handleException,
            }),
          );
          return {
            ...acc,
            [parsedId]: '',
          };
        }, {}),
      );
    }
  }, [variables, variableState]);

  return (
    <Modal
      className="max-h-[80vh] !max-w-[42rem]"
      containerClassName="flex flex-col space-y-6"
      open
      onClose={onClose}
    >
      <div className="text-info-dark text-2xl font-medium leading-9 tracking-tight">
        Execute a workflow test
      </div>
      <span className="text-base text-gray-700">
        {variables.length > 0
          ? 'Enter values for variables in the workflow and execute a test.'
          : 'Once you add API variables to your workflow, you can enter values in this section to test API requests with different input data.'}
      </span>

      {variables.length ? (
        <span className="text-info-dark text-lg font-medium">
          Workflow variables
        </span>
      ) : null}

      {isFetchingNodeViewData && variables.length === 0 ? (
        <LoadingManualRun />
      ) : null}
      {variables.length > 0 ? (
        <div className="flex flex-col !mt-4 gap-y-4 pr-3 overflow-y-auto dark-scrollbar">
          {variables.map((v) => {
            const parsedId = VariableString.parse(
              parseTemplateString({
                data: v.data.query,
                variableMap: {},
                mode: ParseVariableMode.Dashboard,
                handleException,
              }),
            );

            return (
              <Input
                disabled={isPendingTest}
                floatingLabel
                id={parsedId}
                label={parsedId}
                placeholder="Enter value"
                key={v.id}
                onChange={(data) => {
                  setVariableState((s) => ({
                    ...s,
                    [parsedId]: data,
                  }));
                }}
                value={variableState[parsedId] ?? ''}
              />
            );
          })}
        </div>
      ) : null}

      <span className="text-base text-gray-700">
        By clicking “Execute test” the workflow test will start running and
        you’ll be redirected to the execution list page.
      </span>

      <div className="!mt-10 flex space-x-4">
        <Button
          color="secondary"
          loading={isPendingTest}
          onClick={handleSubmit}
          disabled={isFetchingNodeViewData}
          variant="contained"
        >
          Execute Test
        </Button>
        <Button color="secondary" onClick={onClose} variant="outlined">
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

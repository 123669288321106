import { useNavigate } from 'react-router-dom';
import { useGetRefData } from '../Editor/hooks';
import { useEffect, useMemo, useState, type SyntheticEvent } from 'react';
import {
  parseTemplateString,
  ParseVariableMode,
  RunnableWorkflowState,
  CommitWorkflowState,
} from 'types-shared/workflowTypes';
import { AlertVariant, notify, Modal, Tooltip } from 'ui-kit';
import { useRunTestExecution } from './hooks';
import { EditorStore } from '../Editor/store/EditorState';
import { VariableString } from 'types-shared';
import isEmpty from 'lodash/isEmpty';
import { handleException } from 'sentry-browser-shared';
import { getFilteredVariables, useFeatureFlag } from '../../utils/helper';
import values from 'lodash/values';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FeatureFlag } from '../../utils/constants';

import ScheduleRun from './components/ScheduleRun';
import RunWorkflow from './components/RunWorkflow';
import { useFeatureFlagPayload } from 'posthog-js/react';

interface ErrorWithResponse {
  response: Response;
  message?: string;
}

interface Props {
  workflowId: string;
  onClose: () => void;
}

enum TabsEnum {
  RUN_NOW = 'run_now',
  SCHEDULE_RUN = 'schedule_run',
}

export default function ScheduleWorkflow({ workflowId, onClose }: Props) {
  const scheduleEnabled = useFeatureFlag(FeatureFlag.ScheduleRun);

  const readOnlyWorkflowsPayload = useFeatureFlagPayload(
    FeatureFlag.ReadonlyWorkflows,
  ) as { readOnlyWorkflows?: string[] } | undefined;
  const isDesktopWorkflow = useMemo(
    () =>
      readOnlyWorkflowsPayload?.readOnlyWorkflows?.includes(workflowId) ??
      false,
    [readOnlyWorkflowsPayload, workflowId],
  );

  const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.RUN_NOW);

  const editorState = EditorStore();
  const { nodes, variables: variablesMap } = editorState;
  const navigate = useNavigate();

  const [variableState, setVariableState] = useState<Record<string, string>>(
    {},
  );

  if (!workflowId) {
    throw new Error('workflowId not found!');
  }

  const { mutateAsync: runTestExecution, isPending: isPendingTest } =
    useRunTestExecution(isDesktopWorkflow);

  const { data: nodeViewData, isFetching: isFetchingNodeViewData } =
    useGetRefData(workflowId, false, undefined, 'refetch-var-map');

  const variables = useMemo(() => {
    if (!nodeViewData?.variableData) {
      return [];
    }

    return values(
      getFilteredVariables(nodeViewData.variableData, nodes, variablesMap),
    );
  }, [nodeViewData?.variableData, nodes, variablesMap]);

  const processSubmissionError = (throwDefaultError: () => void) => {
    const committedWorkflowState = CommitWorkflowState.safeParse(editorState);
    if (!committedWorkflowState.success) {
      throwDefaultError();
    } else {
      const runnableWorkflowState = RunnableWorkflowState.safeParse(
        committedWorkflowState.data,
      );

      const isSuccess = runnableWorkflowState.success;
      if (isSuccess) {
        throwDefaultError();
      } else {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const errorMessage = JSON.parse(
            runnableWorkflowState.error.message,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          )[0];
          if (errorMessage) {
            notify({
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              message: JSON.parse(runnableWorkflowState.error.message)[0]
                .message as string,
              variant: AlertVariant.INFO,
              timeoutInMs: 5 * 1000, // 5 seconds
            });
          }
        } catch (error) {
          throwDefaultError();
          handleException(error, {
            name: 'Manual run submission error',
            source: 'ManualRun/processSubmissionError',
            extra: {
              committedWorkflowState,
              runnableWorkflowState,
            },
          });
        }
      }
    }
  };

  const runTestNow = () => {
    runTestExecution({
      params: { workflowId },
      body: { variableData: variableState },
    })
      .then(() => {
        notify({
          variant: AlertVariant.SUCCESS,
          message: 'Workflow execution started.',
        });
        navigate(`/workflows/${workflowId}`);
      })
      .catch(async (e: unknown) => {
        handleException(e, {
          name: 'Manual workflow execution error',
          source: 'ManualRun/handleSubmit',
          extra: {
            workflowId,
            variableState,
          },
        });
        let errVariant = AlertVariant.ERROR;
        const err = e as ErrorWithResponse;
        let errorMessage = err.message ?? 'Failed to execute workflow';
        const statusCode = err.response.status;
        const errText = await err.response.text();
        if (errText && statusCode && statusCode >= 400 && statusCode < 500) {
          if (errText.includes('is not in a valid state to be queued')) {
            errVariant = AlertVariant.WARNING;
            errorMessage = 'Workflow needs review';
          }
        }
        processSubmissionError(() => {
          notify({
            message: errorMessage,
            variant: errVariant,
          });
        });
      })
      .finally(() => {
        onClose();
      });
  };

  const handleSubmit = () => {
    runTestNow();
  };

  const handleTabChange = (event: SyntheticEvent, newValue: TabsEnum) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (isEmpty(variableState) && variables.length > 0) {
      setVariableState(
        variables.reduce((acc, variable) => {
          const parsedId = VariableString.parse(
            parseTemplateString({
              data: variable.data.query,
              variableMap: {},
              mode: ParseVariableMode.Dashboard,
              handleException,
            }),
          );
          return {
            ...acc,
            [parsedId]: '',
          };
        }, {}),
      );
    }
  }, [variables, variableState]);

  return (
    <Modal
      className="max-h-[80vh] min-h-[30vh] !max-w-[50rem] !min-w-[60rem]"
      open
      onClose={onClose}
      borderOnCloseIcon={false}
    >
      <div className="pb-5 flex flex-col space-y-6">
        <div className="text-info-dark text-xl font-medium leading-9 tracking-tight">
          Run a workflow
        </div>

        <Tabs
          textColor="secondary"
          indicatorColor="secondary"
          onChange={handleTabChange}
          value={selectedTab}
        >
          <Tab label="RUN NOW" value={TabsEnum.RUN_NOW} />

          {!scheduleEnabled ? (
            // Doing it this way because the tab is unresponsive when wrapped with a tooltip.
            <Tooltip title="Contact sales to access this feature.">
              <Tab
                label="SCHEDULE RUN"
                value={TabsEnum.SCHEDULE_RUN}
                disabled
              />
            </Tooltip>
          ) : (
            <Tab label="SCHEDULE RUN" value={TabsEnum.SCHEDULE_RUN} />
          )}
        </Tabs>

        {selectedTab === TabsEnum.RUN_NOW ? (
          <RunWorkflow
            variables={variables}
            isFetchingNodeViewData={isFetchingNodeViewData}
            isPendingTest={isPendingTest}
            setVariableState={setVariableState}
            variableState={variableState}
            onClose={onClose}
            handleSubmit={handleSubmit}
          />
        ) : (
          <ScheduleRun
            isFetchingNodeViewData={isFetchingNodeViewData}
            isPendingTest={isPendingTest}
          />
        )}
      </div>
    </Modal>
  );
}

import 'types-shared/reactflow.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  AlertVariant,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  notify,
  Tooltip,
  Typography,
  modalEventChannel,
  AccessTimeIcon,
  APITriggerIcon,
  ArrowLeftIcon,
  AutoFixHigh,
  DatabaseIcon,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  Logo,
  TriggerBlockIcon,
} from 'ui-kit';
import { useQueueAutolinkTask } from '../../hooks';
import { EditorStore } from '../../store/EditorState';
import { useAPI } from 'dashboard-shared';
import { SourceTypeEnum } from 'types-shared';
import ManualRun from '../../../ManualRun';
import ScheduleWorkflow from '../../../ScheduleWorkflow';
import { FeatureFlag } from '../../../../utils/constants';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';
import { useVersionHistory } from '../../hooks/useVersionHistory';
import { useSourceVariable } from '../../hooks/useSourceVariable';
import { ConfirmRunAutomation } from '../ConfirmRunAutomation';
import { useFeatureFlag } from '../../../../utils/helper';
import { clsx } from 'clsx';

interface Props {
  autolinkLoading?: boolean;
  workflowId: string;
  workflowName?: string;
  onSave: () => Promise<void>;
  setAutolinkTaskId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSourceType: (sourceType: SourceTypeEnum) => void;
  hasSuggestions?: boolean;
  onReject: () => void;
  onAccept: () => void;
  acceptingSuggestion: boolean;
  rejectingSuggestion: boolean;
  saving?: boolean;
  unsavedChanges?: boolean;
  isReadonlyView?: boolean;
}

export default function UserToolbar({
  autolinkLoading,
  workflowId,
  workflowName,
  onSave,
  setAutolinkTaskId,
  setSourceType,
  hasSuggestions,
  onReject,
  onAccept,
  acceptingSuggestion,
  rejectingSuggestion,
  saving,
  unsavedChanges,
  isReadonlyView = false,
}: Props): JSX.Element {
  const { state, pathname } = useLocation() as {
    pathname: string;
    state: {
      databaseCreated: boolean;
    } | null;
  };
  const navigate = useNavigate();
  const databaseCreated = state?.databaseCreated;
  const { datasourceMetadata, tableData, variables } = EditorStore();

  const isEditorReadonly = isReadonlyView || hasSuggestions;

  const demoFeaturesEnabled = useFeatureFlag(FeatureFlag.DemoFeatures);
  const localRunsEnabled = useFeatureFlag(FeatureFlag.LocalRuns);
  const autolinkEnabled = useFeatureFlag(FeatureFlag.AutolinkDemo);
  const manualRunEnabled = useFeatureFlag(FeatureFlag.ManualRun);
  const [showManualRunPopup, setShowManualRunPopup] = useState<boolean>(false);
  const [showRunWorkflowPopup, setShowRunWorkflowPopup] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [recordIds, setRecordIds] = useState<number[]>([0]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmittedModalOpen, setIsSubmittedModalOpen] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const { setEditingNodeId } = useEditingNodeId();

  const { mutateAsync: queueAutolink, status } = useQueueAutolinkTask();
  const { toggleVersionHistory } = useVersionHistory();

  const { executionSDK } = useAPI();

  const confirmRunHandler = useCallback(async () => {
    setLoading(true);
    try {
      await executionSDK.runRemote(
        workflowId,
        recordIds.map((id) => id.toString()),
      );
      setIsModalOpen(false);
      setIsSubmittedModalOpen(true);
    } catch (e) {
      notify({
        message:
          e instanceof Error
            ? e.message
            : 'Failed to execute the workflow. Please try again.',
        variant: AlertVariant.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }, [executionSDK, workflowId, recordIds]);

  const isLoading = status === 'pending' || autolinkLoading;

  const openSavePrompt = () => {
    modalEventChannel.emit('open', {
      title: 'Save your workflow changes to cloud',
      descriptions: [
        'This workflow is saved on your device. To maintain these changes across devices and execute the workflow remotely, you must save your workflow changes to the cloud.',
      ],
      actions: [
        {
          text: 'SAVE CHANGES IN CLOUD',
          variant: 'contained',
          onClick: async () => {
            modalEventChannel.emit('close');
            await onSave();
            setShowRunWorkflowPopup(true);
          },
        },
        {
          text: 'Leave',
          variant: 'outlined',
          onClick: () => {
            // On leave
            modalEventChannel.emit('close');
          },
        },
      ],
    });
  };

  const scheduleWorkflow = () => {
    if (unsavedChanges) {
      openSavePrompt();
      return;
    }

    setShowRunWorkflowPopup(true);
  };

  const goToWorkflowDetail = () => {
    setIsSubmittedModalOpen(false);
    navigate(`/workflows/${workflowId}`);
  };

  const onAutoLink = useCallback(async () => {
    const taskId: string = await queueAutolink({
      datasourceId: datasourceMetadata?.datasourceId ?? null,
      variables: Object.values(variables),
    });
    setAutolinkTaskId(taskId);
  }, [
    queueAutolink,
    datasourceMetadata?.datasourceId,
    setAutolinkTaskId,
    variables,
  ]);

  useEffect(() => {
    if (databaseCreated) {
      notify({
        message:
          'Database connected! You can now Auto-link your data source to variables/actions',
        variant: AlertVariant.SUCCESS,
        action: (
          <Button
            className="!text-white !border-white"
            onClick={onAutoLink}
            variant="outlined"
          >
            Autolink
          </Button>
        ),
      });
      navigate(pathname, { replace: true });
    }
  }, [pathname, navigate, databaseCreated, onAutoLink]);

  const remoteExecutionApiEnabled = useFeatureFlag(
    FeatureFlag.RemoteExecutionApiTrigger,
  );

  const triggerRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const { isApi, isTrigger, isDataSource } = useSourceVariable(variables);

  const menuItems = [
    {
      label: 'API CALL',
      value: SourceTypeEnum.API,
      isCurrent: isApi,
      icon: (
        <APITriggerIcon
          className="!text-info-dark group-hover:!text-info"
          fontSize="small"
        />
      ),
      disabled: true,
    },
    {
      label: 'DATABASE',
      value: SourceTypeEnum.Datasource,
      isCurrent: isDataSource,
      icon: (
        <DatabaseIcon
          className="!text-info-dark group-hover:!text-info"
          fontSize="small"
        />
      ),
      disabled: true,
    },
    {
      label: 'EMAIL TRIGGER',
      value: SourceTypeEnum.EmailTrigger,
      isCurrent: isTrigger,
      icon: (
        <TriggerBlockIcon
          className="!text-info-dark group-hover:!text-info"
          fontSize="small"
        />
      ),
      disabled: true,
    },
  ];

  const currentItem = menuItems.find((item) => item.isCurrent);

  return (
    <>
      <header className="py-5 flex justify-between items-center !h-20 px-4">
        <div className="flex items-center space-x-6">
          <Link
            className="flex !border !border-solid !border-info !rounded-lg"
            replace
            to={`/workflows/${workflowId}`}
          >
            <ArrowLeftIcon className="text-info !h-10 !w-10" />
          </Link>
          <Link to="/">
            <Logo className="!w-7 !h-7 !cursor-pointer" />
          </Link>
          {hasSuggestions ? (
            <h1 className="text-sm font-medium">
              Workflow improvement recommendation
            </h1>
          ) : (
            <div className="flex flex-col text-xs font-medium">
              <span className="text-gray-500">
                {workflowName ?? 'Workflow'}
              </span>
              <span>{workflowId}</span>
            </div>
          )}

          {remoteExecutionApiEnabled && !isEditorReadonly ? (
            <>
              <Button
                className="group !mr-2 !px-2 !text-nowrap !font-medium !leading-6 !text-sm !text-info-dark hover:!text-info !min-w-[140px]"
                color="secondary"
                endIcon={
                  anchorEl ? (
                    <ExpandLessOutlined
                      className="!text-info-dark group-hover:!text-info"
                      fontSize="small"
                    />
                  ) : (
                    <ExpandMoreOutlined
                      className="!text-info-dark group-hover:!text-info"
                      fontSize="small"
                    />
                  )
                }
                onClick={() => {
                  setAnchorEl(triggerRef.current);
                }}
                ref={triggerRef}
                startIcon={currentItem?.icon}
                variant="text"
              >
                {currentItem?.label}
              </Button>
              <Menu
                BackdropProps={{
                  style: {
                    backgroundColor: 'transparent',
                  },
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                onClose={onMenuClose}
                open={Boolean(anchorEl)}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: '4px',
                  },
                  '& .MuiMenu-list': {
                    padding: '0',
                  },
                  '& .MuiMenu-paper': {
                    minWidth: '140px',
                  },
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {menuItems
                  .filter((i) => !i.isCurrent)
                  .map((item) => (
                    <MenuItem
                      className="!font-medium flex-row items-center"
                      key={item.value}
                      disabled={item.disabled}
                      onClick={() => {
                        setEditingNodeId(undefined);
                        setSourceType(item.value);
                        onMenuClose();
                      }}
                    >
                      {item.icon}
                      <span className="font-medium text-sm text-info-dark ml-2 mr-4">
                        {item.label}
                      </span>
                    </MenuItem>
                  ))}
              </Menu>
            </>
          ) : null}

          {autolinkEnabled && !isEditorReadonly ? (
            <span className="rounded p-px !bg-gradient-to-r from-primary-blue to-primary-purple">
              <Button
                className="!uppercase bg-transparent !text-black !text-sm !bg-white !border-0 !rounded"
                color="secondary"
                endIcon={
                  !isLoading ? (
                    <AutoFixHigh className="text-xs text-black" />
                  ) : null
                }
                loading={isLoading}
                onClick={onAutoLink}
                variant="outlined"
              >
                Autolink
              </Button>
            </span>
          ) : null}
        </div>
        {hasSuggestions ? (
          <div className="flex items-center space-x-4">
            <Button
              color="error"
              disabled={acceptingSuggestion}
              loading={rejectingSuggestion}
              onClick={onReject}
              variant="text"
            >
              Reject
            </Button>
            <Button
              color="secondary"
              disabled={rejectingSuggestion}
              loading={acceptingSuggestion}
              onClick={onAccept}
              variant="contained"
            >
              Apply improvement
            </Button>
          </div>
        ) : (
          <div className="flex items-center space-x-4">
            <Tooltip hidden={isReadonlyView} title="Version History">
              <IconButton
                disabled={isReadonlyView}
                onClick={() => {
                  toggleVersionHistory(true);
                }}
              >
                <AccessTimeIcon
                  className={clsx({
                    '!text-info': !isReadonlyView,
                    '!text-gray-400': isReadonlyView,
                  })}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
            <Button
              color="secondary"
              onClick={() => {
                void onSave();
              }}
              disabled={isReadonlyView}
              variant="outlined"
              loading={saving}
            >
              Save
            </Button>

            {demoFeaturesEnabled ? (
              <Button
                className="!uppercase"
                color="secondary"
                disabled={isReadonlyView}
                variant="contained"
                loading={isExportLoading}
                onClick={() => {
                  setIsExportLoading(true);
                  setTimeout(() => {
                    setIsExportLoading(false);
                  }, 4000);
                }}
              >
                Export Workflow
              </Button>
            ) : null}

            <Tooltip
              hidden={(!isApi && !isTrigger) || manualRunEnabled}
              title="Dashboard runs disabled for triggers"
            >
              <Button
                className="!uppercase"
                color="secondary"
                disabled={
                  (!manualRunEnabled && (isApi || isTrigger)) || Boolean(saving)
                }
                onClick={scheduleWorkflow}
                variant="contained"
              >
                Run workflow
              </Button>
            </Tooltip>
          </div>
        )}
      </header>
      <Modal
        className="!max-w-2xl"
        onClose={() => {
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        showCloseIcon={false}
      >
        <ConfirmRunAutomation
          datasourceState={{ datasourceMetadata, tableData }}
          loading={loading}
          localRunsEnabled={localRunsEnabled}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          onChange={setRecordIds}
          onConfirmRun={confirmRunHandler}
          recordIds={recordIds}
        />
      </Modal>
      <Modal
        className="!max-w-2xl -top-20"
        onClose={() => {
          setIsSubmittedModalOpen(false);
        }}
        open={isSubmittedModalOpen}
        showCloseIcon
      >
        <img alt="logo" className="w-32" src="/logo-blue.png" />
        <div className="ml-1">
          <Typography className="!font-medium !mt-7" variant="h5">
            Your execution is in progress!
          </Typography>
          <Typography className="!mt-4 !text-info-dark">
            Your workflow is running remotely; the execution(s) can take a few
            minutes. We'll notify you of progress through <b>Slack</b> and in
            the workflow executions page!
          </Typography>
        </div>
        <Button
          className="!text-info !border-info !my-10"
          onClick={() => {
            goToWorkflowDetail();
          }}
          variant="outlined"
        >
          GO TO WORKFLOW DETAILS
        </Button>
      </Modal>
      {showManualRunPopup && !saving ? (
        <ManualRun
          onClose={() => {
            setShowManualRunPopup(false);
          }}
          workflowId={workflowId}
        />
      ) : null}
      {showRunWorkflowPopup && !saving ? (
        <ScheduleWorkflow
          onClose={() => {
            setShowRunWorkflowPopup(false);
          }}
          workflowId={workflowId}
        />
      ) : null}
    </>
  );
}

import { useQuery } from '@tanstack/react-query';
import { useAPI } from '../../../hooks/useApi';

export function useGetWorkflowListMetrics() {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<{
    workflowsCount: number;
    executionsCount: number;
  }>({
    queryKey: ['workflowsListMetrics'],
    queryFn: () => {
      return sdk.fetchWorkflowMetrics();
    },
  });
}

export function useGetWorkflowExecutionMetrics(workflowId: string) {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<{
    totalExecutions: number;
    executionsCount: Record<string, number>;
    monthlyExecutions: Record<string, number>[];
    hourlyExecutions: Record<string, number>[];
  }>({
    queryKey: ['workflowExecutionMetrics', workflowId],
    queryFn: () => {
      return sdk.fetchWorkflowExecutionMetrics(workflowId);
    },
  });
}

import { useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import {
  type GridColDef,
  type GridRenderCellParams,
} from '@mui/x-data-grid/models';
import {
  Button,
  DataLoader,
  IconButton,
  FileIconAlt,
  DataCopyIcon,
  CustomDownloadIcon,
  Modal,
} from 'ui-kit';

import { copyToClipboard } from '../../../../utils/helper';
import { clsx } from 'clsx';
import { NoDataFound } from '../../../../components/NoDataFound';

interface OutputsTableProps {
  loading: boolean;
  isOutput?: boolean;
  outputItems: OutputItem[];
}

export enum OutputItemActions {
  DOWNLOAD = 'download',
  COPY = 'copy',
}

export interface OutputItem {
  id: string;
  title: string;
  description?: string | object | null;
  onDownloadLinkData?: (url: string) => void;
  action: OutputItemActions;
  uri?: string;
  type: string;
}

const descriptionBreakLength = 700;

export default function OutputsTab({
  outputItems,
  loading,
  isOutput = true,
}: OutputsTableProps) {
  const [previewString, setPreviewString] = useState<string | undefined>('');
  const [previewTitle, setPreviewTitle] = useState('');

  const truncateDesc = (desc: string) => {
    return desc.length > descriptionBreakLength
      ? `${desc.slice(0, descriptionBreakLength - 3)}...`
      : desc;
  };

  const columns: GridColDef<OutputItem>[] = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Name',
        flex: 2,
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 2,
      },
      {
        field: 'description',
        headerName: 'Value',
        renderCell: (params: GridRenderCellParams<OutputItem>) => {
          const description = params.row.description;

          const canPreview =
            params.row.action === OutputItemActions.COPY &&
            typeof description === 'string' &&
            description.length > descriptionBreakLength;

          const hasNoDescription =
            typeof description === 'undefined' ||
            (typeof description === 'string' && !description.length);

          return (
            <div className="py-4">
              <div
                className={clsx({
                  'flex flex-row items-center text-info-dark': true,
                  'mb-2': canPreview,
                })}
              >
                {params.row.action === OutputItemActions.DOWNLOAD ? (
                  <FileIconAlt style={{ width: 30 }} />
                ) : null}{' '}
                <span
                  className={
                    hasNoDescription
                      ? 'text-gray-500 italic'
                      : 'break-word-span'
                  }
                >
                  {typeof description === 'string'
                    ? truncateDesc(description)
                    : null}

                  {hasNoDescription ? 'No value provided' : null}
                </span>
              </div>
              {canPreview ? (
                <Button
                  className="my-2"
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    setPreviewString(description as string | undefined);
                    setPreviewTitle(params.row.title);
                  }}
                >
                  PREVIEW {isOutput ? 'OUTPUT' : 'INPUT'}
                </Button>
              ) : null}
            </div>
          );
        },
        flex: 4,
      },

      {
        field: ' ',
        flex: 2,
        renderCell: (params: GridRenderCellParams<OutputItem>) => {
          const hasNoDescription =
            typeof params.row.description === 'undefined' ||
            (typeof params.row.description === 'string' &&
              !params.row.description.length);

          return params.row.action === OutputItemActions.COPY ? (
            <IconButton
              className="!p-0"
              disabled={hasNoDescription}
              onClick={() => {
                const description = params.row.description;

                const text =
                  typeof description === 'string'
                    ? description
                    : JSON.stringify(description, null, 2);

                copyToClipboard(text);
              }}
            >
              <DataCopyIcon
                className={
                  hasNoDescription ? 'text-gray-500' : 'text-secondary-blue'
                }
              />
            </IconButton>
          ) : (
            <IconButton
              className="!p-0"
              onClick={() => {
                if (params.row.uri) {
                  params.row.onDownloadLinkData?.(params.row.uri);
                }
              }}
            >
              <CustomDownloadIcon
                className="text-secondary-blue"
                fontSize="small"
              />
            </IconButton>
          );
        },
      },
    ],
    [isOutput],
  );

  return loading ? (
    <DataLoader />
  ) : (
    <div>
      {outputItems.length ? (
        <DataGrid
          columns={columns}
          getRowId={(row) => row.id}
          getRowHeight={() => 'auto'}
          rows={outputItems}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal !important',
            },
          }}
        />
      ) : (
        <NoDataFound
          heading={`No ${isOutput ? 'record outputs' : 'inputs'} found.`}
          subHeading={`If your execution has any ${isOutput ? 'record outputs' : 'inputs'}, they will show up here.`}
        />
      )}

      <PreviewModal
        isOutput={isOutput}
        title={previewTitle}
        open={Boolean(previewString)}
        previewText={previewString}
        onClose={() => {
          setPreviewString('');
          setPreviewTitle('');
        }}
      />
    </div>
  );
}

function PreviewModal({
  previewText,
  title,
  onClose,
  open,
  isOutput = true,
}: {
  previewText: string | undefined;
  title: string;
  onClose: () => void;
  open: boolean;
  isOutput?: boolean;
}) {
  return (
    <Modal
      className="max-h-[80vh] !max-w-[42rem]"
      containerClassName="flex flex-col space-y-3"
      open={open}
      onClose={onClose}
      borderOnCloseIcon={false}
    >
      <p className="text-info-dark text-xl font-medium leading-9 tracking-tight">
        {isOutput ? 'Output' : 'Input'} preview
      </p>
      <p className="text-info-dark text-lg font-medium leading-9 tracking-tight">
        {title}
      </p>

      <div>
        <p className={previewText ? 'text-gray-500 break-word-span' : ''}>
          {previewText ?? 'No value provided'}
        </p>
      </div>

      <div className="!mt-5 flex space-x-4 pb-10">
        <Button
          startIcon={<DataCopyIcon className="text-white" />}
          color="secondary"
          disabled={!previewText}
          onClick={() => {
            if (previewText) {
              copyToClipboard(previewText);
            }

            onClose();
          }}
          variant="contained"
        >
          COPY {isOutput ? 'OUTPUT' : 'INPUT'}
        </Button>
      </div>
    </Modal>
  );
}

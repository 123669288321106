import { VariableInput } from './VariableTypes/VariableInput';
import { clsx } from 'clsx';
import {
  CheckCircleIcon,
  IconButton,
  Input,
  Spinner,
  Switch,
  AutoFixInactiveIcon,
  InfoOutlined,
  SendIcon,
} from 'ui-kit';
import React, { useCallback, useState } from 'react';
import type {
  GlobalVariable,
  TemplateData,
  Variable,
  VariableMap,
} from 'types-shared';
import { isAdmin } from '../../../utils/env';
import { TRANSFORM_INPUT_CHAR_LIMIT } from '../../../utils/constants';

interface FormValues {
  initialValue?: string;
  transformQuery?: TemplateData;
  previewTransform?: string;
}

interface Props {
  enabled: boolean;
  isAddingAVariable?: boolean;
  setEnabled: (newEnabled: boolean) => void;

  formValues: FormValues;
  setFormValues: (values: FormValues) => void;

  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (prompt: TemplateData) => Promise<string | undefined>;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  hidden?: boolean;
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Transformations({
  isAddingAVariable = false,
  enabled,
  setEnabled,
  formValues,
  setFormValues,
  transformApiReqStatus,
  onTransformApiReq,
  variablesMap,
  globalVariablesMap,
  hidden,
  setIsDirty,
}: Props) {
  const isLoading =
    transformApiReqStatus === 'pending' || transformApiReqStatus === 'loading';
  const isSuccess = transformApiReqStatus === 'success';
  const isError = transformApiReqStatus === 'error';
  const [localDirty, setLocalDirty] = useState(false);

  const isTransformInputExceeded =
    (formValues.initialValue?.length ?? 0) > TRANSFORM_INPUT_CHAR_LIMIT;

  const transformApiReq = useCallback(async () => {
    setLocalDirty(false);
    if (!formValues.transformQuery || formValues.transformQuery.length === 0) {
      return;
    }

    const transformedText = await onTransformApiReq(formValues.transformQuery);
    if (transformedText) {
      setFormValues({
        ...formValues,
        previewTransform: transformedText,
      });
    }
  }, [formValues, onTransformApiReq, setFormValues]);

  return hidden ? null : (
    <div
      className={clsx({
        'rounded-lg mb-9 mt-5 p-px ': true,
        '!bg-gradient-to-r from-primary-blue to-primary-purple':
          enabled || isAddingAVariable,
        '!bg-gradient-to-r from-gray-300 to-gray-500':
          !enabled && !isAddingAVariable,
      })}
    >
      <div className="rounded-lg p-7 text-sm flex flex-col bg-white overflow-y-hidden">
        <div className="flex justify-between items-center">
          <div className="flex gap-4">
            <AutoFixInactiveIcon
              fontSize="small"
              className={clsx({
                'text-info-dark': enabled || isAddingAVariable,
                'text-gray-400': !enabled && !isAddingAVariable,
              })}
            />
            <span
              className={clsx({
                'font-medium mb-1': true,
                'text-info-dark': enabled || isAddingAVariable,
                'text-gray-400': !enabled && !isAddingAVariable,
              })}
            >
              Apply GPT Transformations
            </span>
          </div>

          <Switch
            color="secondary"
            checked={enabled}
            // disabled={!enabled}
            onChange={(e, newEnabled) => {
              setIsDirty(true);
              setEnabled(newEnabled);
            }}
          />
        </div>

        <div
          className={clsx({
            'mt-5 text-sm': true,
            'text-slate-500': enabled || isAddingAVariable,
            'text-gray-400': !enabled && !isAddingAVariable,
          })}
        >
          Automatically convert the variable’s data with a natural language
          prompt.
        </div>

        <div
          className={clsx(
            'transition-all duration-500',
            enabled && 'opacity-1',
            !enabled && 'max-h-0 opacity-0',
          )}
        >
          <div className="mb-5 mt-5 text-slate-500 text-sm">
            Add a variable value example to preview the transformation result
            that will be applied
          </div>

          <div className="relative flex flex-col mt-3 pt-1">
            <Input
              classes={{ wrapper: 'flex flex-col truncate mb-8' }}
              floatingLabel
              multiline
              label="Example value to transform"
              onChange={(newInitialValue: string) => {
                setIsDirty(true);
                setLocalDirty(true);
                setFormValues({
                  ...formValues,
                  initialValue: newInitialValue,
                });
              }}
              placeholder="Enter in sample value"
              value={formValues.initialValue}
              error={isTransformInputExceeded}
              showErrorText
              errorText={`You have exceeded the character limit of ${TRANSFORM_INPUT_CHAR_LIMIT.toString()}. Please crop your text to test the transformation.`}
            />

            <VariableInput
              allowAddVariable={isAdmin}
              showPlusButton={isAdmin}
              label="Prompt"
              value={formValues.transformQuery ?? []}
              onChange={(newTransformQuery) => {
                setIsDirty(true);
                setLocalDirty(true);
                setFormValues({
                  ...formValues,
                  transformQuery: newTransformQuery,
                });
              }}
              className={clsx('min-h-40')}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              placeholder="Write the instructions"
            />
            <IconButton
              className="!absolute bottom-1 right-1"
              disabled={
                localDirty
                  ? isTransformInputExceeded
                  : isSuccess || isError || isLoading
              }
              onClick={transformApiReq}
            >
              {isLoading && !localDirty ? <Spinner size={16} /> : null}
              {isSuccess && !isError && !localDirty ? (
                <CheckCircleIcon className="text-transparent" />
              ) : null}
              {isError && !localDirty ? (
                <InfoOutlined className="text-error" />
              ) : null}
              {(!isSuccess &&
                !isError &&
                transformApiReqStatus !== 'pending') ||
              localDirty ? (
                <SendIcon
                  className={clsx('text-white', {
                    'opacity-60': isTransformInputExceeded,
                  })}
                />
              ) : null}
            </IconButton>
          </div>
          {isError ? (
            <p className="px-1 pt-1 text-error text-xs">
              Your transformation is still processing due to high system load.
              Please try again later as our system relies on external services
              that may be experiencing delays.
            </p>
          ) : null}

          <div className="font-medium mt-8 text-info-dark">
            Transformation result
          </div>
          <div
            className={clsx('mt-1 p-[1px] !rounded bg-zinc-100', {
              '!bg-gradient-to-r from-primary-blue to-primary-purple !p-0.5':
                formValues.previewTransform,
            })}
          >
            <div className="!rounded-sm p-3 min-h-[2.75rem] break-words bg-zinc-100 whitespace-pre-wrap font-mono">
              {formValues.previewTransform ? (
                formValues.previewTransform
              ) : (
                <span className="text-neutral-400">Transformation Result</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

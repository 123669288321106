import { useState, type ReactElement, useMemo, useCallback } from 'react';
import type { WorkflowAction, WorkflowActionsOptions } from 'types-shared';
import {
  DeleteIcon,
  DesktopIcon,
  EditIcon,
  EyeIcon,
  HideEyeIcon,
  IconButton,
  Menu,
  MenuItem,
  modalEventChannel,
  PersonIcon,
  QuestionAnswerIcon,
  ClosedCaption,
  Logo,
  LowPriority,
  MoreVert,
  PhonelinkLock,
  Publish,
} from 'ui-kit';
import { contactModalEventChannel } from '../../../../../utils/contactModal';
import { useParams } from 'react-router-dom';
import { isAdmin } from '../../../../../utils/env';
import { ActionsEnum } from 'types-shared/actionTypes';
import { clsx } from 'clsx';

interface Props {
  action: WorkflowAction;
  allowDeleteAction: boolean;
  onEditTarget?: () => void;
  onMoveAction?: () => void;
  onDeleteAction?: () => void;
  onAddApprovalStep?: (position: 'before' | 'after') => void;
  onUpdateActionOptions: (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => void;
  showManualHandleOption?: boolean;
}

interface MenuItemProps {
  text: string;
  action: () => void;
  renderWhen: boolean;
  icon: ReactElement;
}

function CustomMenuItem({ text, action, renderWhen, icon }: MenuItemProps) {
  return renderWhen ? (
    <MenuItem className="min-w-64 h-8 !px-4" onClick={action}>
      <span className="font-normal mr-4">{text}</span>
      {icon}
    </MenuItem>
  ) : null;
}

function ActionMenu({
  action,
  allowDeleteAction,
  onUpdateActionOptions,
  onEditTarget,
  onDeleteAction,
  onMoveAction,
  showManualHandleOption,
}: Props) {
  const { workflowId } = useParams();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    options: {
      hidden,
      hitl,
      sitl,
      adminOnly,
      submitted,
      captcha,
      reCaptcha,
      mfa,
      adminManual,
      adminSkipped,
      terminal,
    } = {},
  } = action;
  const HideIcon = hidden ? EyeIcon : HideEyeIcon;
  const AdminIcon = adminOnly ? EyeIcon : HideEyeIcon;
  const SkipIcon = hitl ? DesktopIcon : PersonIcon;

  const isMfaOrCaptcha = Boolean(mfa) || Boolean(captcha) || Boolean(reCaptcha);
  const isMagicLoopAction = action.actionType === ActionsEnum.MagicLoop;

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const closeModal = useCallback(() => {
    modalEventChannel.emit('close');
  }, []);

  const openModal = useCallback(() => {
    modalEventChannel.emit('open', {
      title: 'Actions handled by Sola',
      descriptions: [
        'Our team can take care of some manually handled actions for you, including Captchas and basic approvals.',
        'Chat with us to setup the timing and pricing that works for you!',
      ],
      actions: [
        {
          text: 'Confirm',
          onClick: () => {
            closeModal();
          },
        },
        {
          text: 'Contact Us',
          onClick: () => {
            contactModalEventChannel.emit('open', {
              problemType: 'General Issue',
              workflowId,
            });
            closeModal();
          },
          variant: 'text',
        },
      ],
    });
  }, [closeModal, workflowId]);

  const toggleActionOption = useCallback(
    (name: keyof WorkflowActionsOptions) => {
      const exclusive = [
        'hidden',
        'adminManual',
        'adminOnly',
        'sitl',
        'hitl',
        'adminSkipped',
      ].includes(name);
      let newHitl =
        name === 'hitl' ? !action.options?.hitl : action.options?.hitl;
      let newSitl =
        name === 'sitl' ? !action.options?.sitl : action.options?.sitl;

      if (name === 'captcha' || name === 'mfa') {
        newSitl = !action.options?.[name];
        if (!action.options?.[name]) {
          openModal();
        }
      }

      if (name === 'hitl') {
        newSitl = false;
      }

      if (name === 'sitl') {
        newHitl = false;
      }
      onUpdateActionOptions(action, {
        ...(exclusive ? action.options : {}),
        hidden: action.options?.hidden,
        adminManual: action.options?.adminManual,
        [name]: !action.options?.[name],
        hitl: newHitl,
        sitl: newSitl,
        ...(name === 'captcha' && action.options?.captcha
          ? {
              reCaptcha: false,
            }
          : {}),
      });
      onClose();
    },
    [onUpdateActionOptions, openModal, onClose, action],
  );

  const menuItems = useMemo(() => {
    const adminMenu: MenuItemProps[] = [
      {
        renderWhen: !isMfaOrCaptcha && Boolean(showManualHandleOption),
        text: hitl
          ? 'Handle action automatically'
          : 'User will handle action manually (shows to users).',
        action: () => {
          toggleActionOption('hitl');
        },
        icon: (
          <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: Boolean(isMfaOrCaptcha && sitl && showManualHandleOption),
        text: 'User will handle action manually (shows to users)',
        action: () => {
          toggleActionOption('hitl');
        },
        icon: (
          <PersonIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: !sitl,
        text: 'Sola will handle this action (shows to users)',
        action: () => {
          toggleActionOption('sitl');
          openModal();
        },
        icon: <Logo className="!w-5 !h-5 !ml-auto" />,
      },
      {
        renderWhen: Boolean(sitl),
        text: 'Handle this action automatically',
        action: () => {
          toggleActionOption('sitl');
        },
        icon: <Logo className="!w-5 !h-5 !ml-auto" />,
      },
      {
        renderWhen: true,
        text: `${hidden ? 'Enable' : 'Skip'} Action (shows to users)`,
        action: () => {
          toggleActionOption('hidden');
        },
        icon: (
          <HideIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: Boolean(isAdmin),
        text: `${adminSkipped ? 'Disable' : 'Enable'} Pass through`,
        action: () => {
          toggleActionOption('adminSkipped');
        },
        icon: (
          <HideIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: adminManual
          ? 'Unmark as Manually handled by Admin'
          : 'Mark as Manually handled by Admin',
        action: () => {
          toggleActionOption('adminManual');
        },
        icon: (
          <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: `${adminOnly ? 'Show Action To' : 'Hide Action From'} Users`,
        action: () => {
          toggleActionOption('adminOnly');
        },
        icon: (
          <AdminIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: 'Edit Target',
        action: () => {
          onClose();
          onEditTarget?.();
        },
        icon: (
          <EditIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: 'Move Action',
        action: () => {
          onClose();
          onMoveAction?.();
        },
        icon: <LowPriority className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
      {
        renderWhen: allowDeleteAction,
        text: 'Delete Action',
        action: () => {
          onClose();
          onDeleteAction?.();
        },
        icon: (
          <DeleteIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: submitted ? 'Unmark as Submit' : 'Mark as Submit',
        action: () => {
          toggleActionOption('submitted');
        },
        icon: <Publish className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
      {
        renderWhen: true,
        text: captcha ? 'Unmark as Captcha' : 'Mark as Captcha',
        action: () => {
          toggleActionOption('captcha');
        },
        icon: <ClosedCaption className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
      {
        renderWhen: true,
        text: mfa ? 'Unmark as MFA' : 'Mark as MFA',
        action: () => {
          toggleActionOption('mfa');
        },
        icon: <PhonelinkLock className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
    ];

    const userMenu: MenuItemProps[] = [
      {
        renderWhen: true,
        text: `${hidden ? 'Enable' : 'Skip'} Action`,
        action: () => {
          toggleActionOption('hidden');
        },
        icon: (
          <HideIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: !isMfaOrCaptcha && Boolean(showManualHandleOption),
        text: hitl ? 'Handle action automatically' : 'Handle action manually ',
        action: () => {
          toggleActionOption('hitl');
        },
        icon: (
          <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: Boolean(isMfaOrCaptcha && sitl && showManualHandleOption),
        text: 'Handle action manually',
        action: () => {
          toggleActionOption('hitl');
        },
        icon: (
          <PersonIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: !sitl,
        text: 'I want Sola to handle this action',
        action: () => {
          toggleActionOption('sitl');
          openModal();
        },
        icon: <Logo className="!w-5 !h-5 !ml-auto" />,
      },
      {
        renderWhen: Boolean(sitl),
        text: 'Handle this action automatically',
        action: () => {
          toggleActionOption('sitl');
        },
        icon: <Logo className="!w-5 !h-5 !ml-auto" />,
      },
      {
        renderWhen: true,
        text: 'Report Inconsistency',
        action: () => {
          onClose();
          contactModalEventChannel.emit('open', {
            problemType: 'Incorrect Action',
            workflowId,
          });
        },
        icon: (
          <QuestionAnswerIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: allowDeleteAction,
        text: 'Delete Action',
        action: () => {
          onClose();
          onDeleteAction?.();
        },
        icon: (
          <DeleteIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
    ];

    if (isMagicLoopAction && terminal) {
      return isAdmin
        ? [
            {
              renderWhen: true,
              text: 'Move Action',
              action: () => {
                onClose();
                onMoveAction?.();
              },
              icon: (
                <LowPriority className="!w-5 !h-5 !ml-auto !text-info-dark" />
              ),
            },
          ]
        : [];
    }

    return isAdmin ? adminMenu : userMenu;
  }, [
    isMfaOrCaptcha,
    showManualHandleOption,
    hitl,
    SkipIcon,
    sitl,
    hidden,
    HideIcon,
    adminSkipped,
    adminManual,
    adminOnly,
    AdminIcon,
    onEditTarget,
    onMoveAction,
    allowDeleteAction,
    submitted,
    captcha,
    mfa,
    isMagicLoopAction,
    terminal,
    toggleActionOption,
    openModal,
    onClose,
    onDeleteAction,
    workflowId,
  ]);

  return (
    <>
      <IconButton
        className="!p-0"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(e.target as HTMLButtonElement);
        }}
      >
        <MoreVert
          className={clsx('!w-5 !h-5', {
            'text-black': !isMagicLoopAction,
            'text-white': isMagicLoopAction,
          })}
        />
      </IconButton>
      <Menu
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
        onClose={onClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map((item) => (
          <CustomMenuItem
            text={item.text}
            renderWhen={item.renderWhen}
            icon={item.icon}
            action={item.action}
            key={item.text}
          />
        ))}
      </Menu>
    </>
  );
}

export default ActionMenu;

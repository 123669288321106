import { useEffect, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import {
  type BranchData,
  type DatasourceMetadata,
  type DatasourceTable,
  type Group,
  type RequestIntegrationTypeEnum,
  type TemplateData,
  type Variable,
  WorkflowConditionalNode,
  type WorkflowContinueNode,
  type WorkflowEdge,
  type WorkflowFreeformNode,
  type WorkflowNode,
  type WorkflowRequestNode,
  type WorkflowRetryNode,
  type WorkflowStopNode,
  BranchModeEnum,
  NodeTypesEnum,
  SourceTypeEnum,
  StopNodeStatusEnum,
  type GlobalVariable,
  type VariableMap,
} from 'types-shared';
import { ConditionalBlock } from './ConditionalBlock';
import { FreeformBlock } from './FreeformBlock';
import { RetryBlock } from './RetryBlock';
import { StopBlock } from './StopBlock';
import clone from 'lodash/clone';
import setWith from 'lodash/setWith';
import { AddRecordingBlock } from './AddRecordingBlock';
import type { WorkflowRequestNodeCoreData } from './RequestBlockAdmin';
import { RequestBlockAdmin } from './RequestBlockAdmin';
import {
  createTemplateVariable,
  initialRequestBlockData,
} from './request.helpers';
import { TriggerBlockAdmin } from './TriggerBlock/TriggerBlockAdmin';
import { OptionsBlock } from './OptionsBlock';
import { TriggerBlockCustomer } from './TriggerBlock/TriggerBlockCustomer';
import { useSourceVariable } from '../../hooks/useSourceVariable';
import { DocumentsBlock } from './DocumentsBlock/DocumentsBlock';
import { RequestBlockCustomer } from './RequestBlockCustomer';
import { insertNodeAfter } from '../../utils/helper';
import { autoFormat } from '../../utils/autoformat';
import { isAdmin } from '../../../../utils/env';
import { initialGroup } from '../Conditions/conditions.helpers';

interface Props {
  workflowId: string;
  allowBranchReordering?: boolean;
  continueRecordingBlockEnabled?: boolean;
  stopBlockEnabled?: boolean;
  enabledFeatureFlags: string[];
  nodeId: string;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  setNodes: (nodes: WorkflowNode[]) => void;
  setEdges: (edges: WorkflowEdge[]) => void;
  updateNode: (node: WorkflowNode) => void;
  addNodes: (nodes: WorkflowNode[]) => void;
  onCancel: () => void;
  onImport?: (replaceNodeId?: string) => Promise<boolean>;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  sourceType?: SourceTypeEnum;
  onTransformApiReq: (
    prompt: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
  onUploadFile: (file: File) => Promise<{ fileId: string }>;
  continueRecording?: (nodeId: string) => void;
  fullRequestNodeVersion: boolean;
}

export function EditNodePanel({
  workflowId,
  allowBranchReordering,
  continueRecordingBlockEnabled,
  enabledFeatureFlags,
  stopBlockEnabled,
  nodeId,
  nodes,
  edges,
  setEdges,
  updateNode,
  setNodes,
  onCancel,
  onImport,
  datasourceMetadata,
  variablesMap,
  globalVariablesMap,
  tableData,
  addVariable,
  updateVariable,
  onTransformApiReq,
  transformApiReqStatus,
  onUploadFile,
  sourceType,
  fullRequestNodeVersion,
}: Props) {
  const [currentNodeType, setCurrentNodeType] = useState<string | null>(null);
  const [editingEdge, setEditingEdge] = useState<WorkflowEdge>();
  const selectedNode = useMemo(
    () => nodes.find((node) => node.id === nodeId),
    [nodes, nodeId],
  );

  const { sourceVariable } = useSourceVariable(variablesMap);

  const insertNode = (sourceId: string) => {
    const sourceNode = nodes.find((node) => node.id === sourceId);

    if (!sourceNode) {
      throw Error('sourceNode not found');
    }

    const { edgeId, nodePositions } = insertNodeAfter(
      sourceNode,
      nodes,
      edges,
      {
        setNodes,
        setEdges,
      },
      true,
    );

    const dataPayload = {
      ...sourceNode.data,
      branchesData: [
        ...((sourceNode as WorkflowConditionalNode).data
          .branchesData as BranchData[]),
        {
          branchId: edgeId,
          selectedMode: BranchModeEnum.Rule,
          instruction: { variableId: createTemplateVariable(addVariable).id },
        },
      ],
    };

    updateNode({
      ...sourceNode,
      position: nodePositions[sourceNode.id] ?? sourceNode.position,
      data: dataPayload,
    } as WorkflowNode);
  };

  const setNodeType = (
    nodeType: string,
    integrationType?: RequestIntegrationTypeEnum,
  ) => {
    if (isNil(selectedNode)) {
      throw Error('selectedNode step not found!');
    }

    switch (nodeType) {
      case 'conditional': {
        const filteredEdges = edges.map((e) => {
          if (e.source === selectedNode.id) {
            return {
              ...e,
              labelStyle: { display: 'block' },
            };
          }
          return e;
        });
        const onwardEdge = filteredEdges.find(
          (e) => e.source === selectedNode.id,
        );
        const branchesData: BranchData[] = [];

        if (onwardEdge) {
          branchesData.push({
            branchId: onwardEdge.id,
            selectedMode: BranchModeEnum.Rule,
            instruction: { variableId: createTemplateVariable(addVariable).id },
            rule: {
              data: initialGroup(addVariable),
              output: [{ id: onwardEdge.id }],
            },
          }); // HERE PAUL
        }
        const payload = {
          ...selectedNode,
          data: {
            ...selectedNode.data,
            branchesData,
          },
          type: NodeTypesEnum.Conditional,
          name: 'New Conditional Step',
        };

        WorkflowConditionalNode.parse(payload);

        updateNode(payload as WorkflowConditionalNode);
        setEdges(filteredEdges);
        break;
      }
      case 'freeform': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Freeform,
          data: {
            ...selectedNode.data,
            instructions: {
              variableId: createTemplateVariable(addVariable).id,
            },
          },
          name: 'New Freeform Step',
        });
        break;
      }
      case 'stop': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Stop,
          data: {
            ...selectedNode.data,
            status: StopNodeStatusEnum.Success,
          },
        });
        break;
      }
      case 'retry': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Retry,
          data: {
            ...selectedNode.data,
            maxAttempts: 3,
            retryInterval: 1440,
          },
        });
        break;
      }
      case 'continue': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Continue,
          data: selectedNode.data,
        });
        break;
      }
      case 'document': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Document,
          data: {
            ...selectedNode.data,
            surfaceOutputVariables: false,
          },
        });
        break;
      }
      case 'request': {
        const requestData = initialRequestBlockData(
          addVariable,
          Boolean(isAdmin),
        );
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Request,
          data: {
            ...selectedNode.data,
            ...requestData,
            integrationType,
          },
        } as WorkflowRequestNode);
        break;
      }
    }

    setCurrentNodeType(nodeType);
  };

  const updateNodeProps = (key: string, value: unknown) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.id === selectedNode?.id) {
          return setWith(clone(_node), key, value, clone);
        }
        return _node;
      }),
    );
  };

  const updateEdge = ({
    name,
    group,
    instruction,
  }: Partial<{
    name: string;
    group: Group;
    instruction: { variableId: string };
  }>) => {
    if (!editingEdge) {
      throw Error('editingEdge not found!');
    }

    if (name) {
      // add the label on edge
      setEdges(
        edges.map((edge) => {
          if (edge.id === editingEdge.id) {
            return {
              ...edge,
              label: name,
            };
          }
          return edge;
        }),
      );
    }

    const updateBranchData = (
      branchesData: BranchData[],
      newInstruction: { variableId: string },
      newGroup?: Group,
    ) => {
      const branchData = branchesData.find(
        (b) => b.branchId === editingEdge.id,
      );

      if (!branchData) {
        return [
          ...branchesData,
          {
            branchId: editingEdge.id,
            rule: newGroup
              ? { data: newGroup, output: [{ id: editingEdge.id }] }
              : undefined,
            instruction: newInstruction,
            selectedMode: newGroup
              ? BranchModeEnum.Rule
              : BranchModeEnum.Instruction,
          },
        ];
      }
      return branchesData.map((b) => {
        if (b.branchId === editingEdge.id) {
          return {
            ...b,
            rule: newGroup
              ? { data: newGroup, output: [{ id: editingEdge.id }] }
              : b.rule,
            instruction: newInstruction,
            selectedMode: newGroup
              ? BranchModeEnum.Rule
              : BranchModeEnum.Instruction,
          };
        }
        return b;
      });
    };

    setNodes(
      nodes.map((_node) => {
        if (
          _node.id === selectedNode?.id &&
          _node.type === NodeTypesEnum.Conditional
        ) {
          return {
            ..._node,
            data: {
              ..._node.data,
              branchesData: updateBranchData(
                _node.data.branchesData ?? [],
                instruction ?? {
                  variableId: createTemplateVariable(addVariable).id,
                },
                group,
              ),
            },
          };
        }
        return _node;
      }),
    );

    setEditingEdge(undefined);
  };

  const deleteBranch = () => {
    if (!editingEdge || !selectedNode) return;

    const updatedEdges = edges.filter((edge) => edge.id !== editingEdge.id);
    setEdges(updatedEdges);
    setEditingEdge(undefined);

    const updatedNodes = nodes
      .filter((n) => n.id !== editingEdge.target)
      .map((n) => {
        if (n.id === editingEdge.source) {
          return {
            ...n,
            data: {
              ...n.data,
              branchesData: (
                n as WorkflowConditionalNode
              ).data.branchesData?.filter((b) => b.branchId !== editingEdge.id),
            },
          } as WorkflowNode;
        }
        return n;
      });

    autoFormat(updatedNodes, updatedEdges, setNodes);
  };

  const updateRequestNodeData = (data: WorkflowRequestNodeCoreData) => {
    setNodes(
      nodes.map((_node) => {
        if (
          _node.id === selectedNode?.id &&
          _node.type === NodeTypesEnum.Request
        ) {
          return {
            ..._node,
            data: {
              ..._node.data,
              ...data,
              nodeStatus: _node.data.nodeStatus,
            },
          };
        }
        return _node;
      }),
    );
  };

  useEffect(() => {
    if (!currentNodeType) {
      setCurrentNodeType(selectedNode?.type ?? 'continue');
    }
  }, [selectedNode?.type, currentNodeType]);

  const handleOnCancel = () => {
    onCancel();
    setCurrentNodeType(null);
  };

  if (!nodeId || !selectedNode) return null;

  if (!currentNodeType || currentNodeType === 'new') {
    return (
      <OptionsBlock
        workflowId={workflowId}
        continueRecordingBlockEnabled={continueRecordingBlockEnabled}
        fullRequestNodeVersion={fullRequestNodeVersion}
        enabledFeatureFlags={enabledFeatureFlags}
        stopBlockEnabled={stopBlockEnabled}
        node={selectedNode}
        onCancel={handleOnCancel}
        onContinue={setNodeType}
        updateNode={updateNode}
      />
    );
  }

  if (
    selectedNode.type === NodeTypesEnum.Source &&
    sourceVariable?.data.sourceType === SourceTypeEnum.EmailTrigger &&
    isAdmin
  ) {
    return (
      <TriggerBlockAdmin
        node={selectedNode}
        onCancel={handleOnCancel}
        updateNode={updateNode}
        updateVariable={updateVariable}
        addVariable={addVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        onTransformApiReq={onTransformApiReq}
        transformApiReqStatus={transformApiReqStatus}
      />
    );
  } else if (
    selectedNode.type === NodeTypesEnum.Source &&
    sourceVariable?.data.sourceType === SourceTypeEnum.EmailTrigger &&
    !isAdmin
  ) {
    return (
      <TriggerBlockCustomer
        node={selectedNode}
        onCancel={handleOnCancel}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        updateVariable={updateVariable}
        addVariable={addVariable}
        onTransformApiReq={onTransformApiReq}
        transformApiReqStatus={transformApiReqStatus}
      />
    );
  } else if (
    !fullRequestNodeVersion &&
    selectedNode.type === NodeTypesEnum.Request &&
    !isAdmin
  ) {
    return (
      <RequestBlockCustomer
        node={selectedNode}
        onCancel={handleOnCancel}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        onUpdateData={updateRequestNodeData}
        updateVariable={updateVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  } else if (
    (fullRequestNodeVersion || isAdmin) &&
    selectedNode.type === NodeTypesEnum.Request
  ) {
    return (
      <RequestBlockAdmin
        addVariable={addVariable}
        datasourceMetadata={datasourceMetadata}
        node={selectedNode}
        onCancel={handleOnCancel}
        onTransformApiReq={onTransformApiReq}
        onUpdateData={updateRequestNodeData}
        tableData={tableData}
        transformApiReqStatus={transformApiReqStatus}
        sourceType={sourceType}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
        updateVariable={updateVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
      />
    );
  } else if (selectedNode.type === NodeTypesEnum.Document) {
    return (
      <DocumentsBlock
        node={selectedNode}
        updateNode={updateNode}
        onCancel={handleOnCancel}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        addVariable={addVariable}
        updateVariable={updateVariable}
        onUploadFile={onUploadFile}
        onTransformApiReq={onTransformApiReq}
        transformApiReqStatus={transformApiReqStatus}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  } else if (selectedNode.type === NodeTypesEnum.Conditional) {
    return (
      <ConditionalBlock
        addVariable={addVariable}
        allowBranchReordering={allowBranchReordering}
        datasourceMetadata={datasourceMetadata}
        deleteBranch={deleteBranch}
        edges={edges}
        editingEdge={editingEdge}
        insertNode={insertNode}
        node={selectedNode}
        nodes={nodes}
        onCancel={handleOnCancel}
        onTransformApiReq={onTransformApiReq}
        onUpdateEdge={updateEdge}
        setEdges={setEdges}
        setEditingEdge={setEditingEdge}
        setNodes={setNodes}
        tableData={tableData}
        transformApiReqStatus={transformApiReqStatus}
        sourceType={sourceType}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
        updateVariable={updateVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        updateErrorOverlay={(val: boolean) => {
          updateNodeProps('errorOverlay', val);
        }}
      />
    );
  } else if (currentNodeType === 'freeform') {
    return (
      <FreeformBlock
        sourceType={sourceType}
        datasourceMetadata={datasourceMetadata}
        node={selectedNode as WorkflowFreeformNode}
        onCancel={handleOnCancel}
        variablesMap={variablesMap}
        updateVariable={updateVariable}
        globalVariablesMap={globalVariablesMap}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  } else if (currentNodeType === 'retry') {
    return (
      <RetryBlock
        node={selectedNode as WorkflowRetryNode}
        onCancel={handleOnCancel}
        updateNodeData={(data: object) => {
          updateNodeProps('data', {
            ...selectedNode.data,
            ...data,
          });
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  } else if (currentNodeType === 'stop') {
    return (
      <StopBlock
        node={selectedNode as WorkflowStopNode}
        onCancel={handleOnCancel}
        updateNodeDataStatus={(val: string) => {
          updateNodeProps('data.status', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  }
  return (
    <AddRecordingBlock
      node={selectedNode as WorkflowContinueNode}
      onCancel={handleOnCancel}
      onImport={onImport}
    />
  );
}

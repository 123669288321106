"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtensionAction = exports.ExtensionPickFromListAction = exports.ExtensionNewTabAction = exports.ExtensionSwitchTabAction = exports.ExtensionUploadDocumentAction = exports.ExtensionScrapeAction = exports.ExtensionMultiChoiceAction = exports.ExtensionKeyPressAction = exports.ExtensionKeydownAction = exports.ExtensionMultiSelectAction = exports.ExtensionSelectAction = exports.ExtensionInputAction = exports.ExtensionActionBase = exports.KeyTypes = exports.KeyTypesEnum = exports.Actions = exports.ActionsEnum = void 0;
var zod_1 = require("zod");
var query_path_1 = require("./query-path");
var ActionsEnum;
(function (ActionsEnum) {
    ActionsEnum["Click"] = "Click";
    ActionsEnum["Input"] = "Input";
    ActionsEnum["Wait"] = "Wait";
    ActionsEnum["Scrape"] = "Scrape";
    ActionsEnum["Keydown"] = "Keydown";
    ActionsEnum["KeyPress"] = "KeyPress";
    ActionsEnum["Select"] = "Select";
    ActionsEnum["MultiChoice"] = "MultiChoice";
    ActionsEnum["Download"] = "Download";
    ActionsEnum["ManualApproval"] = "ManualApproval";
    ActionsEnum["Arbitrary"] = "Arbitrary";
    ActionsEnum["UploadDocument"] = "UploadDocument";
    ActionsEnum["SwitchTab"] = "SwitchTab";
    ActionsEnum["NewTab"] = "NewTab";
    ActionsEnum["PickFromList"] = "PickFromList";
    ActionsEnum["MultiSelect"] = "MultiSelect";
    ActionsEnum["MagicLoop"] = "MagicLoop";
})(ActionsEnum || (exports.ActionsEnum = ActionsEnum = {}));
exports.Actions = zod_1.z.nativeEnum(ActionsEnum);
var KeyTypesEnum;
(function (KeyTypesEnum) {
    KeyTypesEnum["Enter"] = "Enter";
    KeyTypesEnum["Tab"] = "Tab";
    KeyTypesEnum["Backspace"] = "Backspace";
    KeyTypesEnum["Esc"] = "Esc";
    KeyTypesEnum["LeftArrow"] = "LeftArrow";
    KeyTypesEnum["RightArrow"] = "RightArrow";
    KeyTypesEnum["UpArrow"] = "UpArrow";
    KeyTypesEnum["DownArrow"] = "DownArrow";
})(KeyTypesEnum || (exports.KeyTypesEnum = KeyTypesEnum = {}));
exports.KeyTypes = zod_1.z.nativeEnum(KeyTypesEnum);
exports.ExtensionActionBase = zod_1.z.object({
    id: zod_1.z.string(),
    target: query_path_1.QueryPaths,
    actionType: zod_1.z.literal(exports.Actions.enum.Click),
    timestamp: zod_1.z.string(),
});
exports.ExtensionInputAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.Input),
    currentText: zod_1.z.string().optional(),
});
exports.ExtensionSelectAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.Select),
    target: query_path_1.SelectQueryPaths,
    currentSelected: query_path_1.SelectOption,
});
exports.ExtensionMultiSelectAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.MultiSelect),
    target: query_path_1.SelectQueryPaths,
    currentSelected: zod_1.z.array(zod_1.z.number()),
});
exports.ExtensionKeydownAction = exports.ExtensionInputAction.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.Keydown),
    keyPress: zod_1.z.string(),
});
exports.ExtensionKeyPressAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.KeyPress),
    keyType: exports.KeyTypes,
});
exports.ExtensionMultiChoiceAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.MultiChoice),
    target: query_path_1.MultiChoiceQueryPaths,
});
exports.ExtensionScrapeAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.Scrape),
    scrapedText: zod_1.z.string().optional(),
});
exports.ExtensionUploadDocumentAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.UploadDocument),
});
exports.ExtensionSwitchTabAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.SwitchTab),
});
exports.ExtensionNewTabAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.NewTab),
});
exports.ExtensionPickFromListAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.PickFromList),
});
exports.ExtensionAction = zod_1.z.union([
    exports.ExtensionActionBase,
    exports.ExtensionInputAction,
    exports.ExtensionSelectAction,
    exports.ExtensionMultiSelectAction,
    exports.ExtensionMultiChoiceAction,
    exports.ExtensionScrapeAction,
    exports.ExtensionKeydownAction,
    exports.ExtensionKeyPressAction,
    exports.ExtensionPickFromListAction,
]);

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';
import {
  VariableTypeEnum,
  type GlobalVariable,
  type Variable,
  type VariableMap,
} from 'types-shared';
import { VariableChip } from '../../../../../components/VariableChip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, NotFound, Tooltip } from 'ui-kit';
import SearchIcon from '@mui/icons-material/Search';
import { clsx } from 'clsx';
import { useFeatureFlag } from '../../../../../utils/helper';
import { FeatureFlag } from '../../../../../utils/constants';

interface Props {
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
  onSelect: (val: Variable) => void;
  onAddNew?: () => void;
  variables: Variable[];
  globalVariables: GlobalVariable[];
  allowAddVariable: boolean;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
}

enum MenuTypes {
  Custom = 'custom',
  Scrapes = 'scrapes',
  Global = 'global',
  System = 'system',
  Documents = 'documents',
}

type VariableFiltersType = {
  [key in MenuTypes]: VariableTypeEnum;
};

const VariableFilters: VariableFiltersType = {
  [MenuTypes.Custom]: VariableTypeEnum.Query,
  [MenuTypes.Scrapes]: VariableTypeEnum.Scrape,
  [MenuTypes.Global]: VariableTypeEnum.Global,
  [MenuTypes.System]: VariableTypeEnum.Execution,
  [MenuTypes.Documents]: VariableTypeEnum.Document,
};

function VariablesMenu({
  anchorEl,
  open,
  onClose,
  onSelect,
  onAddNew,
  variables = [],
  globalVariables = [],
  allowAddVariable = true,
  variablesMap,
  globalVariablesMap,
}: Props) {
  const [search, setSearch] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<MenuTypes>(MenuTypes.Custom);

  const onTabChange = (_event: React.SyntheticEvent, newValue: MenuTypes) => {
    setSelectedTab(newValue);
  };
  const systemVariablesEnabled = useFeatureFlag(
    FeatureFlag.SystemVariables,
    true,
  );

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const variablesToShow = useMemo(() => {
    const activeVariables: Variable[] = [
      ...variables,
      ...globalVariables,
    ].filter((v) => v.type === VariableFilters[selectedTab]);

    if (search === '') {
      return activeVariables;
    }
    return activeVariables.filter((variable) =>
      variable.name?.toLowerCase().includes(search.toLowerCase()),
    );
  }, [selectedTab, globalVariables, variables, search]);

  return (
    <Menu
      anchorEl={anchorEl}
      classes={{
        paper: '!max-w-none',
        list: '!p-0',
      }}
      className="min-w-[30rem]"
      disablePortal
      onClose={onClose}
      open={open}
      slotProps={{
        paper: {
          sx: {
            width: '500px',
            minHeight: '400px',
            maxHeight: '650px',
          },
        },
      }}
      sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem
        className="!border-t !border-t-gray-300 !text-blue-600 !px-3 !py-2.5"
        divider
      >
        <Button
          className="w-full h-9"
          color="secondary"
          onClick={() => onAddNew?.()}
          variant="contained"
          disabled={!allowAddVariable}
        >
          Add New Variable
        </Button>
      </MenuItem>

      {[...variables, ...globalVariables].length > 0 ? (
        <MenuItem className="!bg-white !px-3 !py-2.5" disableRipple>
          <div
            className={clsx({
              'outline-none border rounded w-full': true,
              ' pl-1': !search.length,
              'focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500':
                !search.length,
            })}
          >
            {search.length ? null : (
              <SearchIcon className="text-info-dark mr-1" />
            )}
            <input
              className={clsx({
                'p-1 text-sm': true,
                'border-0 focus:outline-none': !search.length,
                'w-full ': Boolean(search.length),
              })}
              onChange={onSearchChange}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              placeholder="Search variable"
              value={search}
            />
          </div>
        </MenuItem>
      ) : (
        <MenuItem className="!px-3 !py-2.5 pointer-events-none text-sm" divider>
          No variables found
        </MenuItem>
      )}

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          onChange={onTabChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#2196F3',
            },
            '& .Mui-selected': {
              color: '#2196F3 !important',
            },
          }}
          value={selectedTab}
        >
          {Object.keys(MenuTypes).map((mtKey) => {
            if (mtKey === 'System') {
              return (
                <Tooltip
                  arrow
                  title="Contact sales to access this feature."
                  key={mtKey}
                  hidden={Boolean(systemVariablesEnabled)}
                >
                  <Tab
                    label={mtKey}
                    value={MenuTypes[mtKey as keyof typeof MenuTypes]}
                    disabled={Boolean(!systemVariablesEnabled)}
                  />
                </Tooltip>
              );
            }

            return (
              <Tab
                label={mtKey}
                value={MenuTypes[mtKey as keyof typeof MenuTypes]}
                key={mtKey}
              />
            );
          })}
        </Tabs>
      </Box>

      {variablesToShow.length ? (
        <div className="overflow-y-auto" style={{ maxHeight: '500px' }}>
          {variablesToShow.map((variable: Variable, i) => (
            <MenuItem
              className="!px-3 !py-2.5"
              divider={i === variablesToShow.length - 1}
              key={variable.id}
              onClick={() => {
                onSelect(variable);
                onClose();
              }}
            >
              <VariableChip
                variableId={variable.id}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
              />
            </MenuItem>
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center p-5">
          <div>
            <NotFound
              fontSize="large"
              style={{
                height: '55px',
                width: '43px',
              }}
            />
          </div>
          <p className="text-gray-500 text-normal mt-3 text-center">
            {search.length
              ? `There are no matching results for "${search}" in ${selectedTab.endsWith('s') ? selectedTab.slice(0, -1) : selectedTab} variables`
              : 'No variables found.'}
          </p>
        </div>
      )}
    </Menu>
  );
}

export default VariablesMenu;
